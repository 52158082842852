import React, {FC} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import {sidebarAvatarCircles, sidebarManAvatar, sidebarWomanAvatar} from 'assets';
import {userSelector} from 'store';
import {Gender} from 'types';

import AppText from '../appText';

import styles from './style.module.scss';

type Buttons = {
	key: number;
	name: string;
	icon: string;
	isActive: boolean;
	linkTo: string;
	onClick?: () => void;
}[];

type PropsType = {
	buttons: Buttons;
	additionalButtons: Buttons;
	className?: string;
};

const Sidebar: FC<PropsType> = ({buttons, additionalButtons, className = ''}) => {
	const user = userSelector.GetUserData();
	const location = useLocation();

	const checkRoute = (linkRoute: string, currentRoute: string) => {
		return linkRoute.split('/')[1] === currentRoute.split('/')[1];
	};

	const getButtonsElements = (items: Buttons) =>
		items.map(item => (
			<Link
				key={item.key}
				to={item.linkTo}
				className={`${checkRoute(item.linkTo, location.pathname) ? styles.active : ''}`}
				onClick={item?.onClick}>
				<ReactSVG wrapper="span" src={item.icon} className={styles.buttonsIcon} />
				{item.name}
			</Link>
		));

	return (
		<div className={`${styles.sidebar} ${className}`}>
			<div>
				<div className={`${styles.profile}`}>
					<div className={`${styles.profileIcon}`}>
						<ReactSVG
							wrapper="span"
							src={sidebarAvatarCircles}
							className={styles.circles}
						/>
						{user.gender !== null && (
							<img
								src={
									user.gender === Gender.woman
										? sidebarWomanAvatar
										: sidebarManAvatar
								}
								alt="avatar"
								className={styles.avatar}
							/>
						)}
					</div>
					{user.firstName && (
						<div className={`${styles.profileName}`}>
							Hello, <span>{user.firstName}!</span>
						</div>
					)}
				</div>
				<div className={styles.buttons}>{getButtonsElements(buttons)}</div>
				<AppText className={styles.title}>Products</AppText>
				<div className={styles.buttons}>{getButtonsElements(additionalButtons)}</div>
			</div>
		</div>
	);
};

export default Sidebar;
