import React, {FC} from 'react';

import {PayPalButtons} from '@paypal/react-paypal-js';

type Props = {
	planId: string;
	color: 'blue';
	className?: string;
	onCreation?: () => void;
	onSuccess: (subscriptionId: string) => void;
	onError: (error: any) => void;
};

const ButtonPaypal: FC<Props> = ({
	planId,
	color,
	onCreation,
	onSuccess,
	onError,
	className = '',
}) => {
	return (
		<PayPalButtons
			className={className}
			style={{
				shape: 'pill',
				color,
				layout: 'horizontal',
				label: 'paypal',
				height: 55,
				tagline: false,
			}}
			createSubscription={(data, actions) => {
				if (onCreation) {
					onCreation();
				}

				return actions.subscription.create({
					plan_id: planId,
				});
			}}
			onApprove={data => {
				onSuccess(data.subscriptionID || '');
				return new Promise(() => {});
			}}
			onError={onError}
		/>
	);
};

export default ButtonPaypal;
