import {ThunkAction} from 'redux-thunk';

import {AstrologyAPI} from 'services';
import {AstrologyInitialState, StateType} from 'types';
import {Toast} from 'utility';

import {
	AstrologyActions,
	AstrologyActionTypes,
	setAstroEvents,
	setCurrentMoonPhases,
	setMoonPhases,
} from '../actions/astrology';

const initialState: AstrologyInitialState = {
	astroEvents: null,
	moonPhases: null,
	currentMoonPhase: null,
};

type Thunk = ThunkAction<Promise<void>, StateType, unknown, AstrologyActionTypes>;

export const fetchAstroEvents = (): Thunk => async dispatch => {
	try {
		const response = await AstrologyAPI.getAstroEvents();
		dispatch(setAstroEvents(response));
	} catch (error) {
		Toast.error(JSON.stringify(error));
	}
};

export const fetchMoonPhases = (): Thunk => async dispatch => {
	try {
		const response = await AstrologyAPI.getMoonPhases();
		dispatch(setMoonPhases(response));
	} catch (error) {
		Toast.error(JSON.stringify(error));
	}
};

export const fetchCurrentMoonPhase = (): Thunk => async dispatch => {
	try {
		const response = await AstrologyAPI.getCurrentMoonPhase();
		dispatch(setCurrentMoonPhases(response));
	} catch (error) {
		Toast.error(JSON.stringify(error));
	}
};

const astrologyReducer = (
	state: AstrologyInitialState,
	action: AstrologyActionTypes,
): AstrologyInitialState => {
	const astroState = state || initialState;

	switch (action.type) {
		case AstrologyActions.SET_ASTRO_EVENTS:
			return {
				...astroState,
				astroEvents: action.payload,
			};
		case AstrologyActions.SET_CURRENT_MOON_PHASES:
			return {
				...astroState,
				currentMoonPhase: action.payload,
			};
		case AstrologyActions.SET_MOON_PHASES:
			return {
				...astroState,
				moonPhases: action.payload,
			};
		default:
			return astroState;
	}
};

export default astrologyReducer;
