import {NewsFeedsRequest} from 'types';

export enum NewsFeedActions {
	SET_NEWS = 'newsFeed/setNews',
	TOGGLE_POST_LIKE = 'newsFeed/togglePostLike',
}

type SetNews = {type: NewsFeedActions.SET_NEWS; payload: NewsFeedsRequest};
type TogglePostLike = {type: NewsFeedActions.TOGGLE_POST_LIKE; payload: string};

export const setNews = (payload: NewsFeedsRequest): SetNews => {
	return {
		type: NewsFeedActions.SET_NEWS,
		payload,
	};
};

export const toggleLike = (payload: string): TogglePostLike => {
	return {
		type: NewsFeedActions.TOGGLE_POST_LIKE,
		payload,
	};
};

export type NewsFeedActionTypes = SetNews | TogglePostLike;
