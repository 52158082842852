import React, {FC, useEffect} from 'react';
import TagManager from 'react-gtm-module';
import {Provider, useDispatch} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';

import {PayPalScriptProvider} from '@paypal/react-paypal-js';
import {Elements} from '@stripe/react-stripe-js';
import {Toast} from 'components';
import {MILLISECONDS, STORAGE_KEYS} from 'const';
import {useImagesPreload} from 'hooks';
import {feedbackTriggerService, LogEvent, PaymentService} from 'services';
import {fetchEnums, initializeApp, store, userSelector} from 'store';

import RootComponent from './rootComponent';

const tagManagerArgs = {
	gtmId: process.env.REACT_APP_GTM_KEY,
};

TagManager.initialize(tagManagerArgs);
LogEvent.init();

const stripePromise = PaymentService.init();
const FEEDBACK_SESSION_COUNT_TIME = 3 * MILLISECONDS.minute;

const paypalOptions = {
	'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID || '',
	currency: 'USD',
	vault: true,
	commit: false,
	intent: 'subscription',
};

const App: FC = () => {
	const dispatch = useDispatch();
	const userData = userSelector.GetUserData();

	useEffect(() => {
		dispatch(fetchEnums());
		setTimeout(() => {
			feedbackTriggerService.trigger(STORAGE_KEYS.SESSION_COUNT);
		}, FEEDBACK_SESSION_COUNT_TIME);
	}, []);

	useEffect(() => {
		if (!userData.birthDateTimeUtc) {
			dispatch(initializeApp());
		}
	});

	return <RootComponent />;
};

const AppWrapper: React.FC = () => {
	useImagesPreload();

	return (
		<BrowserRouter>
			<Provider store={store}>
				<PayPalScriptProvider options={paypalOptions}>
					<Elements stripe={stripePromise}>
						<App />
						<Toast />
					</Elements>
				</PayPalScriptProvider>
			</Provider>
		</BrowserRouter>
	);
};

export default AppWrapper;
