import {
	chiron,
	conjunction,
	contraParallel,
	firstQuarter,
	fullMoon,
	jupiter,
	mars,
	mercury,
	moon,
	neptune,
	newMoon,
	opposition,
	parallel,
	pluto,
	quincunx,
	saturn,
	semiSextile,
	semiSquare,
	sesquiquadrature,
	sextile,
	square,
	sun,
	thirdQuarter,
	trine,
	trueNode,
	uranus,
	venus,
	waningCrescent,
	waningGibbous,
	waxingCrescent,
	waxingGibbous,
} from 'assets';

const ASPECTS = [
	{key: 1, icon: quincunx},
	{key: 2, icon: parallel},
	{key: 3, icon: contraParallel},
	{key: 4, icon: opposition},
	{key: 5, icon: sesquiquadrature},
	{key: 6, icon: semiSextile},
	{key: 7, icon: sextile},
	{key: 8, icon: semiSquare},
	{key: 9, icon: conjunction},
	{key: 10, icon: trine},
	{key: 11, icon: square},
];

export const PLANETS = [
	{key: 1, icon: mercury},
	{key: 2, icon: venus},
	{key: 3, icon: venus},
	{key: 4, icon: mars},
	{key: 5, icon: jupiter},
	{key: 6, icon: saturn},
	{key: 7, icon: uranus},
	{key: 8, icon: neptune},
	{key: 9, icon: pluto},
	{key: 10, icon: moon},
	{key: 11, icon: sun},
	{key: 12, icon: trueNode},
	{key: 13, icon: chiron},
];

export const MOON_PHASES = [
	{
		key: 1,
		value: 'New Moon',
		icon: newMoon,
	},
	{
		key: 2,
		value: 'Waxing Crescent',
		icon: waxingCrescent,
	},
	{
		key: 3,
		value: 'First Quarter',
		icon: firstQuarter,
	},
	{
		key: 4,
		value: 'Waxing Gibbous',
		icon: waxingGibbous,
	},
	{
		key: 5,
		value: 'Full Moon',
		icon: fullMoon,
	},
	{
		key: 6,
		value: 'Waning Gibbous',
		icon: waningGibbous,
	},
	{
		key: 7,
		value: 'Third Quarter',
		icon: thirdQuarter,
	},
	{
		key: 8,
		value: 'Waning Crescent',
		icon: waningCrescent,
	},
];

export default ASPECTS;
