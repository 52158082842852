import {CompatibilitiesTypes} from 'types';

export enum CompatibilityActions {
	SET_COMPATIBILITY = 'compatibility/setCompatibility',
}

type SetCompatibility = {
	type: CompatibilityActions.SET_COMPATIBILITY;
	payload: CompatibilitiesTypes;
};

export const setCompatibility = (payload: CompatibilitiesTypes): SetCompatibility => {
	return {
		type: CompatibilityActions.SET_COMPATIBILITY,
		payload,
	};
};

export type CompatibilityActionTypes = SetCompatibility;
