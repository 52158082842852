import {ThunkAction} from 'redux-thunk';

import {NewsFeedAPI} from 'services';
import {NewsFeedInitialState, StateType} from 'types';
import {Toast} from 'utility';

import {NewsFeedActions, NewsFeedActionTypes, setNews, toggleLike} from '../actions/newsFeed';

const initialState: NewsFeedInitialState = {
	isFirstRequest: true,
	fullCount: 0,
	news: null,
};

type Thunk = ThunkAction<Promise<void>, StateType, unknown, NewsFeedActionTypes>;

export const fetchNews =
	(skip = 0): Thunk =>
	async dispatch => {
		try {
			const response = await NewsFeedAPI.getNews(skip);
			dispatch(setNews(response));
		} catch (error: any) {
			if (error.response.status !== 401) {
				Toast.error(JSON.stringify(error));
			}
		}
	};

export const togglePostLike =
	(token, cloneNumber): Thunk =>
	async dispatch => {
		try {
			await NewsFeedAPI.toggleLike(token, cloneNumber);
			dispatch(toggleLike(token));
		} catch (error) {
			Toast.error(JSON.stringify(error));
		}
	};

const newsFeedReducer = (
	state: NewsFeedInitialState,
	action: NewsFeedActionTypes,
): NewsFeedInitialState => {
	const newsFeedState = state || initialState;

	switch (action.type) {
		case NewsFeedActions.SET_NEWS: {
			const stateNews = newsFeedState.news || [];
			const news = newsFeedState.isFirstRequest
				? action.payload.body
				: [...stateNews, ...action.payload.body];
			return {
				...newsFeedState,
				news,
				fullCount: action.payload.fullCount,
				isFirstRequest: false,
			};
		}
		case NewsFeedActions.TOGGLE_POST_LIKE: {
			const news = newsFeedState.news
				? newsFeedState.news.map(item => {
						const post = item;
						if (post.token === action.payload) {
							if (!post.isLiked) {
								post.likes += 1;
							} else {
								post.likes -= 1;
							}
							post.isLiked = !post.isLiked;
						}

						return post;
				  })
				: [];
			return {
				...newsFeedState,
				news,
			};
		}
		default:
			return newsFeedState;
	}
};

export default newsFeedReducer;
