import React, {FC} from 'react';

import {INFO_LINKS} from 'const';

import styles from './style.module.scss';

type Links = {
	key: number;
	name: string;
	href: string;
}[];

type PropsType = {
	links?: Links;
	className?: string;
};

const Footer: FC<PropsType> = ({links = INFO_LINKS, className = ''}) => {
	const linksElements = links.map(item => (
		<a key={item.key} href={item.href} rel="noreferrer" title={item.name} target="_blank">
			{item.name}
		</a>
	));
	return <footer className={`${styles.footer} ${className}`}>{linksElements}</footer>;
};

export default Footer;
