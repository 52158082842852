import {ThunkAction} from 'redux-thunk';

import moment from 'moment';
import {FriendsAPI} from 'services';
import {FriendData, FriendsInitialState, StateType} from 'types';
import {biorhythmsUtils, Toast} from 'utility';

import {
	FriendsActions,
	FriendsActionTypes,
	setFriends,
	setSelectedFriendAccordionData,
	setSelectedFriendBiorhythms,
	setSelectedFriendCurrentMoonPhases,
	setSelectedFriendFocusOfTheDay,
	setSelectedFriendLifePathNumbers,
	setSelectedFriendMission,
	setSelectedFriendMoonPhases,
	setSelectedFriendQuote,
	setSelectedFriendStrengths,
	setSelectedFriendSymbolismAndMyth,
	setSelectedFriendTodayLuckyNumber,
	setSelectedFriendWeaknesses,
	setSelectedFriendZodiacSignInfo,
} from '../actions/friends';

const initialState: FriendsInitialState = {
	firstName: null,
	gender: null,
	birthDateTimeUtc: null,
	birthDateTimeMinutesOffset: null,
	birthCity: {
		name: null,
		latitude: null,
		longitude: null,
	},
	friends: [],
	selectedProfileToken: null,
	selectedFriendAccordionData: null,
	selectedFriendStrengths: null,
	selectedFriendWeaknesses: null,
	selectedFriendSymbolismAndMyth: null,
	selectedFriendMission: null,
	selectedFriendZodiacSignInfo: null,
	selectedFriendFocusOfTheDay: null,
	selectedFriendLifePathNumbers: null,
	selectedFriendQuote: null,
	selectedFriendTodayLuckyNumber: null,
	selectedFriendBiorhythms: null,
	selectedFriendMoonPhases: null,
	selectedFriendCurrentMoonPhase: null,
};

type Thunk = ThunkAction<Promise<void>, StateType, unknown, FriendsActionTypes>; // что возвращает санка, тип стора, всегда unknown, тип всех экшенов этого редьюсера

export const addFriend =
	(friendData: FriendData): Thunk =>
	async dispatch => {
		try {
			await FriendsAPI.postFriendData(friendData);
			const response = await FriendsAPI.getFriends();
			dispatch(setFriends(response));
		} catch (error: any) {
			Toast.error(JSON.stringify(error));
		}
	};

export const fetchFriends = (): Thunk => async dispatch => {
	try {
		const response = await FriendsAPI.getFriends();
		dispatch(setFriends(response));
	} catch (error: any) {
		if (error.response.status !== 401) {
			Toast.error(JSON.stringify(error));
		}
	}
};

export const deleteFriend =
	(friendToken: string): Thunk =>
	async dispatch => {
		try {
			await FriendsAPI.deleteFriend(friendToken);

			const response = await FriendsAPI.getFriends();
			dispatch(setFriends(response));
		} catch (error: any) {
			Toast.error(JSON.stringify(error));
		}
	};

export const fetchSelectedFriendAccordionData =
	(signId: number, friendToken: string): Thunk =>
	async dispatch => {
		try {
			const response = await FriendsAPI.getSelectedFriendAccordionData(signId, friendToken);
			dispatch(setSelectedFriendAccordionData(response));
		} catch (error: any) {
			Toast.error(JSON.stringify(error));
		}
	};

export const fetchSelectedFriendMission =
	(signId: number, friendToken: string): Thunk =>
	async dispatch => {
		try {
			const response = await FriendsAPI.getSelectedFriendMission(signId, friendToken);
			dispatch(setSelectedFriendMission(response));
		} catch (error: any) {
			Toast.error(JSON.stringify(error));
		}
	};

export const fetchSelectedFriendStrengths =
	(signId: number, friendToken: string): Thunk =>
	async dispatch => {
		try {
			const response = await FriendsAPI.getSelectedFriendZodiacStrengths(signId, friendToken);
			dispatch(setSelectedFriendStrengths(response));
		} catch (error: any) {
			Toast.error(JSON.stringify(error));
		}
	};

export const fetchSelectedFriendWeaknesses =
	(signId: number, friendToken: string): Thunk =>
	async dispatch => {
		try {
			const response = await FriendsAPI.getSelectedFriendWeaknesses(signId, friendToken);
			dispatch(setSelectedFriendWeaknesses(response));
		} catch (error: any) {
			Toast.error(JSON.stringify(error));
		}
	};

export const fetchSelectedFriendSymbolismAndMyth =
	(signId: number, friendToken: string): Thunk =>
	async dispatch => {
		try {
			const response = await FriendsAPI.getSelectedFriendSymbolismAndMyth(
				signId,
				friendToken,
			);
			dispatch(setSelectedFriendSymbolismAndMyth(response));
		} catch (error: any) {
			Toast.error(JSON.stringify(error));
		}
	};

export const fetchSelectedFriendZodiacSignInfo =
	(signId: number, friendToken: string): Thunk =>
	async dispatch => {
		try {
			const response = await FriendsAPI.getSelectedFriendSignInfo(signId, friendToken);
			dispatch(setSelectedFriendZodiacSignInfo(response));
		} catch (error: any) {
			Toast.error(JSON.stringify(error));
		}
	};

export const fetchSelectedFriendQuote =
	(friendToken: string): Thunk =>
	async dispatch => {
		try {
			const response = await FriendsAPI.getSelectedFriendQuote(friendToken);
			dispatch(setSelectedFriendQuote(response));
		} catch (error: any) {
			Toast.error(JSON.stringify(error));
		}
	};

export const fetchSelectedFriendLifePathNumbers =
	(friendToken: string): Thunk =>
	async dispatch => {
		try {
			const response = await FriendsAPI.getSelectedFriendLifePathNumbers(friendToken);
			dispatch(setSelectedFriendLifePathNumbers(response));
		} catch (error: any) {
			Toast.error(JSON.stringify(error));
		}
	};

export const fetchSelectedFriendFocusOfTheDay =
	(friendToken: string): Thunk =>
	async dispatch => {
		try {
			const dateToSting = moment().format('YYYY-MM-DD');
			const response = await FriendsAPI.getSelectedFriendFocusOfTheDay(
				dateToSting,
				friendToken,
			);
			dispatch(setSelectedFriendFocusOfTheDay(response));
		} catch (error: any) {
			Toast.error(JSON.stringify(error));
		}
	};

export const fetchSelectedFriendTodayLuckyNumber =
	(friendToken: string): Thunk =>
	async dispatch => {
		try {
			const response = await FriendsAPI.getSelectedFriendTodayLuckyNumber(friendToken);
			dispatch(setSelectedFriendTodayLuckyNumber(response));
		} catch (error: any) {
			Toast.error(JSON.stringify(error));
		}
	};

export const fetchSelectedFriendBiorhythms =
	(friendToken: string): Thunk =>
	async dispatch => {
		try {
			const response = await FriendsAPI.getSelectedFriendBiorhythms(friendToken);
			dispatch(setSelectedFriendBiorhythms(biorhythmsUtils.getFormattedBiorhythms(response)));
		} catch (error: any) {
			Toast.error(JSON.stringify(error));
		}
	};

export const fetchSelectedFriendMoonPhases =
	(friendToken: string): Thunk =>
	async dispatch => {
		try {
			const response = await FriendsAPI.getSelectedFriendMoonPhases(friendToken);
			dispatch(setSelectedFriendMoonPhases(response));
		} catch (error: any) {
			Toast.error(JSON.stringify(error));
		}
	};

export const fetchSelectedFriendCurrentMoonPhases =
	(friendToken: string): Thunk =>
	async dispatch => {
		try {
			const response = await FriendsAPI.getSelectedFriendCurrentMoonPhase(friendToken);
			dispatch(setSelectedFriendCurrentMoonPhases(response));
		} catch (error: any) {
			Toast.error(JSON.stringify(error));
		}
	};

const addFriendReducer = (
	state: FriendsInitialState,
	action: FriendsActionTypes,
): FriendsInitialState => {
	const friendsState = state || initialState;

	switch (action.type) {
		case FriendsActions.SET_FRIENDS:
			return {
				...friendsState,
				friends: action.payload,
			};
		case FriendsActions.SET_FRIEND_NAME:
			return {
				...friendsState,
				firstName: action.payload,
			};
		case FriendsActions.SET_FRIEND_CITY:
			return {
				...friendsState,
				birthCity: action.payload,
			};
		case FriendsActions.SET_FRIEND_GENDER:
			return {
				...friendsState,
				gender: action.payload,
			};
		case FriendsActions.SET_FRIEND_BIRTH_DATE:
			return {
				...friendsState,
				birthDateTimeUtc: action.payload,
			};
		case FriendsActions.SET_FRIEND_BIRTH_DATE_OFFSET:
			return {
				...friendsState,
				birthDateTimeMinutesOffset: action.payload,
			};
		case FriendsActions.SET_SELECTED_PROFILE_TOKEN:
			return {
				...friendsState,
				selectedProfileToken: action.payload,
			};
		case FriendsActions.SET_SELECTED_FRIEND_ACCORDION_DATA:
			return {
				...friendsState,
				selectedFriendAccordionData: action.payload,
			};
		case FriendsActions.SET_SELECTED_FRIEND_MISSION:
			return {
				...friendsState,
				selectedFriendMission: action.payload,
			};
		case FriendsActions.SET_SELECTED_FRIEND_STRENGTHS:
			return {
				...friendsState,
				selectedFriendStrengths: action.payload,
			};
		case FriendsActions.SET_SELECTED_FRIEND_WEAKNESSES:
			return {
				...friendsState,
				selectedFriendWeaknesses: action.payload,
			};
		case FriendsActions.SET_SELECTED_FRIEND_SYMBOLISM_AND_MYTH:
			return {
				...friendsState,
				selectedFriendSymbolismAndMyth: action.payload,
			};
		case FriendsActions.SET_SELECTED_FRIEND_ZODIAC_SIGN_INFO:
			return {
				...friendsState,
				selectedFriendZodiacSignInfo: action.payload,
			};
		case FriendsActions.SET_SELECTED_FRIEND_FOCUS_OF_THE_DAY:
			return {
				...friendsState,
				selectedFriendFocusOfTheDay: action.payload,
			};
		case FriendsActions.SET_SELECTED_FRIEND_QUOTE:
			return {
				...friendsState,
				selectedFriendQuote: action.payload,
			};
		case FriendsActions.SET_SELECTED_FRIEND_TODAY_LUCKY_NUMBER:
			return {
				...friendsState,
				selectedFriendTodayLuckyNumber: action.payload,
			};
		case FriendsActions.SET_SELECTED_FRIEND_LIFE_PATH_NUMBERS:
			return {
				...friendsState,
				selectedFriendLifePathNumbers: action.payload,
			};
		case FriendsActions.SET_SELECTED_FRIEND_BIORHYTHMS:
			return {
				...friendsState,
				selectedFriendBiorhythms: action.payload,
			};
		case FriendsActions.SET_SELECTED_FRIEND_MOON_PHASES:
			return {
				...friendsState,
				selectedFriendMoonPhases: action.payload,
			};
		case FriendsActions.SET_SELECTED_FRIEND_CURRENT_MOON_PHASES:
			return {
				...friendsState,
				selectedFriendCurrentMoonPhase: action.payload,
			};
		default:
			return friendsState;
	}
};

export default addFriendReducer;
