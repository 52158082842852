/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {FC} from 'react';
import {useDispatch} from 'react-redux';
import {ReactSVG} from 'react-svg';

import {manHappy, redCrossIcon, womanHappy} from 'assets';
import {friendsSelector, setSelectedProfileToken, userSelector} from 'store';
import {FriendDataResponseType, Gender} from 'types';

import styles from './style.module.scss';

type PropsType = {
	friendData?: FriendDataResponseType;
	onDeleteClick?: () => void;
};

const FriendsListItem: FC<PropsType> = ({friendData, onDeleteClick}) => {
	const dispatch = useDispatch();
	const selectedProfileToken = friendsSelector.GetSelectedProfileToken();
	const userToken = userSelector.GetUserToken();
	const userGender = userSelector.GetGender();
	const userName = userSelector.GetUserName();

	const onFriendClick = (token: string) => {
		dispatch(setSelectedProfileToken(token));
	};

	const onUserIconClick = () => {
		if (userToken) dispatch(setSelectedProfileToken(userToken));
	};

	return friendData ? (
		<div
			className={`${styles.friendsListItem} ${
				selectedProfileToken === friendData.token ? styles.selected : styles.unselected
			}`}
			onClick={() => onFriendClick(friendData.token)}>
			<div className={styles.avatarWrapper}>
				{friendData.gender !== null && (
					<img
						src={friendData.gender === Gender.woman ? womanHappy : manHappy}
						alt="friend avatar"
						className={styles.avatar}
					/>
				)}
			</div>
			<p>{friendData.firstName}</p>
			{selectedProfileToken === friendData.token && (
				<button type="button" className={styles.deleteIcon} onClick={onDeleteClick}>
					<ReactSVG wrapper="span" src={redCrossIcon} />
				</button>
			)}
		</div>
	) : (
		<div
			className={`${styles.friendsListItem} ${
				selectedProfileToken === userToken ? styles.selected : styles.unselected
			}`}
			onClick={onUserIconClick}>
			<div className={styles.avatarWrapper}>
				{userGender !== null && (
					<img
						src={userGender === Gender.woman ? womanHappy : manHappy}
						alt="avatar"
						className={styles.avatar}
					/>
				)}
			</div>
			<p>{userName}</p>
		</div>
	);
};

export default FriendsListItem;
