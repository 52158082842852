import React, {FC} from 'react';

import styles from './style.module.scss';

type PropsType = {
	className?: string;
	children: React.ReactNode;
};

const PaywallProgressBar: FC<PropsType> = ({className = '', children}) => {
	return <div className={`${styles.progressBar} ${className}`}>{children}</div>;
};

export default PaywallProgressBar;
