import {useEffect} from 'react';

import main from 'assets/backgrounds/main.webp';
import mobile from 'assets/backgrounds/mobile.webp';

const pictures: string[] = [main, mobile];

export default function useImagesPreload() {
	useEffect(() => {
		pictures.forEach(picture => {
			const img = new Image();

			img.src = picture;
		});
	}, []);
}
