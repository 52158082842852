import {useSelector} from 'react-redux';

import {StateType} from 'types';

export const GetAccordionData = () => useSelector((state: StateType) => state.zodiac.accordionData);
export const GetStrengths = () => useSelector((state: StateType) => state.zodiac.strengths);
export const GetWeaknesses = () => useSelector((state: StateType) => state.zodiac.weaknesses);
export const GetSymbolismAndMyth = () =>
	useSelector((state: StateType) => state.zodiac.symbolismAndMuth);
export const GetMission = () => useSelector((state: StateType) => state.zodiac.mission);
export const GetFocusOfTheDay = () => useSelector((state: StateType) => state.zodiac.focusOfTheDay);
export const GetLifePathNumbersData = () =>
	useSelector((state: StateType) => state.zodiac.lifePathNumbers);
export const GetQuote = () => useSelector((state: StateType) => state.zodiac.quote);
export const GetTodayLuckyNumberData = () =>
	useSelector((state: StateType) => state.zodiac.todayLuckyNumber);
export const GetBiorhythms = () => useSelector((state: StateType) => state.zodiac.biorythms);
