import React from 'react';
import {useLocation} from 'react-router-dom';

import moment from 'moment';
import {TimeFormat} from 'types/common';

export const getShortName = (userName: string): string => {
	return userName.slice(0, 2).toUpperCase();
};

export const getDateTitle = (routeKey: string) => {
	switch (routeKey) {
		case 'month': {
			return moment().format('MMMM');
		}
		case 'year': {
			return moment().format('YYYY');
		}
		case 'week': {
			return `${moment().weekday(1).format('DD')}-${moment()
				.weekday(7)
				.format('DD')} of ${moment().format('MMMM')}`;
		}
		default: {
			return moment().format('DD.MM.YYYY');
		}
	}
};

export const useURLSearchParams = () => {
	const {search} = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const todayDate = new Date();

export const monthNames = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

export const getMonthName = (date: Date): string => monthNames[date.getMonth()];

export const getNextMonthLastDay = (): Date =>
	new Date(todayDate.getFullYear(), todayDate.getMonth() + 2, 0);

export const getPrevMonthFirstDay = (): Date =>
	new Date(todayDate.getFullYear(), todayDate.getMonth() - 1, 1);

export const compareDates = (date1: Date, date2: string): boolean =>
	date1.toDateString() === new Date(date2).toDateString();

export function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getYears = (yearsNum: number): string[] => {
	const years: string[] = [];
	const dateStart = moment().subtract(yearsNum, 'years');
	const dateEnd = moment();

	while (dateEnd.diff(dateStart, 'years') >= 0) {
		years.push(dateStart.format('YYYY'));
		dateStart.add(1, 'year');
	}

	return years;
};

export const getDaysOfMonth = (monthNum: string, yearNum: string): number[] => {
	const daysNum = moment(`${yearNum}-${monthNum}`, 'YYYY-MM').daysInMonth();
	let days: number[] = [];
	if (monthNum && yearNum) {
		days = Array.from({length: daysNum}, (_, i) => i + 1);
	}

	return days;
};

export const getBirthDateWithTime = (
	birthDate: string,
	hours: string,
	minutes: string,
	timeFormat: TimeFormat,
): Date => {
	const hoursValue = hours === '12' ? 0 : +hours;
	return moment(
		new Date(
			`${birthDate}, ${
				timeFormat === TimeFormat.PM ? hoursValue + 12 : hoursValue
			}:${minutes}`,
		),
	)
		.utc()
		.toDate();
};
