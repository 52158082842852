import React from 'react';

import {Modal} from 'antd';
import {LogEvent} from 'services';

import RatingStars from './RatingStars';

import modalStyles from '../Modal.module.scss';
import styles from './styles.module.scss';

const FeedbackModal: React.FC<{
	visible: boolean;
	onFeedbackSent: () => void;
}> = ({visible, onFeedbackSent}) => {
	const onStarClick = (rating: number) => {
		LogEvent.saveEvent('Web Feedback - Marks', {
			mark: rating,
		});
		onFeedbackSent();
	};

	const renderContent = () => {
		return (
			<div>
				<div className={styles.title}>Feedback</div>
				<div className={styles.question}>
					How likely are you to recommend Aster to a friend or colleague?
				</div>
				<RatingStars onStarClick={onStarClick} />
				<div className={styles.legend}>
					<span>Not at all likely</span>
					<span>Extremely likely</span>
				</div>
			</div>
		);
	};

	return (
		<Modal
			visible={visible}
			modalRender={renderContent}
			destroyOnClose
			wrapClassName={modalStyles.wrapper}
			className={styles.container}
		/>
	);
};

export default FeedbackModal;
