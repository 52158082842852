const STORAGE_KEYS = {
	AUTH_TOKEN: 'authToken',
	QUOTE: 'quote',
	ZODIAC_SIGNS: 'zodiacSigns',

	// feedback
	FEEDBACK_SENT: 'ASTER_FEEDBACK_SENT',
	FEEDBACK_CHECK_END: 'ASTER_FEEDBACK_CHECK_END',
	FEEDBACK_SKIP_END: 'ASTER_FEEDBACK_SKIP_END',
	LIKE_COUNT: 'ASTER_LIKE_COUNT',
	COMPATIBILITY_COUNT: 'ASTER_COMPATIBILITY_COUNT',
	SESSION_COUNT: 'ASTER_SESSION_COUNT',
	PRODUCT: 'PRODUCT',
};
export default STORAGE_KEYS;
