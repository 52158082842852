import React, {FC, useState} from 'react';

import {DatePicker} from 'antd';
import moment, {Moment} from 'moment';

import AppText from '../../appText';

import 'antd/dist/antd.min.css';
import styles from '../style.module.scss';

type PropsType = {
	label: string;
	value: Moment;
	isLabel?: boolean;
	onChange: (e: Moment | null) => void;
};

const Datepicker: FC<PropsType> = ({isLabel, label, value, onChange}) => {
	const [date, setDate] = useState<Moment>(value);

	const onDatepickerChange = (e: Moment | null) => {
		if (e) {
			setDate(e);
			onChange(e);
		}
	};

	return (
		<div>
			{isLabel && <AppText className={styles.label}>{label}</AppText>}
			<DatePicker
				onChange={onDatepickerChange}
				className={`${styles.datepicker} ${styles.inputContainer} `}
				bordered={false}
				defaultValue={moment(value, 'YYYY-MM-DD')}
				value={date}
				showToday={false}
				dropdownClassName={styles.datepickerPopup}
				disabledDate={current => {
					return (
						(current && current < moment('1943-01-01', 'YYYY-MM-DD')) ||
						current > moment(new Date(), 'YYYY-MM-DD')
					);
				}}
			/>
		</div>
	);
};

export default Datepicker;
