import React from 'react';
import {ReactSVG} from 'react-svg';

import {logo, preloaderAnimation} from 'assets';
import Lottie from 'lottie-react';

import './index.css';
import './app.scss';

const Preloader = () => {
	return (
		<div className="wrapper">
			<div className="container">
				<div className="header auth">
					<ReactSVG wrapper="span" src={logo} className="logo" />
				</div>
				<Lottie loop animationData={preloaderAnimation} className="animation" />
			</div>
		</div>
	);
};

export default Preloader;
