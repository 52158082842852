import React, {FC} from 'react';

import AppText from '../appText';

import styles from './style.module.scss';

type PropsType = {
	percentage: number;
	barTitle: string;
};

const PercentageIndicator: FC<PropsType> = ({percentage, barTitle}) => {
	const percentageValue = 100 - percentage + 3;
	return (
		<div className={styles.circle}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="96px"
				height="96px"
				viewBox="-1 -1 34 34">
				<circle cx="16" cy="16" r="15" className={styles.barBg} />

				<circle
					cx="16"
					cy="16"
					r="15"
					className={styles.progress}
					style={{strokeDashoffset: `${percentageValue}px`}}
				/>
			</svg>
			<div className={styles.percentage}>{percentage}%</div>
			<AppText className={styles.barTitle}>{barTitle}</AppText>
		</div>
	);
};

export default PercentageIndicator;
