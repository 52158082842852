import React, {FC, ReactNode} from 'react';

import {Modal} from 'antd';
import {downloadReportPreloaderAnimation} from 'assets';
import Lottie from 'lottie-react';

import AppText from '../appText';
import ModalContent from '../modalContent';

import styles from './style.module.scss';

type PropsType = {
	showModal: boolean;
};

const modalContent = (): ReactNode => (
	<ModalContent className={styles.modalContentWrapper}>
		<Lottie loop animationData={downloadReportPreloaderAnimation} />
		<AppText className={styles.modalTitle}>
			Your report is almost ready. Please wait some time please
		</AppText>
	</ModalContent>
);

const DownloadReportPreloaderModal: FC<PropsType> = ({showModal}) => {
	return (
		<Modal
			style={{top: 20}}
			centered
			wrapClassName={styles.modal}
			visible={showModal}
			modalRender={modalContent}
		/>
	);
};

export default DownloadReportPreloaderModal;
