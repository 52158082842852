import {
	Biorhythms,
	City,
	CurrentMoonPhase,
	FriendDataResponseType,
	Gender,
	LifePathNumbersTypes,
	MoonPhase,
	SignFocusOfTheDay,
	SignMission,
	SignQuote,
	SignStrengthsAndWeknesses,
	SignSymbolismAndMyth,
	TodayLuckyNumberTypes,
	ZodiacAccordionDataType,
	ZodiacSignInfo,
} from 'types';

export enum FriendsActions {
	SET_FRIENDS = 'friend/SET_FRIENDS',
	SET_FRIEND_NAME = 'friends/SET_FRIEND_NAME',
	SET_FRIEND_CITY = 'friends/SET_FRIEND_CITY',
	SET_FRIEND_GENDER = 'friends/SET_FRIEND_GENDER',
	SET_FRIEND_BIRTH_DATE = 'friends/SET_FRIEND_BIRTH_DATE',
	SET_FRIEND_BIRTH_DATE_OFFSET = 'friends/SET_FRIEND_BIRTH_DATE_OFFSET',
	SET_SELECTED_PROFILE_TOKEN = 'friends/SET_SELECTED_PROFILE_TOKEN',
	SET_SELECTED_FRIEND_ACCORDION_DATA = 'friends/SET_SELECTED_FRIEND_ACCORDION_DATA',
	SET_SELECTED_FRIEND_STRENGTHS = 'friends/SET_SELECTED_FRIEND_STRENGTHS',
	SET_SELECTED_FRIEND_WEAKNESSES = 'friends/SET_SELECTED_FRIEND_WEAKNESSES',
	SET_SELECTED_FRIEND_SYMBOLISM_AND_MYTH = 'friends/SET_SELECTED_FRIEND_SYMBOLISM_AND_MYTH',
	SET_SELECTED_FRIEND_MISSION = 'friends/SET_SELECTED_FRIEND_MISSION',
	SET_SELECTED_FRIEND_ZODIAC_SIGN_INFO = 'friends/SET_SELECTED_FRIEND_ZODIAC_SIGN_INFO',
	SET_SELECTED_FRIEND_QUOTE = 'friends/SET_SELECTED_FRIEND_QUOTE',
	SET_SELECTED_FRIEND_FOCUS_OF_THE_DAY = 'friends/SET_SELECTED_FRIEND_FOCUS_OF_THE_DAY',
	SET_SELECTED_FRIEND_LIFE_PATH_NUMBERS = 'friends/SET_SELECTED_FRIEND_SET_LIFE_PATH_NUMBERS',
	SET_SELECTED_FRIEND_TODAY_LUCKY_NUMBER = 'friends/SET_SELECTED_FRIEND_TODAY_LUCKY_NUMBER',
	SET_SELECTED_FRIEND_BIORHYTHMS = 'friends/SET_SELECTED_FRIEND_SET_BIORHYTHMS',
	SET_SELECTED_FRIEND_MOON_PHASES = 'friends/SET_SELECTED_FRIEND_SET_MOON_PHASES',
	SET_SELECTED_FRIEND_CURRENT_MOON_PHASES = 'friends/SET_SELECTED_FRIEND_SET_CURRENT_MOON_PHASES',
}

export type SetFriends = {type: FriendsActions.SET_FRIENDS; payload: FriendDataResponseType[]};
export type SetFriendName = {type: FriendsActions.SET_FRIEND_NAME; payload: string};
export type SetFriendCity = {type: FriendsActions.SET_FRIEND_CITY; payload: City};
export type SetFriendGender = {type: FriendsActions.SET_FRIEND_GENDER; payload: Gender};
export type SetFriendBirthDate = {type: FriendsActions.SET_FRIEND_BIRTH_DATE; payload: Date};
export type SetFriendBirthDateOffset = {
	type: FriendsActions.SET_FRIEND_BIRTH_DATE_OFFSET;
	payload: number;
};
export type SetSelectedProfileToken = {
	type: FriendsActions.SET_SELECTED_PROFILE_TOKEN;
	payload: string;
};
export type SetSelectedFriendAccordionData = {
	type: FriendsActions.SET_SELECTED_FRIEND_ACCORDION_DATA;
	payload: ZodiacAccordionDataType;
};
export type SetSelectedFriendStrengths = {
	type: FriendsActions.SET_SELECTED_FRIEND_STRENGTHS;
	payload: SignStrengthsAndWeknesses;
};
export type SetSelectedFriendWeaknesses = {
	type: FriendsActions.SET_SELECTED_FRIEND_WEAKNESSES;
	payload: SignStrengthsAndWeknesses;
};
export type SetSelectedFriendSymbolismAndMyth = {
	type: FriendsActions.SET_SELECTED_FRIEND_SYMBOLISM_AND_MYTH;
	payload: SignSymbolismAndMyth;
};
export type SetSelectedFriendMission = {
	type: FriendsActions.SET_SELECTED_FRIEND_MISSION;
	payload: SignMission;
};
export type SetSelectedFriendZodiacSignInfo = {
	type: FriendsActions.SET_SELECTED_FRIEND_ZODIAC_SIGN_INFO;
	payload: ZodiacSignInfo;
};
type SetSelectedFriendQuote = {type: FriendsActions.SET_SELECTED_FRIEND_QUOTE; payload: SignQuote};
export type SetSelectedFriendFocusOfTheDay = {
	type: FriendsActions.SET_SELECTED_FRIEND_FOCUS_OF_THE_DAY;
	payload: SignFocusOfTheDay;
};
export type SetSelectedFriendLifePathNumbers = {
	type: FriendsActions.SET_SELECTED_FRIEND_LIFE_PATH_NUMBERS;
	payload: LifePathNumbersTypes;
};
export type SetSelectedFriendTodayLuckyNumber = {
	type: FriendsActions.SET_SELECTED_FRIEND_TODAY_LUCKY_NUMBER;
	payload: TodayLuckyNumberTypes;
};
export type SetSelectedFriendBiorhythms = {
	type: FriendsActions.SET_SELECTED_FRIEND_BIORHYTHMS;
	payload: Biorhythms;
};
export type SetSelectedFriendMoonPhases = {
	type: FriendsActions.SET_SELECTED_FRIEND_MOON_PHASES;
	payload: MoonPhase[];
};
export type SetSelectedFriendCurrentMoonPhases = {
	type: FriendsActions.SET_SELECTED_FRIEND_CURRENT_MOON_PHASES;
	payload: CurrentMoonPhase;
};

export const setFriends = (payload: FriendDataResponseType[]): SetFriends => ({
	type: FriendsActions.SET_FRIENDS,
	payload,
});

export const setFriendName = (payload: string): SetFriendName => ({
	type: FriendsActions.SET_FRIEND_NAME,
	payload,
});

export const setFriendCity = (payload: City): SetFriendCity => ({
	type: FriendsActions.SET_FRIEND_CITY,
	payload,
});

export const setFriendGender = (payload: Gender): SetFriendGender => ({
	type: FriendsActions.SET_FRIEND_GENDER,
	payload,
});

export const setFriendBirthDate = (payload: Date): SetFriendBirthDate => ({
	type: FriendsActions.SET_FRIEND_BIRTH_DATE,
	payload,
});

export const setFriendBirthDateOffset = (payload: number): SetFriendBirthDateOffset => ({
	type: FriendsActions.SET_FRIEND_BIRTH_DATE_OFFSET,
	payload,
});

export const setSelectedProfileToken = (payload: string): SetSelectedProfileToken => ({
	type: FriendsActions.SET_SELECTED_PROFILE_TOKEN,
	payload,
});

export const setSelectedFriendAccordionData = (
	payload: ZodiacAccordionDataType,
): SetSelectedFriendAccordionData => ({
	type: FriendsActions.SET_SELECTED_FRIEND_ACCORDION_DATA,
	payload,
});

export const setSelectedFriendStrengths = (
	payload: SignStrengthsAndWeknesses,
): SetSelectedFriendStrengths => {
	return {
		type: FriendsActions.SET_SELECTED_FRIEND_STRENGTHS,
		payload,
	};
};

export const setSelectedFriendWeaknesses = (
	payload: SignStrengthsAndWeknesses,
): SetSelectedFriendWeaknesses => {
	return {
		type: FriendsActions.SET_SELECTED_FRIEND_WEAKNESSES,
		payload,
	};
};

export const setSelectedFriendSymbolismAndMyth = (
	payload: SignSymbolismAndMyth,
): SetSelectedFriendSymbolismAndMyth => {
	return {
		type: FriendsActions.SET_SELECTED_FRIEND_SYMBOLISM_AND_MYTH,
		payload,
	};
};

export const setSelectedFriendMission = (payload: SignMission): SetSelectedFriendMission => {
	return {
		type: FriendsActions.SET_SELECTED_FRIEND_MISSION,
		payload,
	};
};

export const setSelectedFriendZodiacSignInfo = (
	payload: ZodiacSignInfo,
): SetSelectedFriendZodiacSignInfo => {
	return {
		type: FriendsActions.SET_SELECTED_FRIEND_ZODIAC_SIGN_INFO,
		payload,
	};
};

export const setSelectedFriendQuote = (payload: SignQuote): SetSelectedFriendQuote => {
	return {
		type: FriendsActions.SET_SELECTED_FRIEND_QUOTE,
		payload,
	};
};

export const setSelectedFriendFocusOfTheDay = (
	payload: SignFocusOfTheDay,
): SetSelectedFriendFocusOfTheDay => {
	return {
		type: FriendsActions.SET_SELECTED_FRIEND_FOCUS_OF_THE_DAY,
		payload,
	};
};

export const setSelectedFriendLifePathNumbers = (
	payload: LifePathNumbersTypes,
): SetSelectedFriendLifePathNumbers => {
	return {
		type: FriendsActions.SET_SELECTED_FRIEND_LIFE_PATH_NUMBERS,
		payload,
	};
};

export const setSelectedFriendTodayLuckyNumber = (
	payload: TodayLuckyNumberTypes,
): SetSelectedFriendTodayLuckyNumber => {
	return {
		type: FriendsActions.SET_SELECTED_FRIEND_TODAY_LUCKY_NUMBER,
		payload,
	};
};

export const setSelectedFriendBiorhythms = (payload: Biorhythms): SetSelectedFriendBiorhythms => {
	return {
		type: FriendsActions.SET_SELECTED_FRIEND_BIORHYTHMS,
		payload,
	};
};

export const setSelectedFriendMoonPhases = (payload: MoonPhase[]): SetSelectedFriendMoonPhases => {
	return {
		type: FriendsActions.SET_SELECTED_FRIEND_MOON_PHASES,
		payload,
	};
};

export const setSelectedFriendCurrentMoonPhases = (
	payload: CurrentMoonPhase,
): SetSelectedFriendCurrentMoonPhases => {
	return {
		type: FriendsActions.SET_SELECTED_FRIEND_CURRENT_MOON_PHASES,
		payload,
	};
};

export type FriendsActionTypes =
	| SetFriends
	| SetFriendName
	| SetFriendCity
	| SetFriendGender
	| SetFriendBirthDate
	| SetFriendBirthDateOffset
	| SetSelectedProfileToken
	| SetSelectedFriendAccordionData
	| SetSelectedFriendStrengths
	| SetSelectedFriendWeaknesses
	| SetSelectedFriendSymbolismAndMyth
	| SetSelectedFriendMission
	| SetSelectedFriendZodiacSignInfo
	| SetSelectedFriendQuote
	| SetSelectedFriendFocusOfTheDay
	| SetSelectedFriendLifePathNumbers
	| SetSelectedFriendTodayLuckyNumber
	| SetSelectedFriendBiorhythms
	| SetSelectedFriendMoonPhases
	| SetSelectedFriendCurrentMoonPhases;
