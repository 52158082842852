import Amplitude from 'amplitude-js';
import {Log} from 'types';

class AmplitudeService {
	static init() {
		const ampInstance = Amplitude.getInstance();
		ampInstance.init(process.env.REACT_APP_AMPLITUDE_KEY);
	}

	static setUserId(token: string) {
		Amplitude.getInstance().setUserId(token);
	}

	static saveEvent(event: string, props?: Log) {
		Amplitude.getInstance().logEvent(event, props);
	}
}

export default AmplitudeService;
