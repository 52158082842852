import React, {FC} from 'react';
import {ReactSVG} from 'react-svg';

import {BiorhythmCategory} from 'types';
import {capitalizeFirstLetter} from 'utility';

import AppText from '../appText';

import styles from './style.module.scss';

type Props = {
	data: BiorhythmCategory;
	title: string;
	icon: string;
};

const BiorhythmsDescription: FC<Props> = ({data, title, icon}) => {
	return (
		<li className={styles.description}>
			<div>
				<AppText className={styles.title}>
					{`${capitalizeFirstLetter(title)} biorhythms`}
				</AppText>
				<AppText className={styles.subtitle}>
					{data.values[0].value}% activity level
				</AppText>
				<div className={data.values[0].value < 0 ? styles.revert : ''}>
					<ReactSVG wrapper="span" src={icon} className={styles.icon} />
				</div>
			</div>
			<AppText className={styles.text}>{data.description}</AppText>
		</li>
	);
};

export default BiorhythmsDescription;
