import {CategoryEventsTypes, EnumTypes} from 'types';

import {SetUserData} from './user';

export enum GeneralActions {
	SET_INIT = 'general/setInit',
	SET_ENUMS = 'general/setEnums',
	SET_CATEGORY_EVENTS = 'general/CategoryEvents',
}

type SetEnums = {type: GeneralActions.SET_ENUMS; payload: EnumTypes};
type SetInit = {type: GeneralActions.SET_INIT};
type SetCategoryEvents = {
	type: GeneralActions.SET_CATEGORY_EVENTS;
	payload: CategoryEventsTypes[];
};

export const setEnums = (payload: EnumTypes): SetEnums => ({
	type: GeneralActions.SET_ENUMS,
	payload,
});

export const setInit = (): SetInit => ({
	type: GeneralActions.SET_INIT,
});

export const setCategoryEvents = (payload: CategoryEventsTypes[]): SetCategoryEvents => ({
	type: GeneralActions.SET_CATEGORY_EVENTS,
	payload,
});

export type GeneralActionTypes = SetEnums | SetInit | SetUserData | SetCategoryEvents;
