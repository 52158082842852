import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {ROUTES, STORAGE_KEYS} from 'const';
import {Storage} from 'utility';

const authRedirect =
	(redirectTo = ROUTES.PRIVATE.PROFILE) =>
	Component =>
		function redirect(props) {
			const navigate = useNavigate();
			const token = Storage.getData(STORAGE_KEYS.AUTH_TOKEN);

			useEffect(() => {
				if (token) {
					navigate(redirectTo);
				}
			}, [token]);

			return <Component {...props} />;
		};

export default authRedirect;
