import React, {FC, useEffect, useState} from 'react';
import Calendar from 'react-calendar';
import {useDispatch} from 'react-redux';
import {ReactSVG} from 'react-svg';

import {
	educationTab,
	healthTab,
	loveTab,
	nextArrow,
	noRecommendationsIcon,
	okRecommendationIcon,
	workTab,
} from 'assets';
import {LogEvent} from 'services';
import {fetchCalendars, generalSelector, GetCalendarsData} from 'store';
import {CalendarDaysType, CalendarItemKey, EventsItemType} from 'types/common';
import {compareDates, getNextMonthLastDay, getPrevMonthFirstDay, todayDate} from 'utility';

import AppText from '../appText';

import './style.scss';
import styles from './style.module.scss';

type PropsType = {
	activeZodiacSign: number;
};

const ActivityCalendars: FC<PropsType> = ({activeZodiacSign}) => {
	const dispatch = useDispatch();
	const [activeDay, setActiveDay] = useState<Date>(todayDate);
	const [activeTab, setActiveTab] = useState<CalendarItemKey>('love');
	const [monthDaysWithActivities, setMonthDaysWithActivities] = useState<CalendarDaysType[]>([]);
	const [recommendedActivities, setRecommendedActivities] = useState<number[]>([]);
	const [eventsItems, setEventsItems] = useState<EventsItemType[]>([]);
	const calendarsData = GetCalendarsData();
	const categoryEventsData = generalSelector.GetCategoryEvents();

	useEffect(() => {
		if (activeZodiacSign) {
			dispatch(fetchCalendars(activeZodiacSign, 0));
		}
	}, [activeZodiacSign]);

	useEffect(() => {
		if (calendarsData && activeTab) {
			setMonthDaysWithActivities(
				calendarsData[activeTab].days.filter(
					items => new Date(items.date).getMonth() === activeDay.getMonth(),
				),
			);

			const dayEvents = calendarsData[activeTab].days.find(item =>
				compareDates(activeDay, item.date),
			);
			setRecommendedActivities(dayEvents ? dayEvents.events : []);
		}
	}, [calendarsData, activeTab, activeDay]);

	useEffect(() => {
		if (categoryEventsData && categoryEventsData.length) {
			const eventsValues = categoryEventsData.find(
				item => item.category.value.toLowerCase() === activeTab,
			)?.events;

			setEventsItems(eventsValues || []);
		}
	}, [JSON.stringify(categoryEventsData), activeTab]);

	const clickDateHandler = setSelectedDay => {
		setActiveDay(setSelectedDay);
	};

	const changeMonthHandler = ({activeStartDate}) => {
		setActiveDay(activeStartDate);
		LogEvent.saveEvent('Web Horoscope - Calendar Date Click');
	};

	const onCalendarTabClick = (tabName: CalendarItemKey) => {
		setActiveTab(tabName);
		LogEvent.saveEvent('Web Horoscope - Calendar Click', {calendarType: tabName});
	};

	const tabsData = [
		{
			name: 'Love',
			key: 1,
			onClick() {
				onCalendarTabClick('love');
			},
			tabLink: loveTab,
		},
		{
			name: 'Health',
			key: 2,
			onClick() {
				onCalendarTabClick('health');
			},
			tabLink: healthTab,
		},
		{
			name: 'Work',
			key: 3,
			onClick() {
				onCalendarTabClick('work');
			},
			tabLink: workTab,
		},
		{
			name: 'Education',
			key: 4,
			onClick() {
				onCalendarTabClick('education');
			},
			tabLink: educationTab,
		},
	];

	const activitiesTabsList = tabsData.map(item => (
		<button
			type="button"
			className={styles.activityTypeWrapper}
			key={item.name}
			onClick={item.onClick}>
			<ReactSVG
				className={`${styles.activityTypeTab} ${
					activeTab === item.name.toLowerCase() ? styles.activeTab : styles.inactiveTab
				}`}
				wrapper="span"
				src={item.tabLink}
			/>
			<AppText
				className={
					activeTab === item.name.toLowerCase() ? styles.activeTabTitle : styles.tabTitle
				}>
				{item.name}
			</AppText>
		</button>
	));

	const calendarDaysWithActivities = ({date}) => {
		const dayItem = monthDaysWithActivities.find(item => compareDates(date, item.date));
		return dayItem ? (
			<div className={`dateWithActivities activities${dayItem.events.length}`}>
				{date.getDate()}
			</div>
		) : (
			<div className="date">{date.getDate()}</div>
		);
	};

	const recommendedActivitiesList =
		recommendedActivities.length && eventsItems.length && eventsItems ? (
			recommendedActivities.map(item => (
				<div className={styles.activity} key={item}>
					<ReactSVG
						wrapper="span"
						className={styles.activityIcon}
						src={okRecommendationIcon}
					/>
					<AppText className={styles.activityTitle}>
						{eventsItems.find(ev => ev.key === item)!.value}
					</AppText>
				</div>
			))
		) : (
			<div className={`${styles.activity} ${styles.noRecommendations}`}>
				<ReactSVG
					wrapper="span"
					className={styles.activityIcon}
					src={noRecommendationsIcon}
				/>
				<AppText className={styles.activityTitle}>No recommendations</AppText>
			</div>
		);

	return (
		<div className={styles.activityWrapper}>
			<AppText className={styles.activitiesTitle}>Activity calendar</AppText>
			<div className={styles.calendarWrapper}>
				<Calendar
					className={styles.calendar}
					prev2Label={null}
					next2Label={null}
					prevLabel={<ReactSVG wrapper="span" src={nextArrow} />}
					nextLabel={<ReactSVG wrapper="span" src={nextArrow} />}
					locale="en"
					maxDetail="month"
					minDetail="month"
					minDate={getPrevMonthFirstDay()}
					maxDate={getNextMonthLastDay()}
					onActiveStartDateChange={changeMonthHandler}
					onClickDay={clickDateHandler}
					tileContent={calendarDaysWithActivities}
					value={activeDay}
				/>

				<div className={styles.activitiesTypesWrapper}>{activitiesTabsList}</div>

				<div className={styles.recommendedActivities}>
					<AppText className={styles.recommendedActivitiesTitle}>
						Recommended activities:
					</AppText>
					{recommendedActivitiesList}
				</div>
			</div>
		</div>
	);
};

export default ActivityCalendars;
