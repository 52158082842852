import React, {FC} from 'react';

import AppText from '../../appText';
import Footer from '../../footer';

import styles from './style.module.scss';

type PropsType = {
	className?: string;
};

const AuthContainer: FC<PropsType> = ({className = ''}) => (
	<div className={`${styles.container} ${className}`}>
		<div className={styles.content}>
			<div>
				<AppText isSubtitle className={styles.title}>
					Hyper-personalized astrology
				</AppText>
				<AppText>
					We use NASA data to compare the position of the planets to the exact time, date,
					and place of your birth to produce the blueprint of your astrological identity.
				</AppText>
			</div>
		</div>
		<Footer />
	</div>
);

export default AuthContainer;
