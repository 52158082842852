import React, {FC} from 'react';
import {useNavigate} from 'react-router-dom';

import TextButton from 'components/buttons/textButton';
import Logo from 'components/logo';
import {ROUTES} from 'const';

import styles from './style.module.scss';

type PropsType = {
	isAuth?: boolean;
	className?: string;
	showBackButton?: boolean;
};

const Header: FC<PropsType> = ({isAuth = false, showBackButton = false, className = ''}) => {
	const navigate = useNavigate();

	const onCancelClick = () => {
		navigate(ROUTES.PRIVATE.PROFILE);
	};

	return (
		<div className={`${styles.header} ${className} ${isAuth ? styles.auth : ''}`}>
			<Logo className={styles.headerLogo} />
			{showBackButton ? (
				<TextButton className={styles.textButton} onClick={onCancelClick}>
					Cancel
				</TextButton>
			) : (
				''
			)}
		</div>
	);
};

export default Header;
