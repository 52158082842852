import {Log} from 'types';

import Amplitude from '../amplitude';

class LogEvent {
	static init() {
		Amplitude.init();
	}

	static setUserId(token: string) {
		Amplitude.setUserId(token);
	}

	static saveEvent(event: string, props?: Log) {
		Amplitude.saveEvent(event, props);
	}
}

export default LogEvent;
