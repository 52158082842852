import {ChargeBeeSubscription} from 'types';

import {appInstance} from '../HTTPClient';

export const sendFile = (
	email: string,
	zodiacSign: number | undefined,
	subscriptionFileType: number | undefined,
) => {
	return appInstance.post('subscriptions/files', {
		email,
		zodiacSign,
		subscriptionFileType,
	});
};

export const buyPDFWithChargebee = (data: ChargeBeeSubscription) => {
	return appInstance.post('subscriptions', data);
};
