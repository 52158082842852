import {
	aquarius,
	aquariusAccordion,
	aquariusSlider,
	aquariusSmallSlider,
	aquariusSmallSliderActive,
	aquariusStars,
	aries,
	ariesAccordion,
	ariesSlider,
	ariesSmallSlider,
	ariesSmallSliderActive,
	ariesStars,
	cancer,
	cancerAccordion,
	cancerSlider,
	cancerSmallSlider,
	cancerSmallSliderActive,
	cancerStars,
	capricorn,
	capricornAccordion,
	capricornSlider,
	capricornSmallSlider,
	capricornSmallSliderActive,
	capricornStars,
	gemini,
	geminiAccordion,
	geminiSlider,
	geminiSmallSlider,
	geminiSmallSliderActive,
	geminiStars,
	leo,
	leoAccordion,
	leoSlider,
	leoSmallSlider,
	leoSmallSliderActive,
	leoStars,
	libra,
	libraAccordion,
	libraSlider,
	libraSmallSlider,
	libraSmallSliderActive,
	libraStars,
	pisces,
	piscesAccordion,
	piscesSlider,
	piscesSmallSlider,
	piscesSmallSliderActive,
	piscesStars,
	sagittarius,
	sagittariusAccordion,
	sagittariusSlider,
	sagittariusSmallSlider,
	sagittariusSmallSliderActive,
	sagittariusStars,
	scorpio,
	scorpioAccordion,
	scorpioSlider,
	scorpioSmallSlider,
	scorpioSmallSliderActive,
	scorpioStars,
	smallZodiacAquarius,
	smallZodiacAries,
	smallZodiacCancer,
	smallZodiacCapricorn,
	smallZodiacGemini,
	smallZodiacLeo,
	smallZodiacLibra,
	smallZodiacPisces,
	smallZodiacSagittarius,
	smallZodiacScorpio,
	smallZodiacTaurus,
	smallZodiacVirgo,
	taurus,
	taurusAccordion,
	taurusSlider,
	taurusSmallSlider,
	taurusSmallSliderActive,
	taurusStars,
	virgo,
	virgoAccordion,
	virgoSlider,
	virgoSmallSlider,
	virgoSmallSliderActive,
	virgoStars,
} from 'assets';

const ZODIACS = [
	{
		value: 'Aries',
		key: 1,
		dates: 'Mar 21 – Apr 20',
		icon: aries,
		iconStars: ariesStars,
		iconsSlider: ariesSlider,
		iconsSmall: ariesSmallSlider,
		iconsSmallActive: ariesSmallSliderActive,
		accordionIcon: ariesAccordion,
		astrologyIcon: smallZodiacAries,
	},
	{
		value: 'Taurus',
		key: 2,
		dates: 'Apr 21 – May 21',
		icon: taurus,
		iconStars: taurusStars,
		iconsSlider: taurusSlider,
		iconsSmall: taurusSmallSlider,
		iconsSmallActive: taurusSmallSliderActive,
		accordionIcon: taurusAccordion,
		astrologyIcon: smallZodiacTaurus,
	},
	{
		value: 'Gemini',
		key: 3,
		dates: 'May 22 - Jun 21',
		icon: gemini,
		iconStars: geminiStars,
		iconsSlider: geminiSlider,
		iconsSmall: geminiSmallSlider,
		iconsSmallActive: geminiSmallSliderActive,
		accordionIcon: geminiAccordion,
		astrologyIcon: smallZodiacGemini,
	},
	{
		value: 'Cancer',
		key: 4,
		dates: 'Jun 22 – Jul 22',
		icon: cancer,
		iconStars: cancerStars,
		iconsSlider: cancerSlider,
		iconsSmall: cancerSmallSlider,
		iconsSmallActive: cancerSmallSliderActive,
		accordionIcon: cancerAccordion,
		astrologyIcon: smallZodiacCancer,
	},
	{
		value: 'Leo',
		key: 5,
		dates: 'Jul 23 – Aug 23',
		icon: leo,
		iconStars: leoStars,
		iconsSlider: leoSlider,
		iconsSmall: leoSmallSlider,
		iconsSmallActive: leoSmallSliderActive,
		accordionIcon: leoAccordion,
		astrologyIcon: smallZodiacLeo,
	},
	{
		value: 'Virgo',
		key: 6,
		dates: 'Aug 24 – Sep 22',
		icon: virgo,
		iconStars: virgoStars,
		iconsSlider: virgoSlider,
		iconsSmall: virgoSmallSlider,
		iconsSmallActive: virgoSmallSliderActive,
		accordionIcon: virgoAccordion,
		astrologyIcon: smallZodiacVirgo,
	},
	{
		value: 'Libra',
		key: 7,
		dates: 'Sep 23 – Oct 23',
		icon: libra,
		iconStars: libraStars,
		iconsSlider: libraSlider,
		iconsSmall: libraSmallSlider,
		iconsSmallActive: libraSmallSliderActive,
		accordionIcon: libraAccordion,
		astrologyIcon: smallZodiacLibra,
	},
	{
		value: 'Scorpio',
		key: 8,
		dates: 'Oct 24 – Nov 22',
		icon: scorpio,
		iconStars: scorpioStars,
		iconsSlider: scorpioSlider,
		iconsSmall: scorpioSmallSlider,
		iconsSmallActive: scorpioSmallSliderActive,
		accordionIcon: scorpioAccordion,
		astrologyIcon: smallZodiacScorpio,
	},
	{
		value: 'Sagittarius',
		key: 9,
		dates: 'Nov 23 – Dec 21',
		icon: sagittarius,
		iconStars: sagittariusStars,
		iconsSlider: sagittariusSlider,
		iconsSmall: sagittariusSmallSlider,
		iconsSmallActive: sagittariusSmallSliderActive,
		accordionIcon: sagittariusAccordion,
		astrologyIcon: smallZodiacSagittarius,
	},
	{
		value: 'Capricorn',
		key: 10,
		dates: 'Dec 22 – Jan 20',
		icon: capricorn,
		iconStars: capricornStars,
		iconsSlider: capricornSlider,
		iconsSmall: capricornSmallSlider,
		iconsSmallActive: capricornSmallSliderActive,
		accordionIcon: capricornAccordion,
		astrologyIcon: smallZodiacCapricorn,
	},
	{
		value: 'Aquarius',
		key: 11,
		dates: 'Jan 21 – Feb 18',
		icon: aquarius,
		iconStars: aquariusStars,
		iconsSlider: aquariusSlider,
		iconsSmall: aquariusSmallSlider,
		iconsSmallActive: aquariusSmallSliderActive,
		accordionIcon: aquariusAccordion,
		astrologyIcon: smallZodiacAquarius,
	},
	{
		value: 'Pisces',
		key: 12,
		dates: 'Feb 19 – Mar 20',
		icon: pisces,
		iconStars: piscesStars,
		iconsSlider: piscesSlider,
		iconsSmall: piscesSmallSlider,
		iconsSmallActive: piscesSmallSliderActive,
		accordionIcon: piscesAccordion,
		astrologyIcon: smallZodiacPisces,
	},
];

export default ZODIACS;
