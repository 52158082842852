import {
	analystM,
	analystW,
	designerM,
	designerW,
	investigatorM,
	investigatorW,
	managerM,
	managerW,
	mediatorM,
	mediatorW,
	scientistM,
	scientistW,
	surgeonM,
	surgeonW,
	teacherM,
	teacherW,
} from 'assets';

const PROFESSIONS = [
	{
		key: 1,
		value: 'Business owner',
		icon: [analystM, analystW],
	},
	{
		key: 2,
		value: 'Data analyst',
		icon: [analystM, analystW],
	},
	{
		key: 3,
		value: 'Designer',
		icon: [designerM, designerW],
	},
	{
		key: 4,
		value: 'Engineer',
		icon: [analystM, analystW],
	},
	{
		key: 5,
		value: 'Fashion designer',
		icon: [designerM, designerW],
	},
	{
		key: 6,
		value: 'Financial analyst',
		icon: [analystM, analystW],
	},
	{
		key: 7,
		value: 'Investigator',
		icon: [investigatorM, investigatorW],
	},
	{
		key: 8,
		value: 'Legal analyst',
		icon: [managerM, managerW],
	},
	{
		key: 9,
		value: 'Manager',
		icon: [analystM, analystW],
	},
	{
		key: 10,
		value: 'Marketer',
		icon: [analystM, analystW],
	},
	{
		key: 11,
		value: 'Mediator',
		icon: [mediatorM, mediatorW],
	},
	{
		key: 12,
		value: 'Physician',
		icon: [surgeonM, surgeonW],
	},
	{
		key: 13,
		value: 'Project manager',
		icon: [managerM, managerW],
	},
	{
		key: 14,
		value: 'Recruiter',
		icon: [analystM, analystW],
	},
	{
		key: 15,
		value: 'Scientist',
		icon: [scientistM, scientistW],
	},
	{
		key: 16,
		value: 'Statistician',
		icon: [managerM, managerW],
	},
	{
		key: 17,
		value: 'Surgeon',
		icon: [surgeonM, surgeonW],
	},
	{
		key: 18,
		value: 'Teacher',
		icon: [teacherM, teacherW],
	},
	{
		key: 19,
		value: 'Therapist',
		icon: [surgeonM, surgeonW],
	},
];

export default PROFESSIONS;
