import {LoginResponseType, UserDataType} from 'types';

import {appInstance, appInstanceWithInterceptors} from '../HTTPClient';

type LoginType = {
	username: string;
	password: string;
};

export const login = ({username, password}: LoginType) =>
	appInstance
		.post<LoginResponseType>('auth', {username, password})
		.then(response => response.data);

export const authUser = () =>
	appInstanceWithInterceptors.get<UserDataType>('users').then(response => response.data);

export const setEmailForForgotPassword = (email: string) => {
	return appInstance.put('auth/sendCode', {email});
};

export const resetPassword = (email: string, code: string, password: string) => {
	return appInstance
		.patch<LoginResponseType>('users/resetPassword', {
			email,
			code,
			password,
		})
		.then(response => response.data);
};

export const setPassword = ({userToken, verificationToken, password, firstName}) => {
	return appInstance.patch(`users/setPassword`, {
		userToken,
		verificationToken,
		password,
		firstName,
	});
};
