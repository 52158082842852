import {
	comingSoon,
	comingSoon1,
	comingSoon2,
	comingSoon3,
	pdfHover,
	productSlide,
	productSlide1,
	productSlide2,
	productSlide3,
	shopCareerReport,
	shopCareerReport2,
	shopCareerReport3,
	shopCareerReport4,
	shopHealthReport,
	shopHealthReport2,
	shopHealthReport3,
	shopHealthReport4,
	shopHomeReport,
	shopHomeReport2,
	shopHomeReport3,
	shopHomeReport4,
	shopLookReport,
	shopLookReport2,
	shopLookReport3,
	shopLookReport4,
	shopPdf,
} from 'assets';

import {ROUTES} from './routes';

const PRODUCTS = [
	{
		id: 1,
		title: 'Astrological Report',
		price: '19.99$',
		url: ROUTES.PRIVATE.PRODUCT,
		img: shopPdf,
		hoverImg: pdfHover,
		isActive: true,
		description: `The Astrological Guide provides information on taking care of yourself, the inherent intersection between self-care and astrology, background on the elements, sign-specific self-care guidance, and hundreds of activities tied to the zodiac signs.
		Combines the fascination of astrology with the never-ending interest in relationships to provide in-depth analysis and insight into a person’s personality and compatibility.
		There’s no better guide to personal growth than the stars!`,
		slides: [productSlide, productSlide1, productSlide2, productSlide3],
		chargebeeKey: 'Aster-PDF-Guide-USD-Daily',
		paypalKey: 'P-61933526AL2770826MKLRQCI',
	},
	{
		id: 2,
		title: 'Look Report',
		price: '4.99$',
		url: ROUTES.PRIVATE.PRODUCT,
		img: comingSoon1,
		hoverImg: comingSoon1,
		isActive: false,
		description: `Each zodiac sign is different when it comes to personality and personal preferences. The style of the zodiac sign also differs depending on which sun sign is the most dominant. It affects what you like, how you like to dress and what accessories you like.
		If too many brand launches confuse you (I hope you're not Twins!) and it's hard for you to decide what to wear, we fully understand you.
		But don't worry. We can tell you what you like according to your zodiac sign! This will help you narrow down your choices if you want to splurge this sale season!
		Read this in our Look report and get inspired by fashion.`,
		slides: [shopLookReport, shopLookReport2, shopLookReport3, shopLookReport4],
		chargebeeKey: 'Look-Report-PDF-USD',
		paypalKey: 'P-6LV87290CM3048904MLIP5FA',
	},
	{
		id: 3,
		title: 'Career Report',
		price: '9.99$',
		url: ROUTES.PRIVATE.PRODUCT,
		img: comingSoon,
		hoverImg: comingSoon,
		isActive: false,
		description: `If you've ever sat at your desk thinking "ugh, I hate this," you can go ahead and blame your birthday, kind of)
		Just like all aspects of your life, your zodiac sign has an influence on the things you value in a career—and, naturally, what path may be best for you.
		What is the best time of year to start looking for a new job?
		Which opportunity is perfect for you? You can know now your career by astrology.
		Depending on your zodiac signs, you can effortlessly pick an ideal profession for you and understand in what direction you can lead your life.`,
		slides: [shopCareerReport, shopCareerReport2, shopCareerReport3, shopCareerReport4],
		chargebeeKey: 'Career-Report-PDF-USD',
		paypalKey: 'P-31168569Y1923990UMLDNWMA',
	},
	{
		id: 5,
		title: 'Fitness & Health Report',
		price: '4.99$',
		url: ROUTES.PRIVATE.PRODUCT,
		img: comingSoon2,
		hoverImg: comingSoon2,
		isActive: false,
		description: `We're all drawn to certain fitness regimens or activities over others. But have you ever stopped to consider why you opt for swimming over yoga? Or why you'd much rather go for a hike than run three miles?
		It's not just about liking your workout, but also, ideally, finding something that helps you find mental balance in addition to the physical.
		In astrology, we can use the Sun Signs to help identify ways to use our energy that best suits your workout needs!`,
		slides: [shopHealthReport, shopHealthReport2, shopHealthReport3, shopHealthReport4],
		chargebeeKey: 'Fitness-Report-PDF-USD',
		paypalKey: 'P-70V39010YY9797006MNCWUWI',
	},
	{
		id: 6,
		title: 'Home & Decor Report',
		price: '4.99$',
		url: ROUTES.PRIVATE.PRODUCT,
		img: comingSoon3,
		hoverImg: comingSoon3,
		isActive: false,
		description: `How does one pick a home decor item that they won’t regret in a week because it doesn’t quite make sense in their space?
		While there is no concrete answer to this dilemma, there is a good starting line, particularly for those with a love for astrology.
		Yes, your zodiac sign can actually be a great guide for home decor.
		Home represents the most private side of your life, and it must suit your psyche or you will forever feel that something is missing or needs to be corrected. Home design can become an expensive project, too, so consulting your horoscope will give you clues ahead of time on how to design the right decor for you.`,
		slides: [shopHomeReport, shopHomeReport2, shopHomeReport3, shopHomeReport4],
		chargebeeKey: 'Home-Report-PDF-USD',
		paypalKey: 'P-4XL58899KU185483AMNCWVUQ',
	},
];

export default PRODUCTS;
