/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {FC, ReactNode, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {Modal} from 'antd';
import {addFriendIcon} from 'assets';
import AppText from 'components/appText';
import Button from 'components/buttons/button';
import FriendsListItem from 'components/friendsListItem';
import Logo from 'components/logo';
import ModalContent from 'components/modalContent';
import {ROUTES} from 'const';
import {
	deleteFriend,
	fetchFriends,
	friendsSelector,
	setSelectedProfileToken,
	userSelector,
} from 'store';
import {Storage} from 'utility';

import styles from './style.module.scss';

type PropsType = {
	isAuth?: boolean;
	className?: string;
};

const AddFriendHeader: FC<PropsType> = ({isAuth = false, className = ''}) => {
	const [modalActive, setModalActive] = useState<boolean>(false);
	const [selectedFriendName, setSelectedFriendName] = useState<string>('');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const friends = friendsSelector.GetFriendsList();
	const selectedProfileToken = friendsSelector.GetSelectedProfileToken();
	const userToken = userSelector.GetUserToken();

	useEffect(() => {
		if (!selectedProfileToken && userToken) {
			dispatch(setSelectedProfileToken(userToken));
		}

		if (selectedProfileToken && selectedProfileToken !== userToken) {
			const selectedFriendData = friends.find(item => item.token === selectedProfileToken);
			if (selectedFriendData) {
				setSelectedFriendName(selectedFriendData.firstName);
			}
		}
	}, [selectedProfileToken, userToken]);

	useEffect(() => {
		if (friends.length === 0) {
			dispatch(fetchFriends());
		}
	}, [friends.length]);

	const onAddFriendClick = () => {
		navigate(ROUTES.PRIVATE.ADD_FRIEND_PREVIEW);
	};

	const hideModal = () => setModalActive(false);

	const onDeleteFriendConfirmation = () => {
		if (selectedProfileToken) {
			dispatch(deleteFriend(selectedProfileToken));
			Storage.removeItem(selectedProfileToken);
		}
		setModalActive(false);
		if (userToken) dispatch(setSelectedProfileToken(userToken));
	};

	const friendsButtonsList = friends
		? friends.map(item => (
				<FriendsListItem
					key={item.token}
					friendData={item}
					onDeleteClick={() => setModalActive(true)}
				/>
		  ))
		: '';

	const modalContent = (): ReactNode => (
		<ModalContent className={styles.modalContentWrapper}>
			<AppText className={styles.modalTitle}>Are you sure?</AppText>
			<AppText className={styles.modalText}>
				Are you sure you want to remove the {selectedFriendName} profile?
			</AppText>
			<AppText className={styles.modalText}>
				It will not be possible to undo the action
			</AppText>
			<Button
				className={`${styles.buttonStyles} ${styles.cancelButton}`}
				variant="secondary"
				onClick={hideModal}>
				Cancel
			</Button>
			<Button
				className={styles.buttonStyles}
				variant="primary"
				onClick={onDeleteFriendConfirmation}>
				Yes, get it
			</Button>
		</ModalContent>
	);

	return (
		<div className={`${styles.header} ${className} ${isAuth ? styles.auth : ''}`}>
			<Logo className={styles.headerLogo} />
			<div className={styles.friendsWrapper}>
				<FriendsListItem />
				<div className={styles.friendsList}>{friendsButtonsList}</div>
				{friends.length < 5 ? (
					<button
						type="button"
						className={styles.addFriendButton}
						onClick={onAddFriendClick}>
						<div className={styles.addFriendWrapper}>
							<img
								src={addFriendIcon}
								alt="friend avatar"
								className={styles.addFriendIcon}
							/>
						</div>
						<p>Add friend</p>
					</button>
				) : (
					''
				)}
			</div>
			<Modal
				style={{top: 20}}
				width={270}
				centered
				wrapClassName={styles.modal}
				visible={modalActive}
				modalRender={modalContent}
			/>
		</div>
	);
};

export default AddFriendHeader;
