import React, {FC} from 'react';

import styles from './style.module.scss';

type PropsType = {
	dataName?: string;
	isBackground?: boolean;
	isActive?: boolean;
	onClick?: () => void;
};

const FeedButton: FC<PropsType> = ({
	dataName = '',
	isBackground = false,
	isActive = false,
	onClick = () => false,
	children,
}) => (
	<button
		data-name={dataName}
		type="button"
		className={`${styles.button} ${isBackground && styles.withBackground} ${
			isActive && styles.active
		}`}
		onClick={onClick}>
		{children}
	</button>
);

export default FeedButton;
