import React, {FC, useRef} from 'react';
import Slider from 'react-slick';
import {ReactSVG} from 'react-svg';

import {nextArrow} from 'assets';
import {ZODIACS} from 'const';

import AppText from '../appText';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './style.module.scss';

type PropsType = {
	activeElement: number;
	setActiveElement: (key: number) => void;
};

const CompatibilitySlider: FC<PropsType> = ({activeElement, setActiveElement}) => {
	const sliderRef = useRef<any>(null);
	const zodiacSignData = ZODIACS?.find(item => item.key === activeElement);

	const sliderElements = ZODIACS.map(item => {
		return (
			<div
				key={item.key}
				className={activeElement === item.key ? styles.active : styles.sliderElement}>
				<ReactSVG
					wrapper="div"
					src={item?.iconsSmallActive}
					className={activeElement !== item.key ? styles.hidden : ''}
				/>
				<ReactSVG
					wrapper="div"
					src={item?.iconsSmall}
					className={activeElement === item.key ? styles.hidden : ''}
				/>
			</div>
		);
	});

	const prevClickHandler = () => {
		if (sliderRef !== null) {
			return activeElement !== 1
				? sliderRef?.current?.slickPrev()
				: sliderRef?.current?.slickGoTo(11, true);
		}
		return false;
	};

	const nextClickHandler = () => {
		if (sliderRef !== null) {
			return activeElement !== 12
				? sliderRef?.current?.slickNext()
				: sliderRef?.current?.slickGoTo(0, true);
		}

		return false;
	};

	const activeElementHandler = (indexPrev: number, indexNext: number) => {
		setActiveElement(indexNext + 1);
	};

	const settings = {
		centerMode: true,
		infinite: true,
		centerPadding: '20px',
		focusOnSelect: true,
		swipeToSlide: true,
		slidesToShow: 3,
		speed: 500,
		arrows: false,
		beforeChange: activeElementHandler,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					centerPadding: '50px',
				},
			},
			{
				breakpoint: 860,
				settings: {
					centerPadding: '10px',
				},
			},
		],
	};

	return (
		<div className={styles.sliderWrapper}>
			<div className={styles.controls}>
				<button type="button" className={styles.prevArrow} onClick={prevClickHandler}>
					<ReactSVG wrapper="div" src={nextArrow} />
				</button>
				<div className={styles.description}>
					<AppText className={styles.dates}>{zodiacSignData!.dates}</AppText>
					<AppText className={styles.zodiacName}>{zodiacSignData!.value}</AppText>
				</div>
				<button type="button" className={styles.nextArrow} onClick={nextClickHandler}>
					<ReactSVG wrapper="div" src={nextArrow} />
				</button>
			</div>
			<Slider ref={sliderRef} className={styles.slider} {...settings}>
				{sliderElements}
			</Slider>
		</div>
	);
};

export default CompatibilitySlider;
