import {City, UserDataType} from 'types';

export enum UserActions {
	SET_USER_DATA = 'user/SET_USER_DATA',
	SET_USER_ACCORDION_DATA = 'user/SET_USER_ACCORDION_DATA',
	SET_USER_NAME = 'user/SET_USER_NAME',
	SET_USER_CITY = 'user/SET_USER_CITY',
}

export type SetUserData = {type: UserActions.SET_USER_DATA; payload: UserDataType};
export type SetUserName = {type: UserActions.SET_USER_NAME; payload: string};
export type SetUserCity = {type: UserActions.SET_USER_CITY; payload: City};
export type SetUserAccordionData = {type: UserActions.SET_USER_ACCORDION_DATA; payload: City};

export const setUserData = (payload: UserDataType): SetUserData => ({
	type: UserActions.SET_USER_DATA,
	payload,
});

export const setUserName = (payload: string): SetUserName => ({
	type: UserActions.SET_USER_NAME,
	payload,
});

export const setUserCity = (payload: City): SetUserCity => ({
	type: UserActions.SET_USER_CITY,
	payload,
});

export type UserActionTypes = SetUserData | SetUserCity | SetUserName;
