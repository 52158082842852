import {ThunkAction} from 'redux-thunk';

import {GeneralAPI} from 'services';
import {GeneralInitialState, StateType} from 'types';
import {Toast} from 'utility';

import {
	GeneralActions,
	GeneralActionTypes,
	setCategoryEvents,
	setEnums,
	setInit,
} from '../actions/general';

import {getUserData} from './user';

const initialState: GeneralInitialState = {
	isInit: false,
	enums: null,
	categoryEvents: null,
};

type Thunk = ThunkAction<Promise<void>, StateType, unknown, GeneralActionTypes>; // что возвращает санка, тип стора, всегда unknown, тип всех экшенов этого редьюсера

export const fetchEnums = (): Thunk => async dispatch => {
	try {
		const response = await GeneralAPI.getEnums();
		dispatch(setEnums(response));
	} catch (error) {
		Toast.error(JSON.stringify(error));
	}
};

export const initializeApp = (): Thunk => async dispatch => {
	try {
		dispatch(setInit());
		await dispatch(getUserData());
	} catch (error: any) {
		Toast.error(JSON.stringify(error));
	}
};

export const fetchCategoryEvents = (): Thunk => async dispatch => {
	try {
		const response = await GeneralAPI.getCategoryEvents();
		dispatch(setCategoryEvents(response));
	} catch (error) {
		Toast.error(JSON.stringify(error));
	}
};

const generalReducer = (
	state: GeneralInitialState,
	action: GeneralActionTypes,
): GeneralInitialState => {
	const generalState = state || initialState;

	switch (action.type) {
		case GeneralActions.SET_ENUMS:
			return {
				...generalState,
				enums: action.payload,
			};
		case GeneralActions.SET_INIT:
			return {
				...generalState,
				isInit: true,
			};
		case GeneralActions.SET_CATEGORY_EVENTS:
			return {
				...generalState,
				categoryEvents: action.payload,
			};
		default:
			return generalState;
	}
};

export default generalReducer;
