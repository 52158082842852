import * as AstrologyAPI from './api/astrology';
import * as AuthAPI from './api/auth';
import CalendarsAPI from './api/calendars';
import * as CompatibilitiesAPI from './api/compatibilities';
import * as FriendsAPI from './api/friends';
import * as GeneralAPI from './api/general';
import * as HoroscopeAPI from './api/horoscopes';
import * as NewsFeedAPI from './api/newsFeed';
import * as UserAPI from './api/user';
import * as ZodiacAPI from './api/zodiac';
import {FeedbackTriggerService} from './FeedbackTriggerService';
import LogEvent from './log';
import PaymentService from './payment';
import SubscriptionsService from './subscriptions';

const feedbackTriggerService = new FeedbackTriggerService();

export {
	AstrologyAPI,
	AuthAPI,
	CalendarsAPI,
	CompatibilitiesAPI,
	feedbackTriggerService,
	FriendsAPI,
	GeneralAPI,
	HoroscopeAPI,
	LogEvent,
	NewsFeedAPI,
	PaymentService,
	SubscriptionsService,
	UserAPI,
	ZodiacAPI,
};
