import React, {FC} from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import {backArrowWhite, nextArrow} from 'assets';

import styles from './style.module.scss';

type PropsType = {
	navigateTo?: string;
	className?: string;
	whiteIcon?: boolean;
};

const GoBack: FC<PropsType> = ({
	navigateTo = '',
	whiteIcon = false,
	className = '',
	children = 'Back',
}) => {
	const navigate = useNavigate();
	const onClick = () => {
		if (navigateTo) {
			navigate(navigateTo);
		} else navigate(-1);
	};

	return (
		<button type="button" className={`${styles.button} ${className}`} onClick={onClick}>
			<ReactSVG wrapper="span" src={whiteIcon ? backArrowWhite : nextArrow} />
			{children}
		</button>
	);
};

export default GoBack;
