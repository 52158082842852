import React, {FC} from 'react';
import ImageGallery from 'react-image-gallery';

import './sliderStyle.scss';
import styles from './style.module.scss';

type PropsType = {
	products: string[];
};

const ShopSlider: FC<PropsType> = ({products}) => {
	const sliderElements = products.map(item => ({
		original: item,
		thumbnail: item,
	}));

	const renderSlide = data => {
		return (
			<div className={styles.content}>
				<div>
					<img src={data.original} alt="product" />
				</div>
			</div>
		);
	};

	return (
		<div className={styles.slider}>
			<ImageGallery
				items={sliderElements}
				showFullscreenButton={false}
				showPlayButton={false}
				renderItem={renderSlide}
			/>
		</div>
	);
};

export default ShopSlider;
