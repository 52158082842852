import React, {FC, useEffect, useState} from 'react';
import GooglePlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
} from 'react-google-places-autocomplete';

import {CityType} from 'types';

import AppText from '../../appText';

import './style.scss';
import styles from '../style.module.scss';

type PropsType = {
	label?: string;
	isLabel?: boolean;
	setCity: (city: CityType) => void;
};

const SearchCityInput: FC<PropsType> = ({label = '', isLabel, setCity}) => {
	const [value, setValue] = useState<any>(null);

	useEffect(() => {
		if (value && value?.label) {
			geocodeByAddress(value.label)
				.then(results => getLatLng(results[0]))
				.then(({lat, lng}) =>
					setCity({
						name: value.value.structured_formatting.main_text,
						latitude: lat,
						longitude: lng,
					}),
				);
		}
	}, [value]);

	return (
		<>
			{isLabel && <AppText className={styles.label}>{label}</AppText>}
			<div className={`${styles.formController} ${styles.cityController}`}>
				<GooglePlacesAutocomplete
					selectProps={{
						onChange: setValue,
						placeholder: 'Enter city of birth',
						noOptionsMessage: () => 'Start typing',
					}}
					apiOptions={{
						language: 'en',
					}}
					debounce={500}
					apiKey={process.env.REACT_APP_GOOGLE_PLACE_AUTOCOMPLETE}
				/>
			</div>
		</>
	);
};

export default SearchCityInput;
