import {ThunkAction} from 'redux-thunk';

import moment from 'moment';
import {ZodiacAPI} from 'services';
import {StateType, ZodiacInitialState} from 'types';
import {biorhythmsUtils, Toast} from 'utility';

import {
	setAccordionData,
	setBiorhythms,
	setFocusOfTheDay,
	setLifePathNumbers,
	setQuote,
	setTodayLuckyNumber,
	setZodiacMission,
	setZodiacStrengths,
	setZodiacSymbolismAndMyth,
	setZodiacWeaknesses,
	ZodiacActions,
	ZodiacActionTypes,
} from '../actions/zodiac';

const initialState: ZodiacInitialState = {
	accordionData: null,
	strengths: null,
	weaknesses: null,
	symbolismAndMuth: null,
	mission: null,
	focusOfTheDay: null,
	lifePathNumbers: null,
	quote: null,
	todayLuckyNumber: null,
	biorythms: null,
};

type Thunk = ThunkAction<Promise<void>, StateType, unknown, ZodiacActionTypes>;

export const fetchAccordionData =
	(signId: number): Thunk =>
	async dispatch => {
		try {
			const response = await ZodiacAPI.getAccordionData(signId);
			dispatch(setAccordionData(response));
		} catch (error) {
			Toast.error(JSON.stringify(error));
		}
	};

export const fetchZodiacStrengths =
	(signId: number): Thunk =>
	async dispatch => {
		try {
			const response = await ZodiacAPI.getZodiacStrengths(signId);
			dispatch(setZodiacStrengths(response));
		} catch (error) {
			Toast.error(JSON.stringify(error));
		}
	};

export const fetchZodiacWeaknesses =
	(signId: number): Thunk =>
	async dispatch => {
		try {
			const response = await ZodiacAPI.getZodiacWeaknesses(signId);
			dispatch(setZodiacWeaknesses(response));
		} catch (error) {
			Toast.error(JSON.stringify(error));
		}
	};

export const fetchZodiacSymbolismAndMyth =
	(signId: number): Thunk =>
	async dispatch => {
		try {
			const response = await ZodiacAPI.getZodiacSymbolismAndMyth(signId);
			dispatch(setZodiacSymbolismAndMyth(response));
		} catch (error) {
			Toast.error(JSON.stringify(error));
		}
	};

export const fetchZodiacMission =
	(signId: number): Thunk =>
	async dispatch => {
		try {
			const response = await ZodiacAPI.getZodiacMission(signId);
			dispatch(setZodiacMission(response));
		} catch (error) {
			Toast.error(JSON.stringify(error));
		}
	};

export const fetchZodiacFocusOfTheDay = (): Thunk => async dispatch => {
	try {
		const dateToSting = moment().format('YYYY-MM-DD');
		const response = await ZodiacAPI.getZodiacFocusOfTheDay(dateToSting);
		dispatch(setFocusOfTheDay(response));
	} catch (error) {
		Toast.error(JSON.stringify(error));
	}
};

export const fetchLifePathNumbers = (): Thunk => async dispatch => {
	try {
		const response = await ZodiacAPI.getLifePathNumbers();
		dispatch(setLifePathNumbers(response));
	} catch (error) {
		Toast.error(JSON.stringify(error));
	}
};

export const fetchZodiacQuote = (): Thunk => async dispatch => {
	try {
		const response = await ZodiacAPI.getZodiacQuote();
		dispatch(setQuote(response));
	} catch (error: any) {
		if (error.response.status !== 401) {
			Toast.error(JSON.stringify(error));
		}
	}
};

export const fetchTodayLuckyNumber = (): Thunk => async dispatch => {
	try {
		const response = await ZodiacAPI.getTodayLuckyNumber();
		dispatch(setTodayLuckyNumber(response));
	} catch (error) {
		Toast.error(JSON.stringify(error));
	}
};

export const fetchBiorhythms = (): Thunk => async dispatch => {
	try {
		const response = await ZodiacAPI.getBiorhythms();
		dispatch(setBiorhythms(biorhythmsUtils.getFormattedBiorhythms(response)));
	} catch (error) {
		Toast.error(JSON.stringify(error));
	}
};

const zodiacReducer = (
	state: ZodiacInitialState,
	action: ZodiacActionTypes,
): ZodiacInitialState => {
	const zodiacState = state || initialState;

	switch (action.type) {
		case ZodiacActions.SET_ACCORDION_DATA:
			return {
				...zodiacState,
				accordionData: action.payload,
			};
		case ZodiacActions.SET_ZODIAC_STRENGTHS:
			return {
				...zodiacState,
				strengths: action.payload,
			};
		case ZodiacActions.SET_ZODIAC_WEAKNESSES:
			return {
				...zodiacState,
				weaknesses: action.payload,
			};
		case ZodiacActions.SET_ZODIAC_SYMBOLISM_AND_MYTH:
			return {
				...zodiacState,
				symbolismAndMuth: action.payload,
			};
		case ZodiacActions.SET_ZODIAC_MISSION:
			return {
				...zodiacState,
				mission: action.payload,
			};
		case ZodiacActions.SET_FOCUS_OF_THE_DAY:
			return {
				...zodiacState,
				focusOfTheDay: action.payload,
			};
		case ZodiacActions.SET_LIFE_PATH_NUMBERS:
			return {
				...zodiacState,
				lifePathNumbers: action.payload,
			};
		case ZodiacActions.SET_QUOTE:
			return {
				...zodiacState,
				quote: action.payload,
			};
		case ZodiacActions.SET_TODAY_LUCKY_NUMBER:
			return {
				...zodiacState,
				todayLuckyNumber: action.payload,
			};
		case ZodiacActions.SET_BIORHYTHMS:
			return {
				...zodiacState,
				biorythms: action.payload,
			};
		default:
			return zodiacState;
	}
};

export default zodiacReducer;
