import {
	bear,
	crow,
	deer,
	dolphin,
	eagle,
	elephant,
	fish,
	fox,
	hawk,
	horse,
	humingbird,
	lion,
	monkey,
	moose,
	owl,
	panda,
	penguin,
	pigeon,
	snake,
	turtle,
	wolf,
} from 'assets';

const ANIMALS = [
	{
		key: 1,
		value: 'Bear',
		icon: bear,
	},
	{
		key: 2,
		value: 'Crow',
		icon: crow,
	},
	{
		key: 3,
		value: 'Deer',
		icon: deer,
	},
	{
		key: 4,
		value: 'Dolphin',
		icon: dolphin,
	},
	{
		key: 5,
		value: 'Eagle',
		icon: eagle,
	},
	{
		key: 6,
		value: 'Elephant',
		icon: elephant,
	},
	{
		key: 7,
		value: 'Fish',
		icon: fish,
	},
	{
		key: 8,
		value: 'Fox',
		icon: fox,
	},
	{
		key: 9,
		value: 'Hawk',
		icon: hawk,
	},
	{
		key: 10,
		value: 'Horse',
		icon: horse,
	},
	{
		key: 11,
		value: 'Humingbird',
		icon: humingbird,
	},
	{
		key: 12,
		value: 'Lion',
		icon: lion,
	},
	{
		key: 13,
		value: 'Monkey',
		icon: monkey,
	},
	{
		key: 14,
		value: 'Moose',
		icon: moose,
	},
	{
		key: 15,
		value: 'Owl',
		icon: owl,
	},
	{
		key: 16,
		value: 'Panda',
		icon: panda,
	},
	{
		key: 17,
		value: 'Penguin',
		icon: penguin,
	},
	{
		key: 18,
		value: 'Pigeon',
		icon: pigeon,
	},
	{
		key: 19,
		value: 'Snake',
		icon: snake,
	},
	{
		key: 20,
		value: 'Turtle',
		icon: turtle,
	},
	{
		key: 21,
		value: 'Wolf',
		icon: wolf,
	},
];

export default ANIMALS;
