import {toast} from 'react-toastify';
import {ThunkAction} from 'redux-thunk';

import {AuthAPI, UserAPI} from 'services';
import {City, StateType, UserInitialState} from 'types';
import {Toast, toastsCommonOptions, toastsOptions} from 'utility';

import {setUserCity, setUserData, setUserName, UserActions, UserActionTypes} from '../actions/user';

import {fetchHoroscope} from './horoscopes';

const initialState: UserInitialState = {
	token: null,
	email: null,
	password: null,
	firstName: 'User',
	birthDateTimeUtc: null,
	zodiacSign: null,
	gender: null,
	relationship: null,
	interests: [],
	motivations: [],
	feelOtherZodiacSign: null,
	birthCity: {
		name: null,
		latitude: null,
		longitude: null,
	},
	isAgreeEmailSubscription: false,
};

type Thunk = ThunkAction<Promise<void>, StateType, unknown, UserActionTypes>;

export const getUserData = (): Thunk => async dispatch => {
	AuthAPI.authUser()
		.then(response => {
			dispatch(setUserData(response));
		})
		.catch(error => {
			if (error.response.status !== 401) {
				Toast.error(JSON.stringify(error));
			}
		});
};

export const updateUserName =
	(name: string): Thunk =>
	async dispatch => {
		try {
			await UserAPI.updateName(name);
			dispatch(setUserName(name));
			toast.success('Name changed', {...toastsCommonOptions, ...toastsOptions.success});
		} catch (error: any) {
			toast.error(`${error.message}`, {...toastsCommonOptions, ...toastsOptions.error});
		}
	};

export const updateUserBirthDate =
	(date: Date): Thunk =>
	async dispatch => {
		try {
			await UserAPI.updateBirthDate(date, -date.getTimezoneOffset());
			const response = await AuthAPI.authUser();
			dispatch(setUserData(response));
			toast.success('Birth date changed', {
				...toastsCommonOptions,
				...toastsOptions.success,
			});
			if (response.zodiacSign) await dispatch(fetchHoroscope(response.zodiacSign, true));
		} catch (error: any) {
			toast.error(`${error.message}`, {...toastsCommonOptions, ...toastsOptions.error});
		}
	};

export const updateUserCity =
	(city: City): Thunk =>
	async dispatch => {
		try {
			await UserAPI.updateCity(city);
			dispatch(setUserCity(city));
			toast.success('City changed', {...toastsCommonOptions, ...toastsOptions.success});
		} catch (error: any) {
			toast.error(`${error.message}`, {...toastsCommonOptions, ...toastsOptions.error});
		}
	};

const userReducer = (state: UserInitialState, action: UserActionTypes): UserInitialState => {
	const userState = state || initialState;

	switch (action.type) {
		case UserActions.SET_USER_DATA:
			return {
				...action.payload,
			};
		case UserActions.SET_USER_NAME:
			return {
				...userState,
				firstName: action.payload,
			};
		case UserActions.SET_USER_CITY:
			return {
				...userState,
				birthCity: action.payload,
			};
		default:
			return userState;
	}
};

export default userReducer;
