import React, {FC} from 'react';
import {ReactSVG} from 'react-svg';

import {ZODIACS} from 'const';

import AppText from '../appText';

import styles from './style.module.scss';

type PropsType = {
	firstZodiacSign: number;
	secondZodiacSign: number;
	className?: string;
};

const CompatibilityZodiacSignsBlock: FC<PropsType> = ({
	firstZodiacSign,
	secondZodiacSign,
	className = '',
}) => {
	const yourZodiacSign = ZODIACS?.find(item => item.key === firstZodiacSign);

	const partnerZodiacSign = ZODIACS?.find(item => item.key === secondZodiacSign);

	return (
		<div className={`${styles.signsWrapper} ${className}`}>
			<div className={styles.signWrapper}>
				{yourZodiacSign && (
					<>
						<ReactSVG
							wrapper="span"
							src={yourZodiacSign.iconsSlider}
							className={styles.zodiacImage}
						/>
						<AppText className={styles.signName}>{yourZodiacSign.value}</AppText>
					</>
				)}
			</div>
			<AppText className={styles.andSign}>&</AppText>
			<div className={styles.signWrapper}>
				{partnerZodiacSign && (
					<>
						<ReactSVG
							wrapper="span"
							src={partnerZodiacSign.iconsSlider}
							className={styles.zodiacImage}
						/>
						<AppText className={styles.signName}>{partnerZodiacSign.value}</AppText>
					</>
				)}
			</div>
		</div>
	);
};

export default CompatibilityZodiacSignsBlock;
