import React, {useEffect} from 'react';

const bannerElementStyle = {display: 'block'};

const BottomAdBanner: React.FC = () => {
	useEffect(() => {
		try {
			if (typeof window === 'object') {
				((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
			}
		} catch {
			// Pass
		}
	}, []);

	return (
		<div>
			<ins
				className="adsbygoogle"
				style={bannerElementStyle}
				data-ad-client="ca-pub-8656773845876413"
				data-ad-slot="1573479735"
				data-ad-format="auto"
				data-full-width-responsive="true"
			/>
		</div>
	);
};

export default BottomAdBanner;
