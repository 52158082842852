import React, {FC} from 'react';
import {ReactSVG} from 'react-svg';

import {logo} from 'assets';

import styles from './style.module.scss';

type PropsType = {
	className?: string;
};

const Logo: FC<PropsType> = ({className = ''}) => (
	<ReactSVG wrapper="span" src={logo} className={`${styles.logo} ${className}`} />
);

export default Logo;
