const getIlluminationValue = (defaultValue: number) =>
	(Math.round(defaultValue * 100) / 100).toFixed(2);

const getDeclinationValue = (defaultValue: number) => {
	const declination = Math.trunc(defaultValue);

	return `${declination}°${Math.trunc(Math.abs(defaultValue - declination) * 100)}`;
};

export {getDeclinationValue, getIlluminationValue};
