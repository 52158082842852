import React, {FC, useEffect, useState} from 'react';
import {ReactSVG} from 'react-svg';

import {info} from 'assets';
import {TooltipThemes} from 'types';

import styles from './style.module.scss';

type Props = {
	theme?: TooltipThemes;
	className?: string;
};

const Tooltip: FC<Props> = ({theme = TooltipThemes.default, className = '', children}) => {
	const [showInfo, setShowInfo] = useState(false);

	const bodyClickListener = (ev: any) => {
		const {target} = ev;

		if (!target?.closest('[data-name="tooltip"]')) {
			setShowInfo(false);
		}
	};

	useEffect(() => {
		const {body} = document;
		body.addEventListener('click', bodyClickListener);

		return () => body.removeEventListener('click', bodyClickListener);
	}, [bodyClickListener]);

	const toggleShowInfo: () => void = () => {
		setShowInfo(!showInfo);
	};

	return (
		<div data-name="tooltip" className={styles.container}>
			<button type="button" onClick={toggleShowInfo} className={styles.button}>
				<ReactSVG className={`${styles.icon} ${styles[theme]}`} src={info} />
			</button>
			<div
				className={`${styles.tooltip} ${className} ${
					showInfo ? styles.show : styles.hide
				}`}>
				{children}
			</div>
		</div>
	);
};

export default Tooltip;
