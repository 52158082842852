/* eslint-disable class-methods-use-this */
/* eslint-disable import/prefer-default-export */

import {MILLISECONDS, STORAGE_KEYS} from 'const';
import {Storage} from 'utility';

const TRIGGER_MAP = new Map([
	[STORAGE_KEYS.LIKE_COUNT, 3],
	[STORAGE_KEYS.COMPATIBILITY_COUNT, 3],
	[STORAGE_KEYS.SESSION_COUNT, 5],
]);

const FEEDBACK_CHECK_PERIOD = MILLISECONDS.day * 30;

export class FeedbackTriggerService {
	private observers: Array<() => void> = [];

	public constructor() {
		const checkEnd = Storage.getData(STORAGE_KEYS.FEEDBACK_CHECK_END);

		if (!checkEnd) {
			const today = new Date().getTime();
			Storage.saveData(STORAGE_KEYS.FEEDBACK_CHECK_END, today + FEEDBACK_CHECK_PERIOD);
		}
	}

	/**
	 * Subscribe to receive broadcast when any condition is successful
	 */
	public subscribe(fn: () => void) {
		this.observers.push(fn);

		return () => {
			this.observers = this.observers.filter(subscriber => subscriber !== fn);
		};
	}

	/**
	 * 1) Check if count tracking is not postponed or finished
	 * 2) Increment count of [key] in local storage if key exists
	 * 3) Broadcast to all subscribers if any condition is successful
	 */
	public trigger(key?: string) {
		const feedbackSent = Storage.getData(STORAGE_KEYS.FEEDBACK_SENT);

		if (feedbackSent) {
			return;
		}

		const today = new Date().getTime();
		const skipEnd = Storage.getData(STORAGE_KEYS.FEEDBACK_SKIP_END);
		const checkEnd = Storage.getData(STORAGE_KEYS.FEEDBACK_CHECK_END);

		if (skipEnd && today <= skipEnd) {
			return;
		}

		if (today < checkEnd) {
			if (key) {
				Storage.saveData(key, Storage.getData(key) + 1);
			}

			const shouldBroadcast = Array.from(TRIGGER_MAP.entries()).some(([trigger, limit]) => {
				const value = Storage.getData(trigger);

				return value >= limit;
			});

			if (shouldBroadcast) {
				this.broadcast();
			}
		} else {
			Storage.saveData(STORAGE_KEYS.FEEDBACK_CHECK_END, today + FEEDBACK_CHECK_PERIOD);
			TRIGGER_MAP.forEach((_, trigger) => {
				Storage.saveData(trigger, trigger === key ? 1 : 0);
			});
		}
	}

	/**
	 * Postpone count tracking
	 */
	public skip() {
		const today = new Date().getTime();
		Storage.saveData(STORAGE_KEYS.FEEDBACK_SKIP_END, today + FEEDBACK_CHECK_PERIOD);
	}

	/**
	 * Finish count tracking
	 */
	public finish() {
		Storage.saveData(STORAGE_KEYS.FEEDBACK_SENT, true);
	}

	/**
	 * Broadcast to all subscribers
	 */
	private broadcast() {
		this.observers.forEach(subscriber => subscriber());
	}
}
