import moment from 'moment';
import {Biorhythms, BiorhythmsCategory, BiorhythmsChartData, BiorhythmsResponse} from 'types';

const getBiorhytmsData = (data: Array<{date: Date; value: number}>, day: string) =>
	data.find((item: {date: Date}) => {
		return moment(item.date).format('YYYY-MM-DD') === day;
	});

export const getCurrentBiorhythm = (data: Biorhythms) => {
	const currentData = {};
	const biorhythmCategoriesKeys = Object.keys(data.categories);

	biorhythmCategoriesKeys.forEach(key => {
		currentData[key] = {...data.categories[key]};

		const currentItem = getBiorhytmsData(
			currentData[key].values,
			moment().format('YYYY-MM-DD'),
		);

		if (currentItem) currentData[key].values = [currentItem];
	});

	return currentData;
};

export const getChartData = (data: Biorhythms) => {
	const chartData: Array<BiorhythmsChartData> = [];
	const biorhythmCategoriesKeys = Object.keys(data.categories);

	biorhythmCategoriesKeys.forEach(key => {
		data.categories[key].values.forEach((item, index) => {
			if (chartData.length < 7) {
				const chartItem: BiorhythmsChartData = {
					name: moment(item.date).format('ddd').toUpperCase(),
					[BiorhythmsCategory.emotional]: [0, -100],
					[BiorhythmsCategory.intellectual]: [0, -100],
					[BiorhythmsCategory.physical]: [0, -100],
					emotionalLine: 0,
					physicalLine: 0,
					intellectualLine: 0,
					default: index % 2 ? 100 : -100,
				};

				chartItem[key][0] = item.value;
				chartItem[`${key}Line`] = item.value;

				chartData.push(chartItem);
			} else {
				chartData[index][key][0] = item.value;
				chartData[index][`${key}Line`] = item.value;
			}
		});
	});

	return chartData;
};

export const getFormattedBiorhythms = (data: BiorhythmsResponse): Biorhythms => {
	return {
		categories: {
			[BiorhythmsCategory.physical]: data[BiorhythmsCategory.physical],
			[BiorhythmsCategory.emotional]: data[BiorhythmsCategory.emotional],
			[BiorhythmsCategory.intellectual]: data[BiorhythmsCategory.intellectual],
		},
		about: data.about,
	};
};
