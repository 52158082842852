import React, {useEffect} from 'react';

const backgroundChanger =
	(className: string = '') =>
	Component =>
		function bgChanger(props) {
			useEffect(() => {
				if (className) {
					document.body.classList.add(className);

					return () => {
						document.body.classList.remove(className);
					};
				}

				return () => {};
			});

			return <Component {...props} />;
		};

export default backgroundChanger;
