import React, {FC} from 'react';

import styles from './style.module.scss';

type PropsType = {
	onClick?: () => void;
	className?: string;
};

const TextButton: FC<PropsType> = ({onClick, children, className = ''}) => {
	return (
		<button type="button" className={`${styles.button} ${className}`} onClick={onClick}>
			{children}
		</button>
	);
};

export default TextButton;
