import {CurrentMoonPhase, MoonPhase} from './astrology';
import {
	Biorhythms,
	LifePathNumbersTypes,
	SignFocusOfTheDay,
	SignMission,
	SignQuote,
	SignStrengthsAndWeknesses,
	SignSymbolismAndMyth,
	TodayLuckyNumberTypes,
	ZodiacAccordionDataType,
	ZodiacSignInfo,
} from './zodiac';

export type City = {
	name: string | null;
	latitude: number | null;
	longitude: number | null;
};

export enum Gender {
	man = 1,
	woman = 2,
}

export interface UserDataType {
	token: string | null;
	email: string | null;
	password: string | null;
	firstName: string;
	birthDateTimeUtc: Date | null;
	zodiacSign: number | null;
	gender: Gender | null;
	relationship: number | null;
	interests: Array<number>;
	motivations: Array<number>;
	feelOtherZodiacSign: number | null;
	isAgreeEmailSubscription: boolean;
	birthCity: City;
}

export type FriendData = {
	firstName: string;
	birthDateTimeUtc: Date;
	birthDateTimeMinutesOffset: number;
	gender: Gender;
	birthCity: City;
};

export type FriendDataResponseType = FriendData & {
	token: string;
	zodiacSign: number;
};

export type FriendsDataType = {
	firstName: string | null;
	birthDateTimeUtc: Date | null;
	birthDateTimeMinutesOffset: number | null;
	gender: Gender | null;
	birthCity: City;
	friends: Array<FriendDataResponseType>;
	selectedProfileToken: string | null;
	selectedFriendAccordionData: ZodiacAccordionDataType | null;
	selectedFriendStrengths: SignStrengthsAndWeknesses | null;
	selectedFriendWeaknesses: SignStrengthsAndWeknesses | null;
	selectedFriendSymbolismAndMyth: SignSymbolismAndMyth | null;
	selectedFriendMission: SignMission | null;
	selectedFriendZodiacSignInfo: ZodiacSignInfo | null;
	selectedFriendFocusOfTheDay: SignFocusOfTheDay | null;
	selectedFriendLifePathNumbers: LifePathNumbersTypes | null;
	selectedFriendQuote: SignQuote | null;
	selectedFriendTodayLuckyNumber: TodayLuckyNumberTypes | null;
	selectedFriendBiorhythms: Biorhythms | null;
	selectedFriendMoonPhases: MoonPhase[] | null;
	selectedFriendCurrentMoonPhase: CurrentMoonPhase | null;
};
