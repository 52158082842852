import {
	Biorhythms,
	LifePathNumbersTypes,
	SignFocusOfTheDay,
	SignMission,
	SignQuote,
	SignStrengthsAndWeknesses,
	SignSymbolismAndMyth,
	TodayLuckyNumberTypes,
	ZodiacAccordionDataType,
} from 'types';

export enum ZodiacActions {
	SET_ACCORDION_DATA = 'zodiac/setAccordionData',
	SET_ZODIAC_STRENGTHS = 'zodiac/setZodiacStrengths',
	SET_ZODIAC_WEAKNESSES = 'zodiac/setZodiacWeaknesses',
	SET_ZODIAC_SYMBOLISM_AND_MYTH = 'zodiac/setZodiacSymbolismAndMyth',
	SET_ZODIAC_MISSION = 'zodiac/setZodiacMission',
	SET_FOCUS_OF_THE_DAY = 'zodiac/setFocusOfTheDay',
	SET_LIFE_PATH_NUMBERS = 'zodiac/setLifePathNumbers',
	SET_QUOTE = 'zodiac/setQuote',
	SET_TODAY_LUCKY_NUMBER = 'zodiac/setTodayLuckyNumber',
	SET_BIORHYTHMS = 'zodiac/setBiorhythms',
}

type SetAccordionData = {
	type: ZodiacActions.SET_ACCORDION_DATA;
	payload: ZodiacAccordionDataType;
};
type SetZodiacStrengths = {
	type: ZodiacActions.SET_ZODIAC_STRENGTHS;
	payload: SignStrengthsAndWeknesses;
};
type SetZodiacWeaknesses = {
	type: ZodiacActions.SET_ZODIAC_WEAKNESSES;
	payload: SignStrengthsAndWeknesses;
};
type SetZodiacSymbolismAndMyth = {
	type: ZodiacActions.SET_ZODIAC_SYMBOLISM_AND_MYTH;
	payload: SignSymbolismAndMyth;
};
type SetZodiacMission = {type: ZodiacActions.SET_ZODIAC_MISSION; payload: SignMission};
type SetFocusOfTheDay = {type: ZodiacActions.SET_FOCUS_OF_THE_DAY; payload: SignFocusOfTheDay};
type SetQuote = {type: ZodiacActions.SET_QUOTE; payload: SignQuote};

type SetLifePathNumbers = {
	type: ZodiacActions.SET_LIFE_PATH_NUMBERS;
	payload: LifePathNumbersTypes;
};
type SetTodayLuckyNumber = {
	type: ZodiacActions.SET_TODAY_LUCKY_NUMBER;
	payload: TodayLuckyNumberTypes;
};
type SetBiorhythms = {
	type: ZodiacActions.SET_BIORHYTHMS;
	payload: Biorhythms;
};

export const setAccordionData = (payload: ZodiacAccordionDataType): SetAccordionData => {
	return {
		type: ZodiacActions.SET_ACCORDION_DATA,
		payload,
	};
};

export const setZodiacStrengths = (payload: SignStrengthsAndWeknesses): SetZodiacStrengths => {
	return {
		type: ZodiacActions.SET_ZODIAC_STRENGTHS,
		payload,
	};
};

export const setZodiacWeaknesses = (payload: SignStrengthsAndWeknesses): SetZodiacWeaknesses => {
	return {
		type: ZodiacActions.SET_ZODIAC_WEAKNESSES,
		payload,
	};
};

export const setZodiacSymbolismAndMyth = (
	payload: SignSymbolismAndMyth,
): SetZodiacSymbolismAndMyth => {
	return {
		type: ZodiacActions.SET_ZODIAC_SYMBOLISM_AND_MYTH,
		payload,
	};
};

export const setZodiacMission = (payload: SignMission): SetZodiacMission => {
	return {
		type: ZodiacActions.SET_ZODIAC_MISSION,
		payload,
	};
};

export const setFocusOfTheDay = (payload: SignFocusOfTheDay): SetFocusOfTheDay => {
	return {
		type: ZodiacActions.SET_FOCUS_OF_THE_DAY,
		payload,
	};
};

export const setLifePathNumbers = (payload: LifePathNumbersTypes): SetLifePathNumbers => {
	return {
		type: ZodiacActions.SET_LIFE_PATH_NUMBERS,
		payload,
	};
};

export const setQuote = (payload: SignQuote): SetQuote => {
	return {
		type: ZodiacActions.SET_QUOTE,
		payload,
	};
};

export const setTodayLuckyNumber = (payload: TodayLuckyNumberTypes): SetTodayLuckyNumber => {
	return {
		type: ZodiacActions.SET_TODAY_LUCKY_NUMBER,
		payload,
	};
};

export const setBiorhythms = (payload: Biorhythms): SetBiorhythms => {
	return {
		type: ZodiacActions.SET_BIORHYTHMS,
		payload,
	};
};

export type ZodiacActionTypes =
	| SetAccordionData
	| SetZodiacStrengths
	| SetZodiacWeaknesses
	| SetZodiacSymbolismAndMyth
	| SetZodiacMission
	| SetFocusOfTheDay
	| SetLifePathNumbers
	| SetQuote
	| SetTodayLuckyNumber
	| SetBiorhythms;
