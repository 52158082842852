import {useSelector} from 'react-redux';

import {StateType} from 'types';

export const GetEnums = () => useSelector((state: StateType) => state.general.enums);
export const GetProfessions = () =>
	useSelector((state: StateType) => state.general.enums?.professions);
export const GetActivities = () =>
	useSelector((state: StateType) => state.general.enums?.activities);
export const GetHerbals = () => useSelector((state: StateType) => state.general.enums?.herbals);
export const GetGems = () => useSelector((state: StateType) => state.general.enums?.gems);
export const GetIsInit = () => useSelector((state: StateType) => state.general.isInit);
export const GetCategoryEvents = () =>
	useSelector((state: StateType) => state.general.categoryEvents);
export const GetFocuses = () => useSelector((state: StateType) => state.general.enums?.focuses);
export const GetPlanets = () => useSelector((state: StateType) => state.general.enums?.planetSigns);
export const GetAspects = () => useSelector((state: StateType) => state.general.enums?.aspects);
