import {useEffect, useState} from 'react';

import {ZodiacSignInfoType} from 'types';
import {getConstantWithAdditionalInfo} from 'utility';

const useCompleter = (defaultData: any, additionalData: Array<any>) => {
	const [completedData, setCompletedData] = useState<Array<any> | Array<ZodiacSignInfoType>>([]);

	useEffect(() => {
		if (completedData.length === 0) {
			const updatedCompletedData = defaultData
				? getConstantWithAdditionalInfo(defaultData, additionalData)
				: null;
			if (updatedCompletedData) {
				setCompletedData(updatedCompletedData);
			}
		}
	}, [defaultData, completedData, additionalData]);

	return completedData;
};

export default useCompleter;
