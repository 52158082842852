import React, {FC} from 'react';

import {Navigation} from 'const';
import {backgroundChanger} from 'hoc';
import {LinksType} from 'types';

import AddFriendHeader from '../../addFriendHeader';
import Feedback from '../../Feedback';
import FooterMenu from '../../footerMenu';
import {BottomAdBanner} from '../../google-ads';
import Sidebar from '../../sidebar';

import styles from './style.module.scss';

type PropsType = {
	contentClassName?: string;
};

const sidebarButtons = Navigation.filter(item => item.type !== LinksType.product);
const sidebarShopButtons = Navigation.filter(item => item.type === LinksType.product);

const PrivateView: FC<PropsType> = ({children, contentClassName = ''}) => {
	return (
		<div className={styles.privateView}>
			<div className="container">
				<div className={styles.blockWrap}>
					<Feedback />
					<AddFriendHeader isAuth />
					<main>
						<Sidebar buttons={sidebarButtons} additionalButtons={sidebarShopButtons} />
						<div className={`${styles.profile} ${contentClassName}`}>{children}</div>
					</main>
				</div>
				<FooterMenu />
				<BottomAdBanner />
			</div>
		</div>
	);
};

export default backgroundChanger()(PrivateView);
