export const ROUTES = {
	PUBLIC: {
		CREATE_PASSWORD: '/create-password',
		LOGIN: '/login',
		WELCOME: '/',
		RESET_PASSWORD: '/reset-password',
		FAQ: 'https://aster.zohodesk.eu/portal/en/kb',
		CONTACT_US: 'support@appaster.com',
		SUBSCRIPTIONS_TERMS: 'https://appaster.com/subscription-terms',
		PRIVACY_POLICY: 'https://appaster.com/privacy-policy',
		TERMS_OF_SERVICE: 'https://appaster.com/terms-of-use',
		KEEN_COM_ASTROLOGERS: 'https://keen.pxf.io/x91Egd',
	},
	PRIVATE: {
		PROFILE: '/profile',
		HOROSCOPE: '/horoscope',
		SETTINGS: '/settings',
		COMPATIBILITY: '/compatibility',
		BIORHYTHMS: '/profile/biorhythms',
		FEED: '/feed',
		ENTER_NAME: '/enter-name',
		GENDER: '/gender',
		ADD_FRIEND_PREVIEW: '/add-friend-preview',
		BIRTH_DATE: '/birth-date',
		TIME_OF_BIRTH: '/time-of-birth',
		PLACE_OF_BIRTH: '/place-of-birth',
		ADD_FRIEND_ANALYZE: '/add-friend-analyze',
		SHOP: '/shop',
		PRODUCT: '/shop/product',
		PAYMENT: '/payment',
		PERSONAL_ASTROLOGY: '/personal-astrology',
		ASTROLOGERS: '/astrologers',
		SUBSCRIPTION_OVER: '/subscription-over',
		SUBSCRIPTION: '/subscription',
	},
};

export type CompatibilityHash = {
	analyze: string;
	result: string;
};

export const COMPATIBILITY_HASH: CompatibilityHash = {
	analyze: 'analyze',
	result: 'result',
};

export enum ResetPasswordStep {
	enterCode = 'enter-code',
	reset = 'reset',
}
