import {CompatibilitiesTypes} from 'types';

import {appInstanceWithInterceptors} from '../HTTPClient';

export const getCompatibility = (firstZodiac: number, secondZodiac: number, reverse: boolean) =>
	appInstanceWithInterceptors
		.get<CompatibilitiesTypes>(
			`Compatibilities?FirstZodiacSign=${firstZodiac}&SecondZodiacSign=${secondZodiac}&Reverse=${reverse}`,
		)
		.then(response => {
			return response.data;
		});

export default getCompatibility;
