import * as SubscriptionsAPI from '../api/subscriptions';

class SubscriptionsService {
	static async subscriptionCancellation(onSuccess: () => void, onError: () => void) {
		try {
			await SubscriptionsAPI.subscriptionCancel();
			onSuccess();
		} catch (error) {
			onError();
		}
	}
}

export default SubscriptionsService;
