export type Gems = {
	gem: number;
	description: string;
};

export interface ZodiacAccordionDataType {
	zodiacSign: number;
	color: string;
	colorDescription: string;
	hexadecimalColorCode: string;
	motivation: string;
	cardinalSin: string;
	cardinalSinDescription: string;
	professionsDescription: string;
	activitiesDescription: string;
	herbalsDescription: string;
	professions: Array<number>;
	activities: Array<number>;
	gems: Array<Gems>;
	herbals: Array<number>;
}

export interface ZodiacSignInfoType {
	value: string;
	key: number;
	dates: string;
	icon: typeof Image;
	iconStars: typeof Image;
	iconsSlider: typeof Image;
	iconsSmall: typeof Image;
	iconsSmallActive: typeof Image;
	accordionIcon: typeof Image;
}

export type ZodiacSignInfo = {
	token: string;
	zodiacSign: number;
	rulingPlanet: number;
	element: number;
	quality: number;
	polarity: number;
	moonConstellation: string;
	ascedant: number;
	descedant: number;
};

export type SignShortDescription = {
	key: number;
	value: string;
	image: string;
};

export type SignDescriptions = {
	key: number;
	title: string;
	text: string;
	image: string;
};

type SignToken = {
	token: string;
	zodiacSign: number;
};

export type SignStrengthsAndWeknesses = SignToken & {
	description: string;
	values: Array<string>;
};

export type SignMission = SignToken & {
	mission: string;
	missionStatement: string;
};

export type SignSymbolismAndMyth = SignToken & {
	myth: string;
};

type FocusStatus = {
	focusType: number;
	focusStatus: number;
	percent: number;
};

export type SignFocusOfTheDay = {
	focus: number;
	focusStatuses: Array<FocusStatus>;
};

export type SignQuote = {
	author: string;
	text: string;
};

export type LifePathNumbersTypes = {
	token: string;
	number: number;
	values: string;
	challenges: string;
	path: string;
	description: string;
};

export type TodayLuckyNumberTypes = {
	token: string;
	number: number;
	symbol: string;
	description: string;
};

export enum BiorhythmsCategory {
	physical = 'physical',
	emotional = 'emotional',
	intellectual = 'intellectual',
}

export type BiorhythmCategory = {
	color: string;
	values: Array<{
		data: Date;
		value: number;
	}>;
	description: string;
	status: number;
	difference: number;
};

type BiorhytmsAbout = {
	about: string;
};

export type Biorhythms = {
	categories: {
		[key in BiorhythmsCategory]: BiorhythmCategory;
	};
} & BiorhytmsAbout;

export type BiorhythmsResponse = {
	[key in BiorhythmsCategory]: BiorhythmCategory;
} & BiorhytmsAbout;

type BiorhythmAreaChartData = {
	[key in BiorhythmsCategory]: [number, number];
};

export type BiorhythmsChartData = {
	name: string;
	emotionalLine: number;
	intellectualLine: number;
	physicalLine: number;
	default: number;
} & BiorhythmAreaChartData;
