import React, {FC, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {COMPATIBILITY_HASH, ROUTES, STORAGE_KEYS} from 'const';
import {feedbackTriggerService, LogEvent} from 'services';
import {fetchCompatibilities} from 'store/reducers/compatibilities';
import {Storage} from 'utility';

import AppText from '../appText';
import Button from '../buttons/button';
import CompatibilitySlider from '../compatibilitySlider';

import styles from './style.module.scss';

const CompatibilitySelection: FC = () => {
	const dispatch = useDispatch();
	const [yourSign, setYourSign] = useState<number>(1);
	const [partnerSign, setPartnerSign] = useState<number>(1);
	const navigate = useNavigate();

	const onClickCheckHandler = async () => {
		dispatch(fetchCompatibilities(yourSign, partnerSign, false));
		navigate(`${ROUTES.PRIVATE.COMPATIBILITY}#${COMPATIBILITY_HASH.analyze}`);
		Storage.saveData(STORAGE_KEYS.ZODIAC_SIGNS, {yourSign, partnerSign});

		feedbackTriggerService.trigger(STORAGE_KEYS.COMPATIBILITY_COUNT);

		LogEvent.saveEvent('Web Compatibility Request', {
			userSign: yourSign,
			partnerSign,
		});
	};

	return (
		<div className={styles.compatibilitySelection}>
			<AppText className={styles.title}>Choose your sign</AppText>
			<CompatibilitySlider activeElement={yourSign} setActiveElement={setYourSign} />
			<AppText className={styles.title}>Choose partner’s sign</AppText>
			<CompatibilitySlider activeElement={partnerSign} setActiveElement={setPartnerSign} />
			<Button
				className={styles.buttonStyles}
				variant="secondary"
				onClick={onClickCheckHandler}>
				Check
			</Button>
		</div>
	);
};

export default CompatibilitySelection;
