import {ThunkAction} from 'redux-thunk';

import {CalendarsAPI} from 'services';
import {CalendarInitialState, StateType} from 'types';
import {Toast} from 'utility';

import {CalendarActions, CalendarActionTypes, setCalendar} from '../actions/calendars';

const initialState: CalendarInitialState = {
	calendars: null,
};

type Thunk = ThunkAction<Promise<void>, StateType, unknown, CalendarActionTypes>; // что возвращает санка, тип стора, всегда unknown, тип всех экшенов этого редьюсера

export const fetchCalendars =
	(ZodiacSign: number, Month: number): Thunk =>
	async dispatch => {
		try {
			const response = await CalendarsAPI(ZodiacSign, Month);
			dispatch(setCalendar(response));
		} catch (error) {
			Toast.error(JSON.stringify(error));
		}
	};

const calendarsReducer = (
	state: CalendarInitialState,
	action: CalendarActionTypes,
): CalendarInitialState => {
	const calendarsState = state || initialState;

	switch (action.type) {
		case CalendarActions.SET_CALENDAR:
			return {
				...calendarsState,
				calendars: action.payload,
			};
		default:
			return calendarsState;
	}
};

export default calendarsReducer;
