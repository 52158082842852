import {useSelector} from 'react-redux';

import {StateType} from 'types';

export const GetUserData = () => useSelector((state: StateType) => state.user);
export const GetUserBithDate = () => useSelector((state: StateType) => state.user.birthDateTimeUtc);
export const GetUserCity = () => useSelector((state: StateType) => state.user.birthCity.name);
export const GetUserName = () => useSelector((state: StateType) => state.user.firstName);
export const GetZodiacSign = () => useSelector((state: StateType) => state.user.zodiacSign);
export const GetGender = () => useSelector((state: StateType) => state.user.gender);
export const GetUserToken = () => useSelector((state: StateType) => state.user.token);
export const GetUserEmail = () => useSelector((state: StateType) => state.user.email);
