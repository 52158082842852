const second = 1000;
const minute = second * 60;
const hour = minute * 60;
const day = hour * 24;
const week = day * 7;

const MILLISECONDS = {
	second,
	minute,
	hour,
	day,
	week,
};

export default MILLISECONDS;
