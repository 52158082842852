import React, {FC, useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import {moreIcon} from 'assets';
import {Navigation} from 'const';
import {LinksType} from 'types';

import AdditionalMenu from './additionalMenu';

import styles from './style.module.scss';

type PropsType = {
	className?: string;
};

const menuLink = Navigation.filter(item => item.type === LinksType.ordinary);
const additionalMenuLink = Navigation.filter(item => item.type !== LinksType.ordinary);

const FooterMenu: FC<PropsType> = ({className = ''}) => {
	const [isAdditionalMenuActive, setIsAdditionalMenuActive] = useState(false);
	const [isAdditionalMenuOpen, setIsAdditionalMenuOpen] = useState(false);
	const location = useLocation();

	const checkRoute = (linkRoute: string, currentRoute: string) => {
		const isActive = linkRoute.split('/')[1] === currentRoute.split('/')[1];
		return isActive;
	};

	useEffect(() => {
		const isMenuActive = additionalMenuLink.some(item =>
			checkRoute(item.linkTo, location.pathname),
		);
		setIsAdditionalMenuActive(isMenuActive);
	}, [location.pathname]);

	const buttonsElements = menuLink.map(item => (
		<Link
			key={item.key}
			to={item.linkTo}
			className={`${checkRoute(item.linkTo, location.pathname) ? styles.active : ''}`}>
			<ReactSVG wrapper="span" src={item.menuIcon} className={styles.buttonsIcon} />
			{item.name}
		</Link>
	));

	const onAdditionalMenuClick = () => {
		setIsAdditionalMenuOpen(!isAdditionalMenuOpen);
	};

	return (
		<>
			{isAdditionalMenuOpen && (
				<AdditionalMenu onAdditionalMenuClick={onAdditionalMenuClick} />
			)}
			<footer className={`${styles.footerMenu} ${className}`}>
				{buttonsElements}
				<button
					data-name="link"
					className={`${isAdditionalMenuActive ? styles.active : ''}`}
					type="button"
					onClick={onAdditionalMenuClick}>
					<ReactSVG wrapper="span" src={moreIcon} className={styles.buttonsIcon} />
					More
				</button>
			</footer>
		</>
	);
};

export default FooterMenu;
