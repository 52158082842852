import {AstroEvent, CurrentMoonPhase, MoonPhase} from 'types';

export enum AstrologyActions {
	SET_ASTRO_EVENTS = 'astrology/setAstroEvents',
	SET_MOON_PHASES = 'astrology/setMoonPhases',
	SET_CURRENT_MOON_PHASES = 'astrology/setCurrentMoonPhases',
}

type SetAstroEvents = {type: AstrologyActions.SET_ASTRO_EVENTS; payload: AstroEvent[]};
type SetMoonPhases = {type: AstrologyActions.SET_MOON_PHASES; payload: MoonPhase[]};
type SetCurrentMoonPhases = {
	type: AstrologyActions.SET_CURRENT_MOON_PHASES;
	payload: CurrentMoonPhase;
};

export const setAstroEvents = (payload: AstroEvent[]): SetAstroEvents => {
	return {
		type: AstrologyActions.SET_ASTRO_EVENTS,
		payload,
	};
};

export const setMoonPhases = (payload: MoonPhase[]): SetMoonPhases => {
	return {
		type: AstrologyActions.SET_MOON_PHASES,
		payload,
	};
};

export const setCurrentMoonPhases = (payload: CurrentMoonPhase): SetCurrentMoonPhases => {
	return {
		type: AstrologyActions.SET_CURRENT_MOON_PHASES,
		payload,
	};
};

export type AstrologyActionTypes = SetAstroEvents | SetMoonPhases | SetCurrentMoonPhases;
