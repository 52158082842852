import React, {FC, useState} from 'react';
import {Link} from 'react-router-dom';

import {ROUTES, ZODIACS} from 'const';
import {HoroscopesTypes} from 'types';
import {Horoscopes} from 'types/common';
import {getDateTitle} from 'utility/functions';

import ActivityCalendars from '../activityCalendars';
import AppText from '../appText';

import styles from './style.module.scss';

type PropsType = {
	isTitle: boolean;
	isMoreButton: boolean;
	isShortVersion?: boolean;
	horoscopes: HoroscopesTypes | null;
	activeZodiacSign: number;
};

const YourHoroscope: FC<PropsType> = ({
	isTitle,
	isMoreButton,
	horoscopes,
	isShortVersion = false,
	activeZodiacSign,
}) => {
	const zodiac = ZODIACS?.find(item => item.key === horoscopes?.zodiacSignInfo.zodiacSign);

	const [activeButtonKey, setActiveButtonKey] = useState<string>('today');

	const navButtons = [
		{
			name: 'Today',
			key: 1,
			onClick() {
				setActiveButtonKey('today');
			},
		},
		{
			name: 'Week',
			key: 2,
			onClick() {
				setActiveButtonKey('week');
			},
		},
		{
			name: 'Month',
			key: 3,
			onClick() {
				setActiveButtonKey('month');
			},
		},
		{
			name: 'Year',
			key: 4,
			onClick() {
				setActiveButtonKey('year');
			},
		},
	];

	const buttonElements = navButtons.map(item => {
		return (
			<button
				type="button"
				key={item.key}
				className={`${item.name.toLowerCase() === activeButtonKey ? styles.active : ''}`}
				onClick={item.onClick}>
				{item.name}
			</button>
		);
	});

	const getHoroscopeText = (
		horoscopesData: Horoscopes,
		key: string,
		isShort: boolean,
	): string => {
		if (horoscopes) {
			let horoscopeText = '';
			switch (key) {
				case 'week':
					horoscopeText = horoscopesData.week[0].content;
					break;
				case 'month':
					horoscopeText = horoscopesData.month[0].content;
					break;
				case 'year':
					horoscopeText = horoscopesData.year[0].content;
					break;
				default:
					horoscopeText = horoscopesData.today[0].content;
			}
			return isShort ? `${horoscopeText.split('\n\n')[0].trim()}..` : horoscopeText;
		}
		return 'No info';
	};

	return (
		<div className={styles.yourHoroscope}>
			{isTitle && <AppText className={styles.title}>Your horoscope</AppText>}
			<div className={styles.main}>
				<div className={styles.left}>
					{zodiac && <img src={zodiac?.iconStars} alt="zodiac icon" />}
				</div>
				<div className={styles.right}>
					<div className={styles.nuv}>{buttonElements}</div>
					<div className={styles.text}>
						<AppText className={styles.date}>{getDateTitle(activeButtonKey)}</AppText>
						<p className={styles.description}>
							{horoscopes &&
								getHoroscopeText(
									horoscopes.horoscopes,
									activeButtonKey,
									isShortVersion,
								)}
						</p>
					</div>
					{isMoreButton && (
						<div className={styles.more}>
							<Link to={ROUTES.PRIVATE.HOROSCOPE}>Learn more</Link>
						</div>
					)}
				</div>
			</div>
			<ActivityCalendars activeZodiacSign={activeZodiacSign} />
		</div>
	);
};

export default YourHoroscope;
