import React, {FC, lazy, Suspense} from 'react';
import {Route, Routes} from 'react-router-dom';

import {ROUTES} from 'const';

import Preloader from './preloader';

import './styles/main.scss';

const Horoscope = lazy(() => import('./pages/Horoscope'));
const Compatibility = lazy(() => import('./pages/Compatibility'));
const Profile = lazy(() => import('./pages/Profile'));
const CreatePassword = lazy(() => import('./pages/authPages/CreatePassword'));
const Login = lazy(() => import('./pages/authPages/Login'));
const Welcome = lazy(() => import('./pages/Welcome'));
const ResetPassword = lazy(() => import('./pages/authPages/ResetPassword'));
const Settings = lazy(() => import('./pages/Settings'));
const Biorhythms = lazy(() => import('./pages/Biorhythms'));
const Feed = lazy(() => import('./pages/Feed'));
const EnterName = lazy(() => import('./pages/addFriend/EnterName'));
const FriendGender = lazy(() => import('./pages/addFriend/FriendGender'));
const AddFriendPreview = lazy(() => import('./pages/addFriend/AddFriendPreview'));
const BirthDate = lazy(() => import('./pages/addFriend/BirthDate'));
const TimeOfBirth = lazy(() => import('./pages/addFriend/TimeOfBirth'));
const AddFriendAnalyze = lazy(() => import('./pages/addFriend/AddFriendAnalyze'));
const PlaceOfBirth = lazy(() => import('./pages/addFriend/PlaceOfBirth'));
const Error = lazy(() => import('./pages/Error'));
const Shop = lazy(() => import('./pages/Shop'));
const Product = lazy(() => import('./pages/Product'));
const Payment = lazy(() => import('./pages/Payment'));
const PersonalAstrology = lazy(() => import('./pages/PersonalAstrology'));
const Astrologers = lazy(() => import('./pages/Astrologers'));
const SubscriptionOver = lazy(() => import('./pages/SubscriptionOver'));
const Subscription = lazy(() => import('./pages/Subscription'));

const RootComponent: FC = () => {
	return (
		<Suspense fallback={<Preloader />}>
			<Routes>
				<Route path={ROUTES.PRIVATE.PROFILE} element={<Profile />} />
				<Route path={ROUTES.PRIVATE.HOROSCOPE} element={<Horoscope />} />
				<Route path={ROUTES.PRIVATE.COMPATIBILITY} element={<Compatibility />} />
				<Route path={ROUTES.PUBLIC.CREATE_PASSWORD} element={<CreatePassword />} />
				<Route path={ROUTES.PUBLIC.LOGIN} element={<Login />} />
				<Route path={ROUTES.PUBLIC.WELCOME} element={<Welcome />} />
				<Route path={`${ROUTES.PUBLIC.RESET_PASSWORD}/:step`} element={<ResetPassword />} />
				<Route path={ROUTES.PRIVATE.SETTINGS} element={<Settings />} />
				<Route path={ROUTES.PRIVATE.BIORHYTHMS} element={<Biorhythms />} />
				<Route path={ROUTES.PRIVATE.FEED} element={<Feed />} />
				<Route path={ROUTES.PRIVATE.ENTER_NAME} element={<EnterName />} />
				<Route path={ROUTES.PRIVATE.GENDER} element={<FriendGender />} />
				<Route path={ROUTES.PRIVATE.ADD_FRIEND_PREVIEW} element={<AddFriendPreview />} />
				<Route path={ROUTES.PRIVATE.BIRTH_DATE} element={<BirthDate />} />
				<Route path={ROUTES.PRIVATE.TIME_OF_BIRTH} element={<TimeOfBirth />} />
				<Route path={ROUTES.PRIVATE.ADD_FRIEND_ANALYZE} element={<AddFriendAnalyze />} />
				<Route path={ROUTES.PRIVATE.PLACE_OF_BIRTH} element={<PlaceOfBirth />} />
				<Route path={ROUTES.PRIVATE.SHOP} element={<Shop />} />
				<Route path={ROUTES.PRIVATE.PRODUCT}>
					<Route path=":productId" element={<Product />} />
				</Route>
				<Route path={ROUTES.PRIVATE.PAYMENT}>
					<Route path=":productId" element={<Payment />} />
				</Route>
				<Route path={ROUTES.PRIVATE.PERSONAL_ASTROLOGY} element={<PersonalAstrology />} />
				<Route path={ROUTES.PRIVATE.ASTROLOGERS} element={<Astrologers />} />
				<Route path={ROUTES.PRIVATE.SUBSCRIPTION_OVER} element={<SubscriptionOver />} />
				<Route path={ROUTES.PRIVATE.SUBSCRIPTION} element={<Subscription />} />
				<Route path="*" element={<Error />} />
			</Routes>
		</Suspense>
	);
};

export default RootComponent;
