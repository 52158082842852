import {NewsFeedsRequest} from 'types';

import {appInstanceWithInterceptors} from '../HTTPClient';

export const getNews = (skip: number) =>
	appInstanceWithInterceptors
		.get<NewsFeedsRequest>(`NewsFeed?Skip=${skip}&Take=2&Reverse=true`)
		.then(response => response.data);

export const toggleLike = (token: string, cloneNumber: number) => {
	appInstanceWithInterceptors.put(`NewsFeed/${token}/Like`, {cloneNumber});
};

export default getNews;
