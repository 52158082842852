import React, {FC} from 'react';

import AppText from '../appText';
import Header from '../header';

import styles from './style.module.scss';

type PropsType = {
	questionTitle: string;
	questionSubtitle?: string;
	className?: string;
	children: JSX.Element;
};

const QuestionLayout: FC<PropsType> = (props: PropsType) => {
	const {questionTitle, questionSubtitle, children, className} = props;
	return (
		<div className={`${styles.questionLayout} ${className || ''}`}>
			<div className="container">
				<Header isAuth showBackButton className={styles.header} />
				<main className={styles.main}>
					<AppText isTitle className={styles.title}>
						{questionTitle}
					</AppText>
					{questionSubtitle && (
						<AppText className={styles.subtitle}>{questionSubtitle}</AppText>
					)}
					{children}
				</main>
			</div>
		</div>
	);
};

export default QuestionLayout;
