import {HoroscopesTypes} from 'types';

export enum HoroscopeActions {
	SET_HOROSCOPE = 'horoscope/setHoroscope',
	SET_USER_HOROSCOPE = 'horoscope/setUserHoroscope',
}

type SetHoroscope = {type: HoroscopeActions.SET_HOROSCOPE; payload: HoroscopesTypes};
type SetUserHoroscope = {type: HoroscopeActions.SET_USER_HOROSCOPE; payload: HoroscopesTypes};

export const setHoroscope = (payload: HoroscopesTypes): SetHoroscope => {
	return {
		type: HoroscopeActions.SET_HOROSCOPE,
		payload,
	};
};

export const setUserHoroscope = (payload: HoroscopesTypes): SetUserHoroscope => {
	return {
		type: HoroscopeActions.SET_USER_HOROSCOPE,
		payload,
	};
};

export type HoroscopeActionTypes = SetHoroscope | SetUserHoroscope;
