import {ZodiacSignInfo} from './zodiac';

export type EnumItemType = {
	key: number;
	value: string;
};

export type EnumTypes = {
	activities: Array<EnumItemType>;
	animals: Array<EnumItemType>;
	aspects: Array<EnumItemType>;
	compatibilityDescriptionTypes: Array<EnumItemType>;
	compatibilityLevels: Array<EnumItemType>;
	compatibilityPercentTypes: Array<EnumItemType>;
	cycleStatuses: Array<EnumItemType>;
	elements: Array<EnumItemType>;
	focuses: Array<EnumItemType>;
	focusStatuses: Array<EnumItemType>;
	focusTypes: Array<EnumItemType>;
	gems: Array<EnumItemType>;
	genders: Array<EnumItemType>;
	herbals: Array<EnumItemType>;
	horoscopeTypes: Array<EnumItemType>;
	interests: Array<EnumItemType>;
	moonPhases: Array<EnumItemType>;
	moonSigns: Array<EnumItemType>;
	motivations: Array<EnumItemType>;
	planetSigns: Array<EnumItemType>;
	polarities: Array<EnumItemType>;
	professions: Array<EnumItemType>;
	qualities: Array<EnumItemType>;
	relationships: Array<EnumItemType>;
	roles: Array<EnumItemType>;
	rulingPlanets: Array<EnumItemType>;
	slotTypes: Array<EnumItemType>;
	zodiacSigns: Array<EnumItemType>;
};

export type HoroscopesItemType = {
	token: any;
	content: any;
	horoscopeType: any;
	lastUpdatedAt: any;
	successPercent: any;
};

export type Horoscopes = {
	today: Array<HoroscopesItemType>;
	week: Array<HoroscopesItemType>;
	month: Array<HoroscopesItemType>;
	year: Array<HoroscopesItemType>;
};

export type Focus = {
	focus: number;
	focusStatuses: Array<{focusType: number; focusStatus: number; percent: number}>;
};

export type HoroscopesTypes = {
	zodiacSignInfo: ZodiacSignInfo;
	horoscopes: Horoscopes;
	focus: Focus;
};

export type CompatibilityDescriptionsType = {
	token: string;
	content: string;
	compatibilityDescriptionType: number;
};

export type CompatibilityPercentsType = {
	token: string;
	percent: number;
	compatibilityPercentType: number;
	compatibilityLevel: number;
};

export type CompatibilityBodyType = {
	token: string;
	firstZodiacSign: number;
	secondZodiacSign: number;
	compatibilityLevel: number;
	descriptions: Array<CompatibilityDescriptionsType>;
	percents: Array<CompatibilityPercentsType>;
	advantages: string[];
	disadvantages: string[];
};

export type CompatibilitiesTypes = {
	body: Array<CompatibilityBodyType>;
	fullCount: number;
	count: number;
};

export type CalendarItemKey = 'love' | 'health' | 'education' | 'work';

export type CalendarsTypes = {
	[key in CalendarItemKey]: CalendarItemType;
};

export type CalendarItemType = {
	token: any;
	days: Array<CalendarDaysType>;
};

export type CalendarDaysType = {
	date: string;
	events: number[];
};

export type CategoryItemType = {
	key: number;
	value: string;
};

export type EventsItemType = {
	key: number;
	value: string;
};

export type CategoryEventsTypes = {
	category: CategoryItemType;
	events: Array<EventsItemType>;
};

export type LifePathNumbersTypes = {
	token: string;
	number: number;
	values: string;
	challenges: string;
	path: string;
	description: string;
};

export enum TooltipThemes {
	default = 'default',
	light = 'light',
}

export enum TimeFormat {
	PM = 1,
	AM = 2,
}
