import {
	BiorhythmsResponse,
	CurrentMoonPhase,
	FriendData,
	FriendDataResponseType,
	LifePathNumbersTypes,
	MoonPhase,
	SignFocusOfTheDay,
	SignMission,
	SignQuote,
	SignStrengthsAndWeknesses,
	SignSymbolismAndMyth,
	TodayLuckyNumberTypes,
	ZodiacAccordionDataType,
	ZodiacSignInfo,
} from 'types';

import {appInstanceWithInterceptors} from '../HTTPClient';

export const getFriends = () =>
	appInstanceWithInterceptors
		.get<FriendDataResponseType[]>('Friends')
		.then(response => response.data);

export const postFriendData = (friendData: FriendData) =>
	appInstanceWithInterceptors.post(`Friends`, friendData);

export const deleteFriend = (friendToken: string) =>
	appInstanceWithInterceptors.delete(`Friends/${friendToken}`);

export const getSelectedFriendAccordionData = (signId: number, friendToken: string) =>
	appInstanceWithInterceptors
		.get<ZodiacAccordionDataType>(
			`ZodiacSignInfos/additionalInfo?ZodiacSign=${signId}&FriendToken=${friendToken}`,
		)
		.then(response => response.data);

export const getSelectedFriendSignInfo = (signId: number, friendToken: string) =>
	appInstanceWithInterceptors
		.get<ZodiacSignInfo>(`ZodiacSignInfos?ZodiacSign=${signId}&FriendToken=${friendToken}`)
		.then(response => response.data);

export const getSelectedFriendZodiacStrengths = (signId: number, friendToken: string) =>
	appInstanceWithInterceptors
		.get<SignStrengthsAndWeknesses>(`Strengths?ZodiacSign=${signId}&FriendToken=${friendToken}`)
		.then(response => response.data);

export const getSelectedFriendWeaknesses = (signId: number, friendToken: string) =>
	appInstanceWithInterceptors
		.get<SignStrengthsAndWeknesses>(
			`Weaknesses?ZodiacSign=${signId}&FriendToken=${friendToken}`,
		)
		.then(response => response.data);

export const getSelectedFriendSymbolismAndMyth = (signId: number, friendToken: string) =>
	appInstanceWithInterceptors
		.get<SignSymbolismAndMyth>(`Myths?ZodiacSign=${signId}&FriendToken=${friendToken}`)
		.then(response => response.data);

export const getSelectedFriendMission = (signId: number, friendToken: string) =>
	appInstanceWithInterceptors
		.get<SignMission>(`Missions?ZodiacSign=${signId}&FriendToken=${friendToken}`)
		.then(response => response.data);

export const getSelectedFriendFocusOfTheDay = (date: string, friendToken: string) =>
	appInstanceWithInterceptors
		.get<SignFocusOfTheDay>(`Focuses?Date=${date}&FriendToken=${friendToken}`)
		.then(response => response.data);

export const getSelectedFriendLifePathNumbers = (friendToken: string) =>
	appInstanceWithInterceptors
		.get<LifePathNumbersTypes>(`LifePathNumbers?FriendToken=${friendToken}`)
		.then(response => {
			return response.data;
		});

export const getSelectedFriendQuote = (friendToken: string) =>
	appInstanceWithInterceptors
		.get<SignQuote>(`Quotes?FriendToken=${friendToken}`)
		.then(response => response.data);

export const getSelectedFriendTodayLuckyNumber = (friendToken: string) =>
	appInstanceWithInterceptors
		.get<TodayLuckyNumberTypes>(`DailyNumbers?FriendToken=${friendToken}`)
		.then(response => {
			return response.data;
		});

export const getSelectedFriendBiorhythms = (friendToken: string) =>
	appInstanceWithInterceptors
		.get<BiorhythmsResponse>(`Biorhythms?FriendToken=${friendToken}`)
		.then(response => {
			return response.data;
		});

export const getSelectedFriendCurrentMoonPhase = (friendToken: string) =>
	appInstanceWithInterceptors
		.get<CurrentMoonPhase>(`MoonPhases/current?FriendToken=${friendToken}`)
		.then(response => response.data);

export const getSelectedFriendMoonPhases = (friendToken: string) =>
	appInstanceWithInterceptors
		.get<MoonPhase[]>(`MoonPhases?FriendToken=${friendToken}`)
		.then(response => response.data);
