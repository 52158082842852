import {emotionalChart, intellectualChart, physicalChart} from 'assets';
import {BiorhythmsCategory} from 'types';

export const COLORS = {
	[BiorhythmsCategory.physical]: '#00D9B1',
	[BiorhythmsCategory.emotional]: '#FFFFFF',
	[BiorhythmsCategory.intellectual]: '#00BDF5',
};

export const CHARTS_IMG = {
	[BiorhythmsCategory.physical]: physicalChart,
	[BiorhythmsCategory.emotional]: emotionalChart,
	[BiorhythmsCategory.intellectual]: intellectualChart,
};
