import {ThunkAction, ThunkDispatch} from 'redux-thunk';

import {STORAGE_KEYS} from 'const';
import {AuthAPI, LogEvent} from 'services';
import {AuthInitialState, LoginResponseType, StateType} from 'types';
import {Storage, Toast} from 'utility';

import {AuthActions, AuthActionsType, setAuthData} from '../actions/auth';

import {initializeApp} from './general';

const initialState: AuthInitialState = {
	email: null,
	code: null,
	password: null,
	authData: null,
};

type Thunk = ThunkAction<Promise<void>, StateType, unknown, AuthActionsType>;

const saveLoginData = async (
	dispatch: ThunkDispatch<StateType, unknown, AuthActionsType>,
	data: LoginResponseType,
) => {
	dispatch(setAuthData(data));
	LogEvent.setUserId(data.userToken);
	await Storage.saveData(STORAGE_KEYS.AUTH_TOKEN, data.authToken);
};

export const fetchLogin =
	({email, password}, setError: () => void, navigate: () => void): Thunk =>
	async dispatch => {
		AuthAPI.login({username: email, password})
			.then(response => {
				saveLoginData(dispatch, response);
			})
			.then(() => navigate())
			.then(() => dispatch(initializeApp()))
			.catch(() => {
				setError();
			});
	};

export const setEmailForForgotPassword =
	(email: string, setError: () => void): Thunk =>
	async () => {
		await AuthAPI.setEmailForForgotPassword(email).catch(error => {
			if (error.response.status === 404) {
				setError();
			} else {
				Toast.error(JSON.stringify(error));
			}
		});
	};

export const setFirstPassword =
	(
		params: {
			userToken: string;
			verificationToken: string;
			password: string;
			firstName: string;
		},
		setError: () => void,
		navigate: () => void,
	): Thunk =>
	async dispatch => {
		await AuthAPI.setPassword(params)
			.then(response =>
				dispatch(
					fetchLogin(
						{email: response.data.email, password: params.password},
						setError,
						navigate,
					),
				),
			)
			.catch(error => {
				if (error.response.status === 404) {
					setError();
				} else {
					Toast.error(JSON.stringify(error));
				}
			});
	};

export const fetchResetPassword =
	(
		email: string,
		code: string,
		password: string,
		navigate: () => void,
		setIsError: () => void,
	): Thunk =>
	async dispatch => {
		AuthAPI.resetPassword(email, code, password)
			.then(response => {
				saveLoginData(dispatch, response);
			})
			.then(() => {
				if (navigate) navigate();
			})
			.catch(() => {
				setIsError();
			});
	};

const authReducer = (state: AuthInitialState, action: AuthActionsType): AuthInitialState => {
	const authState = state || initialState;

	switch (action.type) {
		case AuthActions.SET_EMAIL:
			return {
				...authState,
				email: action.payload,
			};
		case AuthActions.SET_CODE:
			return {
				...authState,
				code: action.payload,
			};
		case AuthActions.SET_PASSWORD:
			return {
				...authState,
				password: action.payload,
			};
		case AuthActions.SET_AUTH_DATA:
			return {
				...authState,
				authData: action.payload,
			};
		default:
			return authState;
	}
};

export default authReducer;
