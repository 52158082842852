import React, {useEffect, useState} from 'react';

import {feedbackTriggerService} from 'services';

import FeedbackModal from './FeedbackModal';
import SurveyModal from './SurveyModal';

enum FeedbackModalTypes {
	Feedback = 'feedback',
	Survey = 'survey',
}

const Feedback: React.FC = () => {
	const [activeModal, setActiveModal] = useState<FeedbackModalTypes | null>(null);

	const onFeedbackSent = () => {
		setActiveModal(FeedbackModalTypes.Survey);
	};

	const onSurveyConfirm = () => {
		feedbackTriggerService.finish();
		setActiveModal(null);
	};

	const onSurveyCancel = () => {
		feedbackTriggerService.skip();
		setActiveModal(null);
	};

	useEffect(() => {
		const unsubuscribeFeedbackTrigger = feedbackTriggerService.subscribe(() => {
			setActiveModal(FeedbackModalTypes.Feedback);
		});

		return unsubuscribeFeedbackTrigger;
	}, []);

	return (
		<>
			<FeedbackModal
				visible={activeModal === FeedbackModalTypes.Feedback}
				onFeedbackSent={onFeedbackSent}
			/>
			<SurveyModal
				visible={activeModal === FeedbackModalTypes.Survey}
				onConfirm={onSurveyConfirm}
				onCancel={onSurveyCancel}
			/>
		</>
	);
};

export default Feedback;
