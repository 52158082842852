import {useSelector} from 'react-redux';

import {StateType} from 'types';

export const GetFriendData = () => useSelector((state: StateType) => state.friends);
export const GetFriendName = () => useSelector((state: StateType) => state.friends.firstName);
export const GetFriendBirthDate = () =>
	useSelector((state: StateType) => state.friends.birthDateTimeUtc);
export const GetFriendsList = () => useSelector((state: StateType) => state.friends.friends);
export const GetSelectedProfileToken = () =>
	useSelector((state: StateType) => state.friends.selectedProfileToken);
export const GetSelectedFriendAccordionData = () =>
	useSelector((state: StateType) => state.friends.selectedFriendAccordionData);
export const GetSelectedFriendStrengths = () =>
	useSelector((state: StateType) => state.friends.selectedFriendStrengths);
export const GetSelectedFriendWeaknesses = () =>
	useSelector((state: StateType) => state.friends.selectedFriendWeaknesses);
export const GetSelectedFriendSymbolismAndMyth = () =>
	useSelector((state: StateType) => state.friends.selectedFriendSymbolismAndMyth);
export const GetSelectedFriendMission = () =>
	useSelector((state: StateType) => state.friends.selectedFriendMission);
export const GetSelectedFriendZodiacSignInfo = () =>
	useSelector((state: StateType) => state.friends.selectedFriendZodiacSignInfo);
export const GetSelectedFriendQuote = () =>
	useSelector((state: StateType) => state.friends.selectedFriendQuote);
export const GetSelectedFriendTodayLuckyNumber = () =>
	useSelector((state: StateType) => state.friends.selectedFriendTodayLuckyNumber);
export const GetSelectedFriendFocusOfTheDay = () =>
	useSelector((state: StateType) => state.friends.selectedFriendFocusOfTheDay);
export const GetSelectedFriendLifePathNumbers = () =>
	useSelector((state: StateType) => state.friends.selectedFriendLifePathNumbers);
export const GetSelectedFriendBiorhythms = () =>
	useSelector((state: StateType) => state.friends.selectedFriendBiorhythms);
export const GetSelectedFriendMoonPhases = () =>
	useSelector((state: StateType) => state.friends.selectedFriendMoonPhases);
export const GetSelectedFriendCurrentMoonPhase = () =>
	useSelector((state: StateType) => state.friends.selectedFriendCurrentMoonPhase);
