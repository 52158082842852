import {
	aloeVera,
	celadine,
	chamomile,
	coltsfoot,
	echinacea,
	ephedra,
	ginseng,
	lavender,
	leuzea,
	mint,
	nettle,
	parsley,
	rosemary,
	sage,
} from 'assets';

const HERBALS = [
	{
		key: 1,
		value: 'Aloe vera',
		icon: aloeVera,
	},
	{
		key: 2,
		value: 'Mint',
		icon: mint,
	},
	{
		key: 3,
		value: 'Leuzea',
		icon: leuzea,
	},
	{
		key: 4,
		value: 'Celadine',
		icon: celadine,
	},
	{
		key: 5,
		value: 'Lavender',
		icon: lavender,
	},
	{
		key: 6,
		value: 'Parsley',
		icon: parsley,
	},
	{
		key: 7,
		value: 'Chamomile',
		icon: chamomile,
	},
	{
		key: 8,
		value: 'Coltsfoot',
		icon: coltsfoot,
	},
	{
		key: 9,
		value: 'Echinacea',
		icon: echinacea,
	},
	{
		key: 10,
		value: 'Nettle',
		icon: nettle,
	},
	{
		key: 11,
		value: 'Rosemary',
		icon: rosemary,
	},
	{
		key: 12,
		value: 'Ephedra',
		icon: ephedra,
	},
	{
		key: 13,
		value: 'Sage',
		icon: sage,
	},
	{
		key: 14,
		value: 'Ginseng',
		icon: ginseng,
	},
];

export default HERBALS;
