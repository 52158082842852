import React, {FC} from 'react';

import AppText from '../../appText';

import styles from '../style.module.scss';

type PropsType = {
	value: string;
	label: string;
	onClick: () => void;
};

const DefaultCityField: FC<PropsType> = ({value, label, onClick}) => {
	return (
		<>
			<AppText className={styles.label}>{label}</AppText>
			<button
				onClick={onClick}
				type="button"
				className={`${styles.formController} ${styles.button}`}>
				<div className={styles.inputContainer}>
					<AppText>{value}</AppText>
				</div>
			</button>
		</>
	);
};

export default DefaultCityField;
