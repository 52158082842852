import {HoroscopesTypes} from 'types';

import {appInstanceWithInterceptors} from '../HTTPClient';

export const getHoroscopes = (signId: number) =>
	appInstanceWithInterceptors
		.get<HoroscopesTypes>(`zodiacSignInfos/withHoroscopes?ZodiacSign=${signId}`)
		.then(response => response.data);

export default getHoroscopes;
