import React, {FC} from 'react';
import {ReactSVG} from 'react-svg';

import {matchBgLeft, matchBgRight} from 'assets';

import AppText from '../appText';

import styles from './style.module.scss';

type PropsType = {
	children: string;
	className?: string;
};

const CompatibilityMatchScreen: FC<PropsType> = ({children, className = ''}) => {
	return (
		<div className={`${styles.matchScreen} ${className}`}>
			<ReactSVG wrapper="span" src={matchBgLeft} className={styles.matchBgLeft} />
			<AppText className={styles.matchTitle}>{children}</AppText>
			<ReactSVG wrapper="span" src={matchBgRight} className={styles.matchBgRight} />
		</div>
	);
};

export default CompatibilityMatchScreen;
