import React, {FC, useEffect, useRef} from 'react';
import Slider from 'react-slick';
import {ReactSVG} from 'react-svg';

import {nextArrow} from 'assets';
import AppText from 'components/appText';
import {ZODIACS} from 'const';
import {LogEvent} from 'services';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './style.module.scss';

type PropsType = {
	activeElement: number;
	setActiveElement: (key: number) => void;
	isHeader?: boolean;
	style?: string;
};

const NextArrow = (props: any) => {
	const {className, style, onClick, myClassName, children} = props;
	return (
		<button
			type="button"
			className={`${className} ${myClassName}`}
			style={{...style}}
			onClick={onClick}>
			{children}
		</button>
	);
};

const PrevArrow = (props: any) => {
	const {className, style, onClick, myClassName, children} = props;
	return (
		<button
			type="button"
			className={`${className} ${myClassName}`}
			style={{...style}}
			onClick={onClick}>
			{children}
		</button>
	);
};

const HoroscopeSlider: FC<PropsType> = ({
	activeElement,
	setActiveElement,
	isHeader = true,
	style = '',
}) => {
	const zodiac = ZODIACS?.find(item => item.key === activeElement);
	const sliderRef = useRef<Slider | null>(null);

	const onSignClick = (key: number) => {
		setActiveElement(key);
		LogEvent.saveEvent('Web Horoscope - Sign Click');
	};

	useEffect(() => {
		setActiveElement(activeElement);
		sliderRef.current?.slickGoTo(activeElement - 1);
	}, [activeElement]);

	const sliderElements = ZODIACS.map(item => {
		return (
			<button
				key={item.key}
				type="button"
				className={`${styles.sliderElement} ${
					activeElement === item.key ? styles.active : ''
				}`}
				onClick={() => onSignClick(item.key)}>
				<ReactSVG
					wrapper="span"
					src={`${item?.iconsSlider}`}
					className={styles.iconsSlider}
				/>
				<p>{item.value}</p>
			</button>
		);
	});

	const settings = {
		dots: false,
		infinite: true,
		slidesToShow: 7,
		swipeToSlide: true,
		centerMode: true,
		centerPadding: '0px',
		initialSlide: activeElement ? activeElement - 1 : 0,
		nextArrow: (
			<NextArrow myClassName={styles.nextArrow}>
				<ReactSVG wrapper="span" src={nextArrow} className={styles.nextArrowIcon} />
			</NextArrow>
		),
		prevArrow: (
			<PrevArrow myClassName={styles.prevArrow}>
				<ReactSVG wrapper="span" src={nextArrow} className={styles.prevArrowIcon} />
			</PrevArrow>
		),
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 7,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 860,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 540,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<div className={`${styles.horoscopeSlider} ${style}`}>
			{zodiac && isHeader && (
				<AppText
					isSubtitle
					className={styles.subtitle}>{`${zodiac?.value} daily horoscope`}</AppText>
			)}
			{zodiac && isHeader && <AppText className={styles.zodiacDate}>{zodiac.dates}</AppText>}
			<Slider className={styles.slider} {...settings} ref={sliderRef}>
				{sliderElements}
			</Slider>
		</div>
	);
};

export default HoroscopeSlider;
