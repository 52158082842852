import React, {FC, ReactNode} from 'react';

import {backgroundChanger} from 'hoc';

import AuthContainer from '../../containers/authContainer';
import Footer from '../../footer';
import Header from '../../header';

import styles from './style.module.scss';

type PropsType = {
	children: ReactNode;
	headerWithProgressBar?: boolean;
};

const AuthView: FC<PropsType> = ({children, headerWithProgressBar = false}) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.sideWithBg} />
			<div className={styles.sideWithoutBg} />
			<div className={`container ${styles.contentWrapper}`}>
				<Header className={styles.header} />
				<div className={styles.content}>
					<AuthContainer />
					<div className={styles.formContainer}>
						<Header
							className={`${styles.headerMobile} ${
								headerWithProgressBar ? styles.headerWithProgressBar : ''
							}`}
						/>
						<div className={styles.mobileContainer}>{children}</div>
						<Footer className={styles.footer} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default backgroundChanger('auth')(AuthView);
