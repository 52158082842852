import React, {FC} from 'react';
import {ReactSVG} from 'react-svg';

import {lock} from 'assets';

import AppText from '../../appText';

import styles from './style.module.scss';

type PropsType = {
	text: string;
	onClick?: (ev?: any) => void;
	isDisabled?: boolean;
	isProcess?: boolean;
};

const PaymentButton: FC<PropsType> = ({isDisabled = false, isProcess = false, onClick, text}) => (
	<button
		disabled={isDisabled || isProcess}
		type="button"
		className={`${styles.button} ${isDisabled ? styles.disabled : ''} ${
			isProcess ? styles.process : ''
		}`}
		onClick={onClick}>
		<ReactSVG wrapper="span" src={lock} />
		<AppText>{text}</AppText>
		{isProcess && <div className={styles.loader} />}
	</button>
);

export default PaymentButton;
