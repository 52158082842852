const correctEmail = (value: string): string | undefined => {
	if (!value) return 'Field is required';
	if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) return undefined;
	return 'Invalid email address';
};

const minLengthCreator =
	(minLength: number) =>
	(value: string): string | undefined => {
		if (value && value.length >= minLength) return undefined;
		return `Password is too simple (minimum ${minLength} characters)`;
	};

const maxLengthCreator =
	(maxLength: number) =>
	(value: string): string | undefined => {
		if (value && value.length <= maxLength) return undefined;
		return `Password is too large (maximum ${maxLength} characters)`;
	};

export const required = (value: string): string | undefined => {
	if (value && value.trim()) return undefined;
	return 'Field is required';
};

export const emailValidators = {
	required: (value: string) => required(value),
	correct: (value: string) => correctEmail(value),
};

export const passwordValidatorsCreator = (minLength: number, maxLength: number) => ({
	required: (value: string) => required(value),
	minLenght: (value: string) => minLengthCreator(minLength)(value),
	maxLenght: (value: string) => maxLengthCreator(maxLength)(value),
});

const maxLengthNameCreator =
	(maxLength: number) =>
	(value: string): string | undefined => {
		if (value && value.length <= maxLength) return undefined;
		return `Name is too large (maximum ${maxLength} characters)`;
	};

export const correctName = (value: string): string | undefined => {
	if (/^[a-z]+$/i.test(value)) return undefined;
	return 'Name must contain only letters';
};

export const nameValidators = (maxLength: number) => ({
	required: (value: string) => required(value),
	maxLength: (value: string) => maxLengthNameCreator(maxLength)(value),
	// correctName: (value: string) => correctName(value),
});

export default required;
