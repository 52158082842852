import {AstroEvent, CurrentMoonPhase, MoonPhase} from 'types';

import {appInstanceWithInterceptors} from '../HTTPClient';

export const getAstroEvents = () =>
	appInstanceWithInterceptors.get<AstroEvent[]>('AstroEvents').then(response => response.data);

export const getCurrentMoonPhase = () =>
	appInstanceWithInterceptors
		.get<CurrentMoonPhase>('MoonPhases/current')
		.then(response => response.data);

export const getMoonPhases = () =>
	appInstanceWithInterceptors.get<MoonPhase[]>('MoonPhases').then(response => response.data);
