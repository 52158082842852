import React, {FC} from 'react';
import {ToastContainer, ToastContainerProps} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

type PropsType = {
	className?: string;
};

const Toast: FC<PropsType> = ({className = ''}) => {
	const toastContainerSettings: ToastContainerProps = {
		position: 'bottom-right',
		autoClose: 500,
		hideProgressBar: false,
		newestOnTop: false,
		closeOnClick: true,
		rtl: false,
		pauseOnFocusLoss: true,
		draggable: true,
		pauseOnHover: true,
		className,
	};
	return <ToastContainer {...toastContainerSettings} />;
};

export default Toast;
