import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk';

import {STORAGE_KEYS} from 'const';
import {Storage} from 'utility';

import astrology from './reducers/astrology';
import auth from './reducers/auth';
import calendars from './reducers/calendars';
import compatibilities from './reducers/compatibilities';
import friends from './reducers/friends';
import general from './reducers/general';
import horoscopes from './reducers/horoscopes';
import newsFeed from './reducers/newsFeed';
import user from './reducers/user';
import zodiac from './reducers/zodiac';

const DESTROY_SESSION = 'session/DESTROY_SESSION';

export type DestroySessionType = {type: typeof DESTROY_SESSION};
export const destroySession = (): DestroySessionType => ({
	type: DESTROY_SESSION,
});

const appReducer = combineReducers({
	general,
	horoscopes,
	auth,
	user,
	compatibilities,
	zodiac,
	calendars,
	astrology,
	newsFeed,
	friends,
});

const rootReducer = (state: any, action: any) => {
	let rootState = state;

	if (action.type === DESTROY_SESSION) {
		Storage.removeItem(STORAGE_KEYS.AUTH_TOKEN);
		Storage.removeItem(STORAGE_KEYS.ZODIAC_SIGNS);
		// reset all reducers to their initial state except general
		rootState = {
			general: {
				...state.general,
				isInit: false,
			},
		};
	}

	return appReducer(rootState, action);
};

export const composeEnhancers =
	(window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunkMiddleware)));

export default store;
