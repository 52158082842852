import {
	astrologersFooterIcon,
	astrologersIcon,
	compatibilityIcon,
	compatibilityIconFooterMenu,
	feedFooterIcon,
	feedIcon,
	horoscopeIcon,
	horoscopeIconFooterMenu,
	myProfileIcon,
	profileIconFooterMenu,
	settingsIcon,
	settingsIconFooterMenu,
	shopIcon,
} from 'assets';
import {LinksType} from 'types';

import {ROUTES} from './routes';

const INFO_LINKS = [
	{key: 1, name: 'Terms of service', href: 'https://appaster.com/terms-of-use'},
	{key: 2, name: 'Privacy policy', href: 'https://appaster.com/privacy-policy'},
	{key: 3, name: 'Subscription terms', href: 'https://appaster.com/subscription-terms'},
];

const Navigation = [
	{
		key: 1,
		type: LinksType.ordinary,
		name: 'Profile',
		icon: myProfileIcon,
		menuIcon: profileIconFooterMenu,
		isActive: true,
		linkTo: ROUTES.PRIVATE.PROFILE,
	},
	{
		key: 2,
		type: LinksType.ordinary,
		name: 'Horoscope',
		icon: horoscopeIcon,
		menuIcon: horoscopeIconFooterMenu,
		isActive: false,
		linkTo: ROUTES.PRIVATE.HOROSCOPE,
	},
	{
		key: 3,
		type: LinksType.ordinary,
		name: 'Compatibility',
		icon: compatibilityIcon,
		menuIcon: compatibilityIconFooterMenu,
		isActive: false,
		linkTo: ROUTES.PRIVATE.COMPATIBILITY,
	},
	{
		key: 4,
		type: LinksType.ordinary,
		name: 'Feed',
		icon: feedIcon,
		menuIcon: feedFooterIcon,
		isActive: false,
		linkTo: ROUTES.PRIVATE.FEED,
	},
	{
		key: 5,
		type: LinksType.onlyWeb,
		name: 'Astrologers',
		icon: astrologersIcon,
		menuIcon: astrologersFooterIcon,
		isActive: false,
		linkTo: ROUTES.PRIVATE.ASTROLOGERS,
	},
	{
		key: 6,
		type: LinksType.product,
		name: 'Shop',
		icon: shopIcon,
		menuIcon: shopIcon,
		isActive: false,
		linkTo: ROUTES.PRIVATE.SHOP,
	},
	{
		key: 7,
		type: LinksType.onlyWeb,
		name: 'Settings',
		icon: settingsIcon,
		menuIcon: settingsIconFooterMenu,
		isActive: false,
		linkTo: ROUTES.PRIVATE.SETTINGS,
	},
];

export {INFO_LINKS, Navigation};
