import {
	badminton,
	cooking,
	dancing,
	fishing,
	food,
	games,
	gardening,
	jewelry,
	languages,
	laptop,
	motorbike,
	museums,
	music,
	painting,
	puzzles,
	reading,
	sculpting,
	singing,
	sports,
	tennis,
	traveling,
	waterSport,
	writing,
	yoga,
} from 'assets';

const ACTIVITIES = [
	{
		key: 1,
		value: 'Badminton',
		icon: badminton,
	},
	{
		key: 2,
		value: 'Cooking',
		icon: cooking,
	},
	{
		key: 3,
		value: 'Dancing',
		icon: dancing,
	},
	{
		key: 4,
		value: 'Eating out',
		icon: food,
	},
	{
		key: 5,
		value: 'Fishing',
		icon: fishing,
	},
	{
		key: 6,
		value: 'Games',
		icon: games,
	},
	{
		key: 7,
		value: 'Gardening',
		icon: gardening,
	},
	{
		key: 8,
		value: 'Internet',
		icon: laptop,
	},
	{
		key: 9,
		value: 'Jewelry',
		icon: jewelry,
	},
	{
		key: 10,
		value: 'Languages',
		icon: languages,
	},
	{
		key: 11,
		value: 'Motorbike',
		icon: motorbike,
	},
	{
		key: 12,
		value: 'Museums',
		icon: museums,
	},
	{
		key: 13,
		value: 'Music',
		icon: music,
	},
	{
		key: 14,
		value: 'Painting',
		icon: painting,
	},
	{
		key: 15,
		value: 'Puzzles',
		icon: puzzles,
	},
	{
		key: 16,
		value: 'Reading',
		icon: reading,
	},
	{
		key: 17,
		value: 'Sculpting',
		icon: sculpting,
	},
	{
		key: 18,
		value: 'Singing',
		icon: singing,
	},
	{
		key: 19,
		value: 'Sports',
		icon: sports,
	},
	{
		key: 20,
		value: 'Swimming',
		icon: waterSport,
	},
	{
		key: 21,
		value: 'Tennis',
		icon: tennis,
	},
	{
		key: 22,
		value: 'Traveling',
		icon: traveling,
	},
	{
		key: 23,
		value: 'Writing',
		icon: writing,
	},
	{
		key: 24,
		value: 'Yoga',
		icon: yoga,
	},
];

export default ACTIVITIES;
