import {loadStripe} from '@stripe/stripe-js';
import {ChargeBeeSubscription} from 'types';

import * as PaymentAPI from '../api/payment';

class PaymentService {
	static init() {
		return loadStripe(process.env.REACT_APP_STRIPE_KEY || '', {
			locale: 'en',
		});
	}

	static async sendPDFPaypal(
		email: string,
		zodiacSign: string | undefined,
		subscriptionFileType: number | undefined,
		onSuccess: () => void,
		onError: (error: any) => void,
	) {
		try {
			await PaymentAPI.sendFile(email, Number(zodiacSign), subscriptionFileType);
			onSuccess();
		} catch (error) {
			onError(error);
		}
	}

	static async sendPDF(
		data: ChargeBeeSubscription,
		zodiacSign: string | undefined,
		subscriptionFileType: number | undefined,
		onSuccess: () => void,
		onError: (error: any) => void,
	) {
		try {
			await PaymentAPI.buyPDFWithChargebee(data);
			await PaymentAPI.sendFile(data.email, Number(zodiacSign), subscriptionFileType);
			onSuccess();
		} catch (error) {
			onError(error);
		}
	}
}

export default PaymentService;
