import React, {FC, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import {Navigation} from 'const';
import {LinksType} from 'types';

import styles from './style.module.scss';

const menuLink = Navigation.filter(item => item.type !== LinksType.ordinary);

type Props = {
	onAdditionalMenuClick: () => void;
};

const AdditionalMenu: FC<Props> = ({onAdditionalMenuClick}) => {
	const bodyClickListener = (ev: any) => {
		const {target} = ev;

		if (!target?.closest('[data-name="link"]')) {
			onAdditionalMenuClick();
		}
	};

	useEffect(() => {
		const {body} = document;
		body.addEventListener('click', bodyClickListener);

		return () => body.removeEventListener('click', bodyClickListener);
	}, [bodyClickListener]);

	const buttonsElements = menuLink.map(item => (
		<Link key={item.key} to={item.linkTo} data-name="link">
			<ReactSVG wrapper="span" src={item.menuIcon} className={styles.buttonsIcon} />
			{item.name}
		</Link>
	));

	return <div className={styles.modal}>{buttonsElements}</div>;
};

export default AdditionalMenu;
