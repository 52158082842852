import axios from 'axios';
import {STORAGE_KEYS} from 'const';
import {isDev, Storage} from 'utility';

const baseURL = isDev()
	? process.env.REACT_APP_BACKEND_DEV_URL
	: process.env.REACT_APP_BACKEND_PROD_URL;

export const appInstance = axios.create({baseURL});
export const appInstanceWithInterceptors = axios.create({baseURL});

appInstanceWithInterceptors.interceptors.request.use(
	async function getToken(config) {
		const token = Storage.getData(STORAGE_KEYS.AUTH_TOKEN);
		const configWithToken = config;

		if (token && configWithToken.headers) {
			configWithToken.headers.Authorization = `Bearer ${token}`;
		}

		return configWithToken;
	},
	function catchError(error) {
		return Promise.reject(error);
	},
);
