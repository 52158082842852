import React from 'react';
import ReactDOM from 'react-dom';

import AppWrapper from 'app';

import './index.css';

ReactDOM.render(
	<React.StrictMode>
		<AppWrapper />
	</React.StrictMode>,
	document.getElementById('root'),
);
