import {City} from 'types';

import {appInstanceWithInterceptors} from '../HTTPClient';

export const updateBirthDate = (birthDateTimeUtc: Date, offset: number) =>
	appInstanceWithInterceptors.patch('users/birthDate', {
		birthDateTimeUtc,
		birthDateTimeMinutesOffset: offset,
	});

export const updateCity = (birthCity: City) =>
	appInstanceWithInterceptors.patch('users/birthCity', {
		...birthCity,
	});

export const updateName = (firstName: string) =>
	appInstanceWithInterceptors.patch('users/firstName', {
		firstName,
	});
