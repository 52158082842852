import {LoginResponseType} from 'types';

export enum AuthActions {
	SET_EMAIL = 'auth/setEmail',
	SET_CODE = 'auth/setCode',
	SET_PASSWORD = 'auth/setPassword',
	SET_AUTH_DATA = 'auth/setAuthData',
}

type SetEmail = {type: AuthActions.SET_EMAIL; payload: string};
type SetCode = {type: AuthActions.SET_CODE; payload: string};
type SetPassword = {type: AuthActions.SET_PASSWORD; payload: string};
type SetAuthData = {type: AuthActions.SET_AUTH_DATA; payload: LoginResponseType};

export const setEmail = (payload: string): SetEmail => ({
	type: AuthActions.SET_EMAIL,
	payload,
});

export const setCode = (payload: string): SetCode => ({
	type: AuthActions.SET_CODE,
	payload,
});

export const setPassword = (payload: string): SetPassword => ({
	type: AuthActions.SET_PASSWORD,
	payload,
});

export const setAuthData = (payload: LoginResponseType): SetAuthData => ({
	type: AuthActions.SET_AUTH_DATA,
	payload,
});

export type AuthActionsType = SetAuthData | SetCode | SetEmail | SetPassword;
