import React, {useState} from 'react';
import {ReactSVG} from 'react-svg';

import {star} from 'assets';

import styles from './styles.module.scss';

const RatingStars: React.FC<{
	count?: number;
	onStarClick: (rating: number) => void;
}> = ({count = 10, onStarClick}) => {
	const [tempRating, setTempRating] = useState(0);

	return (
		<div className={styles.container}>
			{[...Array(count)].map((_, i) => {
				const rating = i + 1;

				return (
					<ReactSVG
						key={rating}
						wrapper="span"
						src={star}
						className={`${styles.star} ${
							rating <= tempRating && styles.starHighlighted
						}`}
						onMouseEnter={() => {
							setTempRating(rating);
						}}
						onClick={() => {
							onStarClick(rating);
						}}
					/>
				);
			})}
		</div>
	);
};

export default RatingStars;
