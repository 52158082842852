import {
	agate,
	amber,
	amethyst,
	azurine,
	blackTourmaline,
	granite,
	malachite,
	obsidian,
	sodalite,
	tigersEye,
} from 'assets';

const GEMS = [
	{
		key: 1,
		value: 'Agate',
		icon: agate,
	},
	{
		key: 2,
		value: 'Amber',
		icon: amber,
	},
	{
		key: 3,
		value: 'Amethyst',
		icon: amethyst,
	},
	{
		key: 4,
		value: 'Azurine',
		icon: azurine,
	},
	{
		key: 5,
		value: 'Black tourmaline',
		icon: blackTourmaline,
	},
	{
		key: 6,
		value: 'Granite',
		icon: granite,
	},
	{
		key: 7,
		value: 'Malachite',
		icon: malachite,
	},
	{
		key: 8,
		value: 'Obsidian',
		icon: obsidian,
	},
	{
		key: 9,
		value: 'Sodalite',
		icon: sodalite,
	},
	{
		key: 10,
		value: 'Tigers eye',
		icon: tigersEye,
	},
];

export default GEMS;
