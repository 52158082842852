import {
	aliceRunyon,
	angelicAlicia,
	askFran,
	askGrace,
	eyeOfMark,
	mSCHERRYTWO,
	nadiAstrologer,
	nickDutc,
	spiritminded,
} from 'assets';

const ASTROLOGERS = [
	{
		key: 1,
		image: askGrace,
		name: 'Ask Grace',
		description:
			'**Want to know INTENTIONS, SOLUTIONS, OUTCOMES?** See into the MIND and HEART of anyone. ROMANCE LOVE CAREER MONEY. Info from SPIRIT GUIDES + ANGELS! Worth TWICE THE PRICE. 48Yrs.Exp. PhD/Spiritual Counseling',
		price: '$6.52',
		reviews: '2104',
		rating: '4.7',
	},
	{
		key: 2,
		image: nadiAstrologer,
		name: 'Nadi astrologer',
		description:
			'The ancient system of nadi astrology will tell you about relationships and also everything about your partner. Armed with this knowledge, no need to waste your time, just be with your destiny.',
		price: '$4.99',
		reviews: '2104',
		rating: '4.7',
	},
	{
		key: 3,
		image: nickDutc,
		name: 'NickDutch',
		description:
			'Long career history as a reader! Sensibly priced to provide you with Expert level Guru Guidance with all the important things that matter to you. Divination with or Without tools!',
		price: '$5.15',
		reviews: '2104',
		rating: '4.7',
	},
	{
		key: 4,
		image: aliceRunyon,
		name: 'Alice Runyon',
		description:
			"Are you destined to be together? Is this a past Life connection? What's you're purpose together? Why are you in each other's Life? I can tell you all this by tuning in to your NAMES & seeing clearly the answer to your souls",
		price: '$2.39',
		reviews: '2104',
		rating: '4.7',
	},
	{
		key: 5,
		image: eyeOfMark,
		name: 'Eye of Mark',
		description:
			'I will help you move through issues pressing you now. I will be your Guide and your Friend. You have more power than you think! We will find your power and remove the things that block your happiness in Love. Call Me!',
		price: '$4.99',
		reviews: '2104',
		rating: '4.7',
	},
	{
		key: 6,
		image: angelicAlicia,
		name: 'Angelic Alicia',
		description:
			'hi I specialize in love tarot card readings by using my special gifts and divination, I can see the answers you seek through my spirit guides. I will have simple direct answers for you. I can also help with career & finances',
		price: '$5.35',
		reviews: '2104',
		rating: '4.7',
	},
	{
		key: 7,
		image: mSCHERRYTWO,
		name: 'MSCHERRYTWO',
		description:
			"~~~Love~~~Every Minute Counts.~~~ When You Don't, Won't and Just Can't Give Up. I can help. Call me now.~~ I Recognized That I Had The Gift of Sight When I Was 9 Years Old. ~~~GET YOUR ANSWERS PROMPTLY~~~",
		price: '$5.87',
		reviews: '2104',
		rating: '4.7',
	},
	{
		key: 8,
		image: askFran,
		name: 'Ask Fran',
		description:
			"I'm one of the very top-rated psychics on Keen with 29 years experience. I have done over 143,000 readings, will tell you the truth about your life, IN GREAT DETAIL, and make you LAUGH, too. So call now... and Ask Fran.",
		price: '$2.99',
		reviews: '2104',
		rating: '4.7',
	},
	{
		key: 9,
		image: spiritminded,
		name: 'spiritminded',
		description:
			"Worried about job, love and relationships......I've been receiving messages from spirit world since the age of 5, I can help you. Spirit Minded",
		price: '$3.34',
		reviews: '2104',
		rating: '4.7',
	},
];

export default ASTROLOGERS;
