type MediaType = {
	token: string;
	url: string;
	fileType: string;
};

enum Medias {
	VIDEO = 1,
	IMAGE = 2,
}

type FeedsDataType = {
	cloneNumber: number;
	token: string;
	title: string;
	description: string;
	likes: number;
	isLiked: boolean;
	mediaType: Medias;
	medias: MediaType[];
	comments: {
		token: string;
		username: string;
		text: string;
	}[];
};

type NewsFeedsRequest = {
	count: number;
	fullCount: number;
	body: FeedsDataType[];
};

export {Medias};
export type {FeedsDataType, MediaType, NewsFeedsRequest};
