import React, {useState} from 'react';
import {FieldError} from 'react-hook-form';
import {ReactSVG} from 'react-svg';

import {securityOff, securityOn} from 'assets';

import AppText from '../../appText';

import styles from '../style.module.scss';

type PropsType = {
	label: string;
	meta: FieldError | undefined;
	type?: string;
	apiError?: string;
	isLabel?: boolean;
	isSecurity?: boolean;
	className?: string;
	inputClassName?: string;
};

const Input = React.forwardRef<HTMLInputElement, PropsType>(
	(
		{
			label,
			meta,
			type = '',
			apiError,
			isLabel,
			isSecurity,
			className = '',
			inputClassName = '',
			...props
		},
		ref,
	) => {
		const [isPasswordVisible, setIsPasswordVisible] = useState(isSecurity);
		const hasError = !!meta?.message || !!apiError;

		const togglePasswordVisible = () => setIsPasswordVisible(!isPasswordVisible);

		const inputType = type || (isPasswordVisible ? 'password' : 'text');
		return (
			<>
				{isLabel && <AppText className={styles.label}>{label}</AppText>}
				<div className={`${styles.formController} ${className}`}>
					<div
						className={`${styles.inputContainer} ${inputClassName} ${
							isSecurity ? styles.securityInput : ''
						}  ${hasError ? styles.error : ''}`}>
						<input placeholder={label} type={inputType} ref={ref} {...props} />
						{isSecurity && (
							<button
								type="button"
								onClick={togglePasswordVisible}
								className={styles.securityButton}>
								<ReactSVG
									className={styles.securityIcon}
									src={!isPasswordVisible ? securityOn : securityOff}
								/>
							</button>
						)}
					</div>
					{hasError && (
						<span className={styles.warning}>
							{apiError || `Invalid ${label.split(' ')[0].toLowerCase()}`}
						</span>
					)}
				</div>
			</>
		);
	},
);

export default Input;
