import React, {FC} from 'react';

import {BIORHYTHMS_CONSTANT, CHART_CONST} from 'const';
import {Area, CartesianGrid, ComposedChart, Line, ResponsiveContainer, XAxis} from 'recharts';
import {Biorhythms} from 'types';
import {biorhythmsUtils} from 'utility';

import styles from './style.module.scss';

type Props = {
	data: Biorhythms;
};

const BiorhythmsChart: FC<Props> = ({data}) => {
	const chartLines = Object.keys(data.categories);
	const chartData = biorhythmsUtils.getChartData(data);

	return (
		<div className={styles.container}>
			<span className={styles.maxValue}>100%</span>
			<span className={styles.minValue}>-100%</span>
			<span className={styles.firstDate}>{chartData[0].name}</span>
			<ResponsiveContainer width="100%" height={225}>
				<ComposedChart data={chartData} margin={{left: 0, right: 0}}>
					<XAxis
						dataKey="name"
						stroke={CHART_CONST.STROKE_COLOR}
						tick={{
							fill: CHART_CONST.STROKE_COLOR,
							fontSize: CHART_CONST.TICK_FONT_SIZE,
							transform: 'translate(0, 14)',
						}}
					/>
					<CartesianGrid stroke={CHART_CONST.STROKE_COLOR} horizontalPoints={[98]} />
					{chartLines.map(category => {
						return (
							<React.Fragment key={`key-${category}`}>
								<Line
									type="monotone"
									dataKey={`${category}Line`}
									stroke={BIORHYTHMS_CONSTANT.COLORS[category]}
									strokeWidth={2}
									fill={BIORHYTHMS_CONSTANT.COLORS[category]}
									activeDot={undefined}
									dot={false}
								/>
								<defs>
									<linearGradient
										id={`color${category}`}
										x1="0"
										y1="0"
										x2="0"
										y2="1">
										<stop
											offset="5%"
											stopColor={BIORHYTHMS_CONSTANT.COLORS[category]}
											stopOpacity={0.7}
										/>
										<stop
											offset="100%"
											stopColor={CHART_CONST.GRADIENT_STOP_COLOR}
											stopOpacity={0.1}
										/>
									</linearGradient>
								</defs>
								<Area
									type="monotone"
									dataKey={category}
									strokeWidth={0}
									fillOpacity={1}
									fill={`url(#color${category})`}
									baseLine={-100}
								/>
							</React.Fragment>
						);
					})}
					<Line type="natural" dataKey="default" stroke="transparent" dot={false} />
				</ComposedChart>
			</ResponsiveContainer>
		</div>
	);
};

export default BiorhythmsChart;
