export default class Storage {
	static getData(key) {
		const data = localStorage.getItem(key);
		return data ? JSON.parse(data) : data;
	}

	static saveData(key, data) {
		localStorage.setItem(key, JSON.stringify(data));
	}

	static removeItem(key) {
		localStorage.removeItem(key);
	}
}
