import React, {FC, useState} from 'react';
import {ReactSVG} from 'react-svg';

import {plusButtonImg} from 'assets';

import AppText from '../appText';

import styles from './style.module.scss';

type PropsType = {
	title: string;
	description: string;
};

const compatibilityDropdown: FC<PropsType> = ({title = 'title', description = 'description'}) => {
	const [isClosed, setIsClosed] = useState<boolean>(false);

	const onClickCheckHandler = async () => {
		setIsClosed(!isClosed);
	};

	return (
		<button
			type="button"
			className={`${styles.compatibilityDropdown} ${isClosed ? styles.show : styles.hide}`}
			onClick={onClickCheckHandler}>
			<div className={styles.dropdownHeader}>
				<AppText className={styles.title}>{title}</AppText>
				<div className={styles.button}>
					<ReactSVG wrapper="span" src={plusButtonImg} className={styles.plusButtonImg} />
				</div>
			</div>
			<AppText className={styles.description}>{description}</AppText>
		</button>
	);
};

export default compatibilityDropdown;
