import React, {FC} from 'react';
import {ReactSVG} from 'react-svg';

import {advantagesBg, disadvantagesBg} from 'assets';

import AppText from '../appText';

import styles from './style.module.scss';

type PropsType = {
	advantagesList: string[];
	disadvantages?: boolean;
	className?: string;
};

const CompatibilityAdvantagesBlock: FC<PropsType> = ({
	advantagesList,
	disadvantages = false,
	className = '',
}) => {
	const advantages = advantagesList.map((item, index) => {
		return (
			// eslint-disable-next-line react/no-array-index-key
			<li key={`${index}`} className={styles.listItem}>
				{item}
			</li>
		);
	});
	return (
		<div
			className={`${
				disadvantages ? styles.disadvantagesBlock : styles.advantagesBlock
			} ${className}`}>
			<ReactSVG
				wrapper="span"
				src={disadvantages ? disadvantagesBg : advantagesBg}
				className={styles.backgroundImage}
			/>
			<AppText className={styles.title}>
				{disadvantages ? 'Disadvantages' : 'Advantages'}
			</AppText>
			<ul className={styles.advantagesList}>{advantages}</ul>
		</div>
	);
};

export default CompatibilityAdvantagesBlock;
