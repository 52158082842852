import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {ROUTES, STORAGE_KEYS} from 'const';
import {generalSelector} from 'store';
import {Storage} from 'utility';

const withRedirect =
	(redirectTo = ROUTES.PUBLIC.WELCOME) =>
	Component =>
		function redirect(props) {
			const navigate = useNavigate();
			const token = Storage.getData(STORAGE_KEYS.AUTH_TOKEN);
			const isInit = generalSelector.GetIsInit();

			useEffect(() => {
				if (!token && isInit) {
					navigate(redirectTo);
				}
			}, [token, isInit]);

			return <Component {...props} />;
		};

export default withRedirect;
