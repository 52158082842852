import {ThunkAction} from 'redux-thunk';

import {HoroscopeAPI} from 'services';
import {HoroscopeInitialState, StateType} from 'types';
import {Toast} from 'utility';

import {
	HoroscopeActions,
	HoroscopeActionTypes,
	setHoroscope,
	setUserHoroscope,
} from '../actions/horoscope';

const initialState: HoroscopeInitialState = {
	horoscope: null,
	userHoroscope: null,
};

type Thunk = ThunkAction<Promise<void>, StateType, unknown, HoroscopeActionTypes>; // что возвращает санка, тип стора, всегда unknown, тип всех экшенов этого редьюсера

export const fetchHoroscope =
	(params: number, isUserHoroscope = false): Thunk =>
	async dispatch => {
		try {
			const response = await HoroscopeAPI.getHoroscopes(params);

			if (!isUserHoroscope) {
				dispatch(setHoroscope(response));
			} else {
				dispatch(setUserHoroscope(response));
			}
		} catch (error) {
			Toast.error(JSON.stringify(error));
		}
	};

const horoscopesReducer = (
	state: HoroscopeInitialState,
	action: HoroscopeActionTypes,
): HoroscopeInitialState => {
	const horoscopeState = state || initialState;

	switch (action.type) {
		case HoroscopeActions.SET_HOROSCOPE:
			return {
				...horoscopeState,
				horoscope: action.payload,
			};
		case HoroscopeActions.SET_USER_HOROSCOPE:
			return {
				...horoscopeState,
				userHoroscope: action.payload,
			};
		default:
			return horoscopeState;
	}
};

export default horoscopesReducer;
