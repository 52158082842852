import React, {FC} from 'react';

import styles from './style.module.scss';

type PropsType = {
	className?: string;
};

const ModalContent: FC<PropsType> = ({children, className = ''}) => {
	return <div className={`${styles.modalContent} ${className}`}>{children}</div>;
};

export default ModalContent;
