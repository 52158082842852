import React, {FC, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {mastercard, paypal, visa} from 'assets';
import {ROUTES, STORAGE_KEYS} from 'const';
import {PaymentService} from 'services';
import {Storage, Toast} from 'utility';

import AppText from '../appText';
import PaymentButton from '../buttons/paymentButton';

import styles from './style.module.scss';

type Props = {
	email: string | null;
	zodiacSign: string | undefined;
	productId: number | undefined;
	chargebeeKey: string;
	buttonText?: string;
	style?: string;
};

const PaymentModal: FC<Props> = ({
	email,
	zodiacSign,
	productId,
	chargebeeKey,
	buttonText = 'Buy',
	style = '',
}) => {
	const [isDisabled, setIsDisabled] = useState(true);
	const [isProcess, setIsProcess] = useState(false);
	const stripe = useStripe();
	const elements = useElements();
	const navigate = useNavigate();

	const handleSubmit = async (ev: any) => {
		try {
			ev.preventDefault();
			setIsProcess(true);

			if (!stripe || !elements || !email) return false;

			const card = elements.getElement(CardElement);

			if (!card) return false;

			const token = await stripe.createToken(card);

			if (!token.token) return false;

			const onSuccessPayment = () => {
				Toast.success();
				navigate(ROUTES.PRIVATE.SHOP);
				Storage.removeItem(STORAGE_KEYS.PRODUCT);
			};

			await PaymentService.sendPDF(
				{
					email,
					paymentSourceType: 'card',
					temporaryToken: token.token.id,
					plan: chargebeeKey,
				},
				zodiacSign,
				productId,
				onSuccessPayment,
				() => Toast.error(),
			);
		} catch (error: any) {
			Toast.error();
		}

		return setIsProcess(false);
	};

	const changesHandler = (event: any) => {
		if (event.complete) {
			return setIsDisabled(false);
		}

		return setIsDisabled(true);
	};

	return (
		<div className={style}>
			<AppText isTitle>Credit / Debit Card</AppText>
			<div className={styles.paymentIcons}>
				<img src={mastercard} alt="mastercard-icon" />
				<img src={visa} alt="mastercard-icon" />
				<img src={paypal} alt="paypal-icon" />
			</div>
			<form onSubmit={handleSubmit} className={styles.form}>
				<CardElement
					id="card-element"
					onChange={changesHandler}
					options={{hidePostalCode: true}}
					className={styles.input}
				/>
				<PaymentButton
					isProcess={isProcess}
					isDisabled={isDisabled}
					onClick={handleSubmit}
					text={buttonText}
				/>
			</form>
		</div>
	);
};

export default PaymentModal;
