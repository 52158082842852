import React, {FC, useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';

import AppText from '../appText';
import TextButton from '../buttons/textButton';

import styles from './style.module.scss';

type Props = {
	title: string;
	text: string;
	quote?: string;
	report?: boolean;
};

const Article: FC<Props> = ({title, text, quote = '', report = false}) => {
	const [isReadMoreButton, setIsReadMoreButton] = useState(false);
	const [articleText, setArticleText] = useState(text);
	const isTabletOrMobile = useMediaQuery({query: '(max-width: 1024px)'});

	const seeAllText = () => {
		setIsReadMoreButton(false);
		setArticleText(text);
	};

	const getDescription = (isHidden: boolean) => {
		const maxLength = quote ? 75 : 100;
		if (text.length <= maxLength) {
			seeAllText();
			return text;
		}

		const sentencesArray = text.trim().slice(0, maxLength);
		const shortText = sentencesArray.split(' ');
		shortText.pop();
		return isHidden ? `${shortText.join(' ')}...` : text;
	};

	useEffect(() => {
		setIsReadMoreButton(isTabletOrMobile);
		const desctiprion = getDescription(isTabletOrMobile);
		setArticleText(desctiprion);
	}, [isTabletOrMobile]);

	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<AppText isSubtitle className={`${styles.title} ${report && styles.reportTitle}`}>
					{title}
				</AppText>
				{!!quote && <AppText className={styles.quote}>{`"${quote}"`}</AppText>}
				{report ? (
					<AppText className={styles.reportText}>{text}</AppText>
				) : (
					<AppText className={styles.text}>{articleText}</AppText>
				)}
				{/* <AppText className={styles.text}>{articleText}</AppText> */}
			</div>
			{isReadMoreButton && !report && (
				<div className={styles.buttonContainer}>
					<TextButton onClick={seeAllText}>Read more</TextButton>
				</div>
			)}
		</div>
	);
};

export default Article;
