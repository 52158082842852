import React, {FC} from 'react';
import ImageGallery from 'react-image-gallery';

import {MediaType} from 'types';

import './sliderStyle.scss';
import styles from './style.module.scss';

type PropsType = {
	feeds: MediaType[];
};

const FeedSlider: FC<PropsType> = ({feeds}) => {
	const sliderElements = feeds.map(item => ({
		original: item.url,
		thumbnail: item.url,
	}));

	const renderSlide = data => {
		return (
			<div className={styles.content}>
				<div>
					<img src={data.original} alt="news" />
				</div>
			</div>
		);
	};

	return (
		<div className="feedslider">
			<div className={styles.slider}>
				<ImageGallery
					items={sliderElements}
					showFullscreenButton={false}
					showThumbnails={false}
					showPlayButton={false}
					showBullets
					showIndex
					renderItem={renderSlide}
				/>
			</div>
		</div>
	);
};

export default FeedSlider;
