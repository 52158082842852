export const findItemValueByKey = (array: Array<any>, criteria: number) => {
	return array?.find(item => item.key === criteria)?.value;
};

export const findItemByKey = (array: Array<any>, criteria: number) => {
	return array?.find(item => item.key === criteria);
};

export const findItemIconByKey = (array: Array<any>, criteria: number, iconKey = 'icon') => {
	return array?.find(item => item.key === criteria)?.[iconKey];
};

export const getConstantWithAdditionalInfo = (
	defaultConstant: Array<any>,
	constantWithAdditionalInfo: Array<any>,
	props: string = 'value',
) => {
	return defaultConstant.map(elem => {
		const itemAdditionalInfo = constantWithAdditionalInfo.find(
			item => item[props] === elem[props],
		);
		return {...elem, ...itemAdditionalInfo};
	});
};
