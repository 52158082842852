import React, {FC} from 'react';

import styles from './style.module.scss';

type PropsType = {
	variant: 'primary' | 'secondary';
	onClick?: () => void;
	isDisabled?: boolean;
	className?: string;
};

const Button: FC<PropsType> = ({
	variant,
	isDisabled = false,
	onClick = () => false,
	children,
	className = '',
}) => (
	<button
		type="button"
		className={`${styles.button} ${styles[variant]} ${className} ${
			isDisabled ? styles.disabled : ''
		}`}
		onClick={onClick}>
		{children}
	</button>
);

export default Button;
