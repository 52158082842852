enum LinksType {
	ordinary,
	onlyWeb,
	product,
}

type NavLink = {
	key: number;
	type: LinksType;
	name: string;
	icon: string;
	menuIcon: string;
	isActive: boolean;
	linkTo: string;
	onClick?: () => void;
};

export {LinksType};
export type {NavLink};
