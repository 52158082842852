import {
	BiorhythmsResponse,
	LifePathNumbersTypes,
	SignFocusOfTheDay,
	SignMission,
	SignQuote,
	SignStrengthsAndWeknesses,
	SignSymbolismAndMyth,
	TodayLuckyNumberTypes,
	ZodiacAccordionDataType,
} from 'types';

import {appInstanceWithInterceptors} from '../HTTPClient';

export const getAccordionData = (signId: number) =>
	appInstanceWithInterceptors
		.get<ZodiacAccordionDataType>(`ZodiacSignInfos/additionalInfo?ZodiacSign=${signId}`)
		.then(response => response.data);

export const getZodiacStrengths = (signId: number) =>
	appInstanceWithInterceptors
		.get<SignStrengthsAndWeknesses>(`Strengths?ZodiacSign=${signId}`)
		.then(response => response.data);

export const getZodiacWeaknesses = (signId: number) =>
	appInstanceWithInterceptors
		.get<SignStrengthsAndWeknesses>(`Weaknesses?ZodiacSign=${signId}`)
		.then(response => response.data);

export const getZodiacSymbolismAndMyth = (signId: number) =>
	appInstanceWithInterceptors
		.get<SignSymbolismAndMyth>(`Myths?ZodiacSign=${signId}`)
		.then(response => response.data);

export const getZodiacMission = (signId: number) =>
	appInstanceWithInterceptors
		.get<SignMission>(`Missions?ZodiacSign=${signId}`)
		.then(response => response.data);

export const getZodiacFocusOfTheDay = (date: string) =>
	appInstanceWithInterceptors
		.get<SignFocusOfTheDay>(`Focuses?Date=${date}`)
		.then(response => response.data);

export const getLifePathNumbers = () =>
	appInstanceWithInterceptors.get<LifePathNumbersTypes>(`LifePathNumbers`).then(response => {
		return response.data;
	});

export const getZodiacQuote = () =>
	appInstanceWithInterceptors.get<SignQuote>('Quotes').then(response => response.data);

export const getTodayLuckyNumber = () =>
	appInstanceWithInterceptors.get<TodayLuckyNumberTypes>('DailyNumbers').then(response => {
		return response.data;
	});

export const getBiorhythms = () =>
	appInstanceWithInterceptors.get<BiorhythmsResponse>('Biorhythms').then(response => {
		return response.data;
	});
