import React from 'react';

import {Modal} from 'antd';
import {surveyAnimation} from 'assets';
import Button from 'components/buttons/button';
import Lottie from 'lottie-react';

import modalStyles from '../Modal.module.scss';
import styles from './styles.module.scss';

const SurveyModal: React.FC<{
	visible: boolean;
	onConfirm: () => void;
	onCancel: () => void;
}> = ({visible, onConfirm, onCancel}) => {
	const renderContent = () => {
		return (
			<div>
				<Lottie loop animationData={surveyAnimation} className={styles.animation} />
				<div className={styles.title}>We value your opinion</div>
				<div className={styles.text}>
					Thanks for using Aster, we would like to know you better, so we can improve your
					user experience with us.
				</div>
				<div className={styles.text}>
					If you have about 1-2 minutes to answer on our questions, please complete our
					survey.
				</div>
				<div className={styles.controls}>
					<Button className="" variant="secondary" onClick={onConfirm}>
						<a
							href={process.env.REACT_APP_FEEDBACK_SURVEY_URL}
							target="_blank"
							rel="noreferrer">
							Take a Survey
						</a>
					</Button>
					<Button className={styles.cancelButton} variant="primary" onClick={onCancel}>
						Not Now
					</Button>
				</div>
			</div>
		);
	};

	return (
		<Modal
			visible={visible}
			modalRender={renderContent}
			destroyOnClose
			wrapClassName={modalStyles.wrapper}
			className={styles.container}
		/>
	);
};

export default SurveyModal;
