import {ThunkAction} from 'redux-thunk';

import {CompatibilitiesAPI} from 'services';
import {CompatibilityInitialState, StateType} from 'types';
import {Toast} from 'utility';

import {
	CompatibilityActions,
	CompatibilityActionTypes,
	setCompatibility,
} from '../actions/compatibilities';

const initialState: CompatibilityInitialState = {
	compatibilities: null,
};

type Thunk = ThunkAction<Promise<void>, StateType, unknown, CompatibilityActionTypes>; // что возвращает санка, тип стора, всегда unknown, тип всех экшенов этого редьюсера

export const fetchCompatibilities =
	(firstZodiac: number, secondZodiac: number, reverse: boolean): Thunk =>
	async dispatch => {
		try {
			const response = await CompatibilitiesAPI.getCompatibility(
				firstZodiac,
				secondZodiac,
				reverse,
			);
			dispatch(setCompatibility(response));
		} catch (error) {
			Toast.error(JSON.stringify(error));
		}
	};

const compatibilitiesReducer = (
	state: CompatibilityInitialState,
	action: CompatibilityActionTypes,
): CompatibilityInitialState => {
	const compatibilityState = state || initialState;

	switch (action.type) {
		case CompatibilityActions.SET_COMPATIBILITY:
			return {
				...compatibilityState,
				compatibilities: action.payload,
			};
		default:
			return compatibilityState;
	}
};

export default compatibilitiesReducer;
