import {CalendarsTypes} from 'types';

import {appInstanceWithInterceptors} from '../HTTPClient';

const CalendarsAPI = (ZodiacSign: number, Month: number) =>
	appInstanceWithInterceptors
		.get<CalendarsTypes>(`Calendars?Month=${Month}&ZodiacSign=${ZodiacSign}`)
		.then(response => response.data);

export default CalendarsAPI;
