import React, {FC} from 'react';

import Tooltip from '../../tooltip';

const PasswordTooltip: FC = () => {
	return (
		<Tooltip>
			<h2>User need to provide a Password</h2>
			<ul>
				<li>Password must consist from 4 to 25 (any) characters.</li>
			</ul>
		</Tooltip>
	);
};

export default PasswordTooltip;
