import {CalendarsTypes} from 'types';

export enum CalendarActions {
	SET_CALENDAR = 'calendar/setCalendar',
}

type SetCalendar = {type: CalendarActions.SET_CALENDAR; payload: CalendarsTypes};

export const setCalendar = (payload: CalendarsTypes): SetCalendar => {
	return {
		type: CalendarActions.SET_CALENDAR,
		payload,
	};
};

export type CalendarActionTypes = SetCalendar;
