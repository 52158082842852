import React, {FC} from 'react';
import {ReactSVG} from 'react-svg';

import {qrCodeImage, reportBackgroundImage, reportLogoGreen} from 'assets';

import AppText from '../appText';

import styles from './style.module.scss';

type PropsType = {
	contentClassName?: string;
	id?: string;
};

const PDFFile: FC<PropsType> = ({children, contentClassName = '', id = ''}) => {
	return (
		<div className={styles.pageWrapper}>
			<div className={styles.pdfWrapper} id={id}>
				<ReactSVG
					wrapper="span"
					src={reportBackgroundImage}
					className={styles.backgroundImage}
				/>
				<div className={styles.logo}>
					<ReactSVG wrapper="span" src={reportLogoGreen} className={styles.reportLogo} />
					<AppText className={styles.name}>ASTER</AppText>
				</div>
				<div className={`${styles.content} ${contentClassName}`}>{children}</div>
				<footer>
					<AppText className={styles.text}>
						Thank you for using Aster. We hope that with our help you will become more
						self-aware. And we want to help you do that. Visit our web platform via QR
						code scanning (or via <b className={styles.webpage}>web.appaster.com</b>).{' '}
						<b className={styles.bold}>
							Daily horoscopes, biorhythms, detailed information about your sign that
							updates daily!
						</b>
					</AppText>
					<ReactSVG wrapper="span" src={qrCodeImage} className={styles.qrCodeImage} />
				</footer>
			</div>
		</div>
	);
};

export default PDFFile;
