import {toast, ToastOptions} from 'react-toastify';

type Options = {
	success: ToastOptions;
	info: ToastOptions;
	warn: ToastOptions;
	error: ToastOptions;
};

export const toastsCommonOptions: ToastOptions = {
	position: 'bottom-right',
	autoClose: 5000,
	hideProgressBar: false,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined,
	theme: 'colored',
};

export const toastsOptions: Options = {
	success: {
		progressStyle: {background: '#D7FFF9'},
	},
	info: {
		progressStyle: {background: '#9DDCF6'},
	},
	warn: {
		progressStyle: {background: '#FFF2BF'},
	},
	error: {
		progressStyle: {background: '#FFDFDF'},
	},
};

const getErrorToast = (error = "Sorry server doesn't response") => {
	return toast.error(`${JSON.stringify(error)}`, {
		...toastsCommonOptions,
		...toastsOptions.error,
	});
};

const getSuccessToast = (text = 'Thank you!\n Payment processed successfully') => {
	return toast.success(text, {
		...toastsCommonOptions,
		...toastsOptions.success,
	});
};

export default {
	success: getSuccessToast,
	error: getErrorToast,
};
