import React, {FC} from 'react';

import styles from './style.module.scss';

type Props = {
	text: string;
	percent: number;
	isSmall?: boolean;
};

const BiorhythmsCategory: FC<Props> = ({text, percent, isSmall = false}) => {
	return (
		<li className={`${styles.category} ${isSmall ? styles.small : ''}`}>
			<h5>{text}</h5>
			<span>{percent}%</span>
		</li>
	);
};

export default BiorhythmsCategory;
